
import React, { useState, useEffect,useContext } from 'react'

import { useGoogleLogin, GoogleLogin } from "@react-oauth/google";
import axios from "../Axios";
import googleLogo from '../images/google-logo.png'
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import '../assets/ManualCSS/login.css'
import { Tooltip } from '@mui/material';

import appContext from "../context/appContext";

const LoginByGoogle = () => {

    const navigate = useNavigate();
    const [user, setUser] = useState('');

    const context=useContext(appContext);
  const {setIsOpen,setDetails}=context;


    const sendData = (email) => {
        console.log("SendData ",email);
        axios.post("google_signin/", {
            email: email,
        })
            .then((response) => {
                if (response.data.error === 1) {
                    //  setTermsAndConditionsErr(response.data.erroMsg);
                    toast.error(response.data.erroMsg);
                    console.log("oaisjfaosj");
                    //  setLoader(false);
                } else {
                    console.log("problem");
                    localStorage.setItem("TokenSkewb", response.data.data.token);
                    localStorage.setItem("NameSkewb", response.data.data.Name);
                    localStorage.setItem("is_superuser", response.data.data.is_superuser);
                    localStorage.setItem("is_GPT", response.data.data.gpt_access);
                    if (localStorage.getItem('CurrentTheme') === null) {
                        localStorage.setItem('CurrentTheme', "Dark")
                    }

                    console.log(response.data);
                    setDetails({
                        id: response.data.data.acessname,
                        name: response.data.data.acessname,
                        hirarchy_level: response.data.data.level,
                        img: response.data.data.brand_logo,
                        
                      })
                      localStorage.setItem('Header-name',response.data.data.acessname);
                    navigate("/portfolio");
                    setIsOpen(false);

                    //  setLoader(false);
                }
            }).catch((err) => {
                console.log("err ",err);
                toast.error("Invalid");
                //   setLoader(false);
            });
    }
    const handleGoogleLogin = useGoogleLogin({


        onSuccess: (response) => {


            console.log('-----GoogleResponse-----');
            console.log("Login ", response);
            console.log('Login Success:', response.access_token);
            setUser(response)
            const decoded = jwtDecode(response.access_token);
            console.log("decoded ", decoded);


        },
        onError: (error) => console.log("Login Failed:", error)

        // Handle login success
    });

    useEffect(() => {
        if (user!=='') {
            axios
                .get(
                    `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
                    {
                        headers: {
                            Authorization: `Bearer ${user.access_token}`,
                            Accept: "application/json",
                        },
                    }
                )
                .then((res) => {


                    console.log('---- UserProfile-------');
                    console.log(res.data);
                    sendData(res.data.email)

                })
                .catch((err) => console.log(err));
        }
    }, [user]);

    return (
        <div className="shadow-2xl">
            <div

                className='google_button btn-class'
                type="button"
               
                style={{
                    
                    
                    marginTop:'5px',

                    outline: 'none',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    // border: 'solid #d6ff41 1px',
                    // width: 'wrap-content',
                    // fontWeight: '700',
                    // fontSize: 'var(--regular-font-size)',
                    // lineHeight: 'var(--regular-line-height)',


                }}

                onClick={handleGoogleLogin}
            >

                {/* <div  title="Sign in With Google"> */}
                    Login With 
                <img  src={googleLogo} style={{ width: '25px', height: '25px',cursor: 'pointer' }} />
                {/* </div> */}


                

            </div>
        </div>
    );
};

export default LoginByGoogle;