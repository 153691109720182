
import React, { useEffect, useState, useContext, useRef } from "react";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { Cross as Hamburger } from "hamburger-react";
import DetailsPopUp from "./DetailsPopUp";
import appContext from "../context/appContext";
import configDriven from '../ConfirgDriven';

// LOGO
import LogoMark from "../images/skewb-logomark.png";
import LavenderLogo from "../images/skewb-logomark-c1.png";
import SapphireLogo from "../images/skewb-logomark-c3.png";
import TealLogo from "../images/skewb-logomark-c5.png";
import CyanLogo from "../images/skewb-logomark-c7.png";
import GoldenrodLogo from "../images/skewb-logomark-b4.png";

// MENU
import MenuIcon from "../images/Vector.png";
import LavenderMenu from "../images/Vector-c1.png";
import SapphireMenu from "../images/Vector-c3.png";
import TealMenu from "../images/Vector-c5.png";
import CyanMenu from "../images/Vector-c7.png";
import GoldenrodMenu from "../images/Vector-b4.png";

import { Box } from "@mui/material";

const Topbar = (props) => {

  const Analyse=JSON.parse(localStorage.getItem('Analyse'));
  console.log("TOPBAR CALL");
  const context = useContext(appContext);
  const { isOpen, setIsOpen, DetailsId, setDetailsId, Details, setDetails, BrandDetails, } = context;

  const navigate = useNavigate();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [Count, setCount] = useState(0);
  const [BrandName, setBrandName] = useState();
  const [KPIName, setKPIName] = useState();

  const [fullNameBrand, setFullNameBrand] = useState("");


  console.log("BrandDetails payload", BrandDetails)
  const [DetailsFlag, setDetailsFlag] = useState(false);
  const [ShowHideMenuIcon, setShowHideMenuIcon] = useState("");
  const [move, setMove] = useState(false);


  console.log("topbar isOpen", isOpen);

  const topref = useRef(null);

  
  const convertLabelToName = (label) => {
    
    const displayNames = JSON.parse(localStorage.getItem("displayNames"));
    // Split the label by hyphens
    const labelParts = label.split('-');

    // Convert each part into its corresponding id
    const idParts = labelParts.map(part => {
      // Find the key where the value matches the part
    
        return displayNames[part];
        // return foundId || part; // Use the part itself if no matching id is found
      
    });

    // Join the id parts with hyphens
    let resultId = idParts.join('-');
    console.log("Label ",label," ",resultId);

    return resultId;
  };


  const handleFilterClick = (event) => {
    event.stopPropagation(); // Prevent the click event from propagating
    console.log("topbar clicked");
    if (topref.current && !topref.current.contains(event.target)) {
      // setIsOpen(false);
      // setDetailsId("");
      if (typeof isOpen !== "undefined") {
        if (isOpen === true) {
          console.log(isOpen);
          setIsOpen(false);
        }
        // console.log("ISOPEN false ");
      }
    }
  };



  useEffect(() => {
    const url = window.location.href;
    const text = url.split("/");
    const tab = text[text.length - 1];
    setShowHideMenuIcon(tab);
  }, []);

  useEffect(() => {
    if (
      BrandDetails !== null &&
      JSON.stringify(Details) === "{}" &&
      DetailsId === ""
    ) {
      setDetailsId(BrandDetails.id);
      setDetails(BrandDetails);
      setDetailsFlag(true);
    }
  }, [BrandDetails, Details, DetailsId]);

  useEffect(() => {

    setBrandName(
      location.pathname === "/home" ||
        location.pathname === "/" ||
        location.pathname === "/portfolio"
        ? ""
        : Analyse
          ? Analyse.BrandName
          : ""
    );
    setKPIName(
      location.pathname !== "/home" ? (Analyse ? Analyse.KPIName : "") : ""
    );
  }, [Count, location.pathname]);

  // full name logic
  const AllBrands = JSON.parse(localStorage.getItem("allBrands"));

  function flattenDataToObject(data) {
    let result = {};

    function flatten(item) {
      result[item?.id] = item?.name;
      if (item?.children) {
        item?.children.forEach(flatten);
      }
    }

    data?.forEach(flatten);
    return result;
  }


  const flattenedData = flattenDataToObject([AllBrands]);

  function getTeaList(idList, flattenedData) {
    return idList?.map((key) => flattenedData[key]);
  }

  function getDashSeparatedName(match, flattenedData) {
    const pathSegments = match?.split("-");
    const newPath = pathSegments?.map((segment, index) =>
      pathSegments.slice(0, index + 1).join("-")
    );
    const result = getTeaList(newPath, flattenedData);
    const names = result?.join("-");
    return names;
  }

  useEffect(() => {
    const fullName = getDashSeparatedName(DetailsId, flattenedData);
    setFullNameBrand(fullName);
  }, [BrandName]);


  useEffect(() => {

    if (isOpen)
      setMove(true);
    else {
      setTimeout(() => {
        setMove(false);
      }, 400)
    }


  }, [isOpen])



  return (
    <div>

      <header id="page-topbar" onClick={(e) => { handleFilterClick(e) }}>

        <div
          style={{
            height: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            overflow: 'hidden',
            boxShadow:
              "inset -5px -5px 9px rgba(255, 255, 255, 0.10), inset 5px 5px 9px rgba(0, 0, 0, 0.6)",
            background: '#1c2427'

            /* Uniform inset shadow on all sides */

          }}
        >

          <div className="">
            <div className="">
              <Link to="/portfolio" className="logo logo-dark">
                <span className="logo-sm">
                  <img
                    src={
                      localStorage.getItem("CurrentTheme") === "Dark"
                        ? LogoMark
                        : localStorage.getItem("ColorCode") === "Lavender"
                          ? LavenderLogo
                          : localStorage.getItem("ColorCode") === "Sapphire"
                            ? SapphireLogo
                            : localStorage.getItem("ColorCode") === "Teal"
                              ? TealLogo
                              : localStorage.getItem("ColorCode") === "Cyan"
                                ? CyanLogo
                                : localStorage.getItem("ColorCode") === "Goldenrod"
                                  ? GoldenrodLogo
                                  : LogoMark
                    }
                    alt="logo-sm"
                    height="20"
                    style={{ lineHeight: '40px !important' }}
                  />
                </span>
                <span className="logo-lg">
                  <img
                    src={
                      localStorage.getItem("CurrentTheme") === "Dark"
                        ? LogoMark
                        : localStorage.getItem("ColorCode") === "Lavender"
                          ? LavenderLogo
                          : localStorage.getItem("ColorCode") === "Sapphire"
                            ? SapphireLogo
                            : localStorage.getItem("ColorCode") === "Teal"
                              ? TealLogo
                              : localStorage.getItem("ColorCode") === "Cyan"
                                ? CyanLogo
                                : localStorage.getItem("ColorCode") === "Goldenrod"
                                  ? GoldenrodLogo
                                  : LogoMark
                    }
                    alt="logo-dark"
                    height="20"
                    style={{ lineHeight: '40px !important' }}
                  />
                </span>
              </Link>
              <Link to="/portfolio" className="logo logo-light">
                <span className="logo-sm">
                  <img
                    src={
                      localStorage.getItem("CurrentTheme") === "Dark"
                        ? LogoMark
                        : localStorage.getItem("ColorCode") === "Lavender"
                          ? LavenderLogo
                          : localStorage.getItem("ColorCode") === "Sapphire"
                            ? SapphireLogo
                            : localStorage.getItem("ColorCode") === "Teal"
                              ? TealLogo
                              : localStorage.getItem("ColorCode") === "Cyan"
                                ? CyanLogo
                                : localStorage.getItem("ColorCode") === "Goldenrod"
                                  ? GoldenrodLogo
                                  : LogoMark
                    }
                    alt="logo-sm-light"
                    height="20"
                    style={{ lineHeight: '40px !important' }}
                  />
                </span>
                <span className="logo-lg">
                  <img
                    src={
                      localStorage.getItem("CurrentTheme") === "Dark"
                        ? LogoMark
                        : localStorage.getItem("ColorCode") === "Lavender"
                          ? LavenderLogo
                          : localStorage.getItem("ColorCode") === "Sapphire"
                            ? SapphireLogo
                            : localStorage.getItem("ColorCode") === "Teal"
                              ? TealLogo
                              : localStorage.getItem("ColorCode") === "Cyan"
                                ? CyanLogo
                                : localStorage.getItem("ColorCode") === "Goldenrod"
                                  ? GoldenrodLogo
                                  : LogoMark
                    }
                    alt="logo-light"
                    height="20"
                    style={{ lineHeight: '40px !important' }}
                  />
                </span>
              </Link>
            </div>
          </div>
          {BrandName ? window.innerWidth >= 600 && <div className="">
            <div className="d-flex btn-class" style={{display:'flex',alignItems:'center',justifyContent:'center',height: '20px',whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',     maxWidth: '150px', fontSize: "10px",
              width: 'auto',
              padding:'0 5px 0 5px'

            }}>
              <div className="d-lg-block">
                <div className="d-lg-inline-block ms-1 " >

                   (

                    <Link
                      className="btn header-item noti-icon waves-effect px-0 d-flex align-items-center "
                      to="/portfolio"
                      style={{  fontSize: "10px",display:'flex',alignItems:'center',justifyContent:'center'}}
                    >
                      <i className="ri-arrow-left-s-line me-2" style={{  fontSize: "10px", }}></i>
                      <p className="m-0 d-flex align-items-center ">
                        Portfolio Landscape
                      </p>
                    </Link>

                  ) 

                </div>
              </div>
            </div>
          </div>: null}
          <div className="col-sm-6 d-flex align-items-center justify-content-center">

            <div className="d-inline-block">
              <h2
                className="heading-menu-title"
                style={{ fontSize: "12px", margin: "0" }}
              >
                {BrandName ? (
                  <>
                  

                    {convertLabelToName(localStorage.getItem('Header-name'))} -{" "} {(Analyse ? convertLabelToName(Analyse.KPI) : "")}

                    
                  </>
                ) : (
                  <span style={{ margin: 0 }}>Portfolio Landscape</span>

                )}
              </h2>
            </div>

          </div>
          <div className="col-sm-3 d-flex align-items-center justify-content-end">
            <div className="d-flex align-items-center">
              {/* {DetailsFlag ? (
                  <button
                    type="button"
                    className="btn header-item noti-icon waves-effect"
                    onClick={() => setShow(!show)}
                    style={{ marginRight: "10px" }}
                  >
                    <img
                      src={
                        localStorage.getItem("CurrentTheme") === "Dark"
                          ? MenuIcon
                          : localStorage.getItem("ColorCode") === "Lavender"
                          ? LavenderMenu
                          : localStorage.getItem("ColorCode") === "Sapphire"
                          ? SapphireMenu
                          : localStorage.getItem("ColorCode") === "Teal"
                          ? TealMenu
                          : localStorage.getItem("ColorCode") === "Cyan"
                          ? CyanMenu
                          : localStorage.getItem("ColorCode") === "Goldenrod"
                          ? GoldenrodMenu
                          : MenuIcon
                      }
                      alt=""
                    />
                  </button>
                ) : null} */}

              {window.innerWidth >= 600 && parseInt(localStorage.getItem('is_GPT')) === 1 && <div
              className="btn-class"
                onClick={() => navigate("/gpt")}
                style={{
                  color: "white",
                  fontSize: "10px",
                  cursor: "pointer",
                  width:'auto',
                  height: '20px',
                  display:'flex',
                  justifyContent:'center',
                  alignItems:'center',
                }}
              >
                Skewb GPT
              </div>}
              <span
                ref={topref}
                className="btn-class"
                style={{
                  zIndex: "10000",
                  color: "white",
                  marginRight:'0px',
                  marginLeft:"10px",
                  // fontSize: "10px",
                  width:"30px",
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '20px',
                  padding:"0px",
                  // background: 'red',
                  // width: '25px',
                  boxShadow: isOpen
                    ? `-1px -1px 2px rgba(214, 255, 65, 1), /* Light shadow on top-left */
         inset -5px -5px 9px rgba(214, 255, 65, 0.1), 
         inset 5px 5px 9px rgba(0, 0, 0, 0.4)`
                    : null,
                }}
              >
                <span>
                <Hamburger toggled={isOpen} toggle={setIsOpen} size={18} duration={0.4} left={"0px"}/>
                </span>
              </span>


            </div>
          </div>
        </div>

      </header>
      {
        move ? <DetailsPopUp /> : null
      }
    </div>
  );
};

export default Topbar;