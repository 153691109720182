import React, { useState, useEffect, useRef } from "react";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import "../assets/ManualCSS/Cale.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

const MultiCalender = ({
  setDefaultStartDate,
  setDefaultEndDate,
  DefaultStartDate,
  DefaultEndDate,
  rangeStartDate,
  rangeEndDate,
  setTimeframe,
  onChangeHandler,
  TimeFramesAll,
  SelectedChart,
  timeframe,
  setIsSubmitEnabled,
  submittedStartDate,
  submittedEndDate,
  submittedTimeFrame,
  isRefreshEnabled,
  // setIsRefreshEnabled,
  // handleRefreshClick,
}) => {
  const [view, setView] = useState("Monthly");
  const [selectedButton, setSelectedButton] = useState("Monthly");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [collapsedYears, setCollapsedYears] = useState({});
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [isChecked, setIsChecked] = useState("Monthly");
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [defaultStart, setDefaultStart] = useState(submittedStartDate);
  const [defaultEnd, setDefaultEnd] = useState(submittedEndDate);
  console.log("defaultStartDate and DefaultEndDate", startDate, "&", endDate);
  // console.log("defualtEndDate", DefaultEndDate);
  // console.log("timeframe", timeframe);

  const parseDate = (dateStr) => {
    const [year, month, day] = dateStr?.split("-")?.map(Number);
    return { year, month, day };
  };

  // useEffect(()=>{
  //   if(setIsRefreshEnabled!==undefined){
  //     if(startDate===null && endDate===null){
  //       setIsRefreshEnabled(true);
  //     }
  //   }
  // },[startDate,endDate])

  const handleRefreshClick = () => {
    // setView("Monthly");

    const startDateParsed = parseDate(submittedStartDate);
    const endDateParsed = parseDate(submittedEndDate);
    // setTimeframe("Monthly");
    // setView("Monthly");
    setTimeframe(submittedTimeFrame);
    setView(submittedTimeFrame);
    setSelectedValue(submittedTimeFrame);

    const startDateValue = (() => {
      switch (submittedTimeFrame) {
        case "Monthly":
          return startDateParsed.month;
        case "Yearly":
          return startDateParsed.year;
        case "Quarterly":
          return Math.ceil(startDateParsed.month / 3);
        default:
          return startDateParsed.month;
      }
    })();

    const endDateValue = (() => {
      switch (submittedTimeFrame) {
        case "Monthly":
          return endDateParsed.month;
        case "Yearly":
          return endDateParsed.year;
        case "Quarterly":
          return Math.ceil((endDateParsed.month - 2) / 3);
        default:
          return endDateParsed.month;
      }
    })();

    setStartDate({
      type: submittedTimeFrame,
      value:
        submittedTimeFrame === "Annual" ? startDateParsed.year : startDateValue,
      year: startDateParsed.year,
    });

    setEndDate({
      type: submittedTimeFrame,
      value:
        submittedTimeFrame === "Annual" ? endDateParsed.year : endDateValue,
      year: endDateParsed.year,
    });
    // setView("Monthly");
    // setIsRefreshEnabled(false);
  };

  useEffect(() => {
    console.log("changes when toggle");
    setView("Monthly");
    setSelectedValue("Monthly");
  }, [SelectedChart]);

  useEffect(() => {
    if (startDate && endDate) {
      setIsSubmitEnabled(false);
    } else {
      setIsSubmitEnabled(true);
    }
  }, [startDate, endDate]);

  const datePickerRef = useRef(null);

  const toggleList = () => {
    setIsDatePickerOpen(!isDatePickerOpen);
  };

  useEffect(() => {
    if (startDate && endDate) {
      const formattedStartDate = convertToStartDate(startDate);
      const formattedEndDate = convertToEndDate(endDate);

      setDefaultStartDate(formattedStartDate);
      setDefaultEndDate(formattedEndDate);
    }
  }, [startDate, endDate, setDefaultStartDate, setDefaultEndDate]);

  useEffect(() => {
    const parseDate = (dateStr) => {
      const [year, month, day] = dateStr?.split("-")?.map(Number);
      return { year, month, day };
    };
    const startDateParsed = parseDate(DefaultStartDate);
    const endDateParsed = parseDate(DefaultEndDate);
    // if (view === "Monthly") {
    //   setStartDate({
    //     type: "Monthly",
    //     value: startDateParsed.month,
    //     year: startDateParsed.year,
    //   });
    //   setEndDate({
    //     type: "Monthly",
    //     value: endDateParsed.month,
    //     year: endDateParsed.year,
    //   });
    // }
    // else if (view === "Quarterly") {
    //   const startQuarter = Math.ceil(startDateParsed.month / 3);
    //   const endQuarter = Math.ceil(endDateParsed.month / 3);
    //   setStartDate({
    //     type: "Quarterly",
    //     value: startQuarter,
    //     year: startDateParsed?.year,
    //   });
    //   setEndDate({
    //     type: "Quarterly",
    //     value: endQuarter,
    //     year: endDateParsed?.year,
    //   });
    // }
    // else if (view === "Annual") {
    //   setStartDate({
    //     type: "Annual",
    //     value: startDateParsed?.year,
    //     year: startDateParsed?.year,
    //   });
    //   setEndDate({
    //     type: "Annual",
    //     value: endDateParsed?.year,
    //     year: endDateParsed?.year,
    //   });
    // }
    setCurrentYear(startDateParsed.year);
  }, [view, DefaultStartDate, DefaultEndDate]);

  useEffect(() => {
    const parseDate = (dateStr) => {
      const [year, month, day] = dateStr?.split("-")?.map(Number);
      return { year, month, day };
    };
    const startDateParsed = parseDate(DefaultStartDate);
    const endDateParsed = parseDate(DefaultEndDate);
    if (view === "Monthly") {
      setStartDate({
        type: "Monthly",
        value: startDateParsed.month,
        year: startDateParsed.year,
      });
      setEndDate({
        type: "Monthly",
        value: endDateParsed.month,
        year: endDateParsed.year,
      });
    }
  }, [DefaultStartDate, DefaultEndDate]);

  // Event listener for clicks outside the date picker
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        if (!endDate && startDate) {
          // If endDate is null or undefined, set it to startDate
          setEndDate(startDate);
          setIsSubmitEnabled(false);
        }
        if (startDate === null && endDate === null) {
          console.log(
            "SubmittedStartDateNew ",
            submittedStartDate,
            "&",
            " SubmittedEndate ",
            submittedEndDate,
            "SubmittedTf: ",
            submittedTimeFrame
          );
          const startDateParsed = parseDate(submittedStartDate);
          const endDateParsed = parseDate(submittedEndDate);
          // setTimeframe("Monthly");
          // setView("Monthly");
          setTimeframe(submittedTimeFrame);
          setView(submittedTimeFrame);
          setSelectedValue(submittedTimeFrame);

          const startDateValue = (() => {
            switch (submittedTimeFrame) {
              case "Monthly":
                return startDateParsed.month;
              case "Yearly":
                return startDateParsed.year;
              case "Quarterly":
                return Math.ceil(startDateParsed.month / 3);
              default:
                return startDateParsed.month;
            }
          })();

          const endDateValue = (() => {
            switch (submittedTimeFrame) {
              case "Monthly":
                return endDateParsed.month;
              case "Yearly":
                return endDateParsed.year;
              case "Quarterly":
                return Math.ceil((endDateParsed.month - 2) / 3);
              default:
                return endDateParsed.month;
            }
          })();

          setStartDate({
            type: submittedTimeFrame,
            value:
              submittedTimeFrame === "Annual"
                ? startDateParsed.year
                : startDateValue,
            year: startDateParsed.year,
          });

          setEndDate({
            type: submittedTimeFrame,
            value:
              submittedTimeFrame === "Annual"
                ? endDateParsed.year
                : endDateValue,
            year: endDateParsed.year,
          });
        }
        setIsDatePickerOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [endDate, startDate]);

  // const getQuarterFromMonth = (month) => Math.ceil(month / 3);
  // const getEndQuarterFromMonth = (month) => Math.floor(month / 3);

  // const isFullQuarterSelected = (start, end) => {
  //   const startQuarter = getQuarterFromMonth(start.value);
  //   const endQuarter = getEndQuarterFromMonth(end.value);
  //   return (
  //     startQuarter === endQuarter &&
  //     start.value % 3 === 1 &&
  //     end.value % 3 === 0
  //   );
  // };

  // const isFullYearSelected = (start, end) => {
  //   return start.value === 1 && end.value === 12 && start.year === end.year;
  // };

  //   function getQuartersFromMonths(startMonth, endMonth) {
  //     // Calculate the quarter for each month
  //     let startQuarter = Math.ceil(startMonth / 3);
  //     let endQuarter = Math.floor(endMonth / 3);

  //     if(endQuarter<startQuarter){
  //       startQuarter=endQuarter;
  //     }

  //     console.log("startq",startQuarter);
  //     console.log("endQ",endQuarter);
  //     // // Check if the selection covers a full quarter or not
  //     // if (startQuarter !== endQuarter || startMonth === endMonth) {
  //     //     return [startQuarter, endQuarter];
  //     // } else {
  //     //     return [null, null];
  //     // }
  // }

  console.log("startDate outside", startDate);
  console.log("enddate outside", endDate);

  function getQuarterRange(startMonth, endMonth) {
    // Define quarters based on months
    const quarters = {
      1: 1,
      2: 1,
      3: 1, // Q1: Jan - Mar
      4: 2,
      5: 2,
      6: 2, // Q2: Apr - Jun
      7: 3,
      8: 3,
      9: 3, // Q3: Jul - Sep
      10: 4,
      11: 4,
      12: 4, // Q4: Oct - Dec
    };

    // Get the start and end quarters
    const startQuarter = quarters[startMonth];
    const endQuarter = quarters[endMonth];

    let validStartQuarter = null;
    let validEndQuarter = null;

    // Check if quarters within the range are fully covered
    for (let q = startQuarter; q <= endQuarter; q++) {
      const quarterStartMonth = (q - 1) * 3 + 1;
      const quarterEndMonth = q * 3;

      // Check if the current quarter is fully covered
      if (startMonth <= quarterStartMonth && endMonth >= quarterEndMonth) {
        if (validStartQuarter === null) {
          validStartQuarter = q;
        }
        validEndQuarter = q;
      }
    }

    // If we have valid start and end quarters, return them
    if (validStartQuarter !== null && validEndQuarter !== null) {
      return [validStartQuarter, validEndQuarter];
    } else {
      return [null, null];
    }
  }

  function fn(startMonth, startYear, endMonth, endYear) {
    // Initialize variables to track valid start and end years
    let validStartYear = null;
    let validEndYear = null;

    // Check if the entire startYear is covered
    if (
      startMonth === 1 &&
      (endYear > startYear || (endYear === startYear && endMonth === 12))
    ) {
      validStartYear = startYear;
    }

    // Check if the entire endYear is covered
    if (
      endMonth === 12 &&
      (startYear < endYear || (startYear === endYear && startMonth === 1))
    ) {
      validEndYear = endYear;
    }

    // Handle cases where the range spans multiple years or just one full year
    if (validStartYear !== null && validEndYear === null) {
      // If only startYear is valid, it means the range does not cover the full endYear
      return [validStartYear, validStartYear];
    }

    if (validStartYear === null && validEndYear !== null) {
      // If only endYear is valid, it means the range does not cover the full startYear
      return [validEndYear, validEndYear];
    }

    if (validStartYear === null && validEndYear === null) {
      // If neither year is valid
      if (startYear === endYear && startMonth === 1 && endMonth === 12) {
        // Special case: covering the entire year
        return [startYear, endYear];
      }
      return [null, null];
    }

    // If both startYear and endYear are valid and the range spans multiple years
    if (
      validStartYear !== null &&
      validEndYear !== null &&
      validStartYear <= validEndYear
    ) {
      return [validStartYear, validEndYear];
    }

    // If no full year is covered
    return [null, null];
  }

  function fn2(startQuarter, startYear, endQuarter, endYear) {
    // If the range is invalid, return [null, null]
    if (
      startYear > endYear ||
      (startYear === endYear && startQuarter > endQuarter)
    ) {
      return [null, null];
    }

    // Determine the first fully covered year
    let firstFullYear = startQuarter === 1 ? startYear : startYear + 1;

    // Determine the last fully covered year
    let lastFullYear = endQuarter === 4 ? endYear : endYear - 1;

    // If there are no fully covered years, return [null, null]
    if (firstFullYear > lastFullYear) {
      return [null, null];
    }

    // Return the first and last fully covered years
    return [firstFullYear, lastFullYear];
  }
  function getStartEndMonth(startQuarter, endQuarter) {
    // Calculate the start month
    let startMonth = (startQuarter - 1) * 3 + 1;
    // Calculate the end month
    let endMonth = endQuarter * 3;

    return [startMonth, endMonth];
  }

  const handleViewChange = (newView) => {
    // console.log("startDate inside", startDate);
    // console.log("enddate inside", endDate);
    // console.log("newView", newView);
    if(endDate===null){
      setStartDate(null);
    }

    // console.log("startDate and EndDate inside", startDate , "&", endDate)


    setView(newView);
    setIsChecked(newView);
    setTimeframe(newView);

    if (newView === "Monthly") {
      if (
        startDate &&
        endDate &&
        startDate.type === "Quarterly" &&
        endDate.type === "Quarterly"
      ) {
        let [startMonth, endMonth] = getStartEndMonth(
          startDate.value,
          endDate.value
        );
        console.log("startMonth", startMonth);
        setStartDate({
          type: "Monthly",
          value: startMonth,
          year: startDate.year,
        });
        setEndDate({
          type: "Monthly",
          value: endMonth,
          year: endDate.year,
        });
      } else if (
        startDate &&
        endDate &&
        startDate.type === "Annual" &&
        endDate.type === "Annual"
      ) {
        setStartDate({
          type: "Monthly",
          value: 1,
          year: startDate.year,
        });
        setEndDate({
          type: "Monthly",
          value: 12,
          year: endDate.year,
        });
      }
      // No change needed for Monthly view
    } else if (newView === "Quarterly") {
      if (
        startDate &&
        endDate &&
        startDate.type === "Monthly" &&
        endDate.type === "Monthly"
      ) {
        const [startQuarter, endQuarter] = getQuarterRange(
          startDate.value,
          endDate.value
        );

        if (startQuarter !== null && endQuarter !== null) {
          setStartDate({
            type: "Quarterly",
            value: startQuarter,
            year: startDate.year,
          });
          setEndDate({
            type: "Quarterly",
            value: endQuarter,
            year: endDate.year,
          });
          // console.log("dangal1", startDate);
          // console.log("dangal2", endDate);
        } else {
          // setStartDate({ type: "Quarterly", value: startQuarter, year: startDate.year });
          // setEndDate({ type: "Quarterly", value: endQuarter, year: endDate.year });
          setStartDate(null);
          setEndDate(null);
        }
      } else if (
        startDate &&
        endDate &&
        startDate.type === "Annual" &&
        endDate.type === "Annual"
      ) {
        setStartDate({ type: "Quarterly", value: 1, year: startDate.year });
        setEndDate({ type: "Quarterly", value: 4, year: endDate.year });
      }
    } else if (newView === "Annual") {
      if (startDate && endDate) {
        if (startDate.type === "Monthly" && endDate.type === "Monthly") {
          const [startYear, endYear] = fn(
            startDate.value,
            startDate.year,
            endDate.value,
            endDate.year
          );
          if (startYear !== null && endYear !== null) {
            setStartDate({ type: "Annual", value: startYear, year: endYear });
            setEndDate({ type: "Annual", value: endYear, year: endYear });
          } else {
            // setStartDate({ type: "Quarterly", value: startQuarter, year: startDate.year });
            // setEndDate({ type: "Quarterly", value: endQuarter, year: endDate.year });
            setStartDate(null);
            setEndDate(null);
          }
        } else if (
          startDate.type === "Quarterly" &&
          endDate.type === "Quarterly"
        ) {
          const [startYear, endYear] = fn2(
            startDate.value,
            startDate.year,
            endDate.value,
            endDate.year
          );
          if (startYear !== null && endYear !== null) {
            setStartDate({
              type: "Annual",
              value: startYear,
              year: startDate.year,
            });
            setEndDate({ type: "Annual", value: endYear, year: endYear });
          } else {
            // setStartDate({ type: "Quarterly", value: startQuarter, year: startDate.year });
            // setEndDate({ type: "Quarterly", value: endQuarter, year: endDate.year });
            setStartDate(null);
            setEndDate(null);
          }
        }
      }
    }

    setIsDatePickerOpen(true);
  };

  const resetSelection = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const monthNames = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  };

  const formatDate = (date) => {
    if (!date) return "";
    if (date.type === "Monthly")
      return `${monthNames[date.value]} ${date.year}`;
    if (date.type === "Quarterly") return `Q${date.value} ${date.year}`;
    return `${date.year}`;
  };

  const isSelected = (date) => {
    if (startDate && !endDate) {
      return date.year === startDate.year && date.value === startDate.value;
    }
    if (startDate && endDate) {
      const isAfterStart =
        date.year > startDate.year ||
        (date.year === startDate.year && date.value >= startDate.value);

      const isBeforeEnd =
        date.year < endDate.year ||
        (date.year === endDate.year && date.value <= endDate.value);

      return isAfterStart && isBeforeEnd;
    }
    return false;
  };

  const toggleYearCollapse = (year) => {
    setCollapsedYears((prev) => ({
      ...prev,
      [year]: !prev[year],
    }));
  };
  // console.log("rangeStartDate",rangeStartDate);
  const renderMonths = () => {
    const years = getYearsInRange(rangeStartDate, rangeEndDate); // Assuming this returns an array of valid years

    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Parse rangeStartDate and rangeEndDate to get the start and end years and months
    const startYear = new Date(rangeStartDate).getFullYear();
    const startMonth = new Date(rangeStartDate).getMonth(); // 0-indexed (0 for Jan, 1 for Feb, etc.)

    const endYear = new Date(rangeEndDate).getFullYear();
    const endMonth = new Date(rangeEndDate).getMonth(); // 0-indexed

    const handlePreviousYear = () => {
      const currentIndex = years.indexOf(currentYear);
      if (currentIndex > 0) {
        setCurrentYear(years[currentIndex - 1]);
      }
    };

    const handleNextYear = () => {
      const currentIndex = years.indexOf(currentYear);
      if (currentIndex < years.length - 1) {
        setCurrentYear(years[currentIndex + 1]);
      }
    };

    return (
      <div>
        <div className="year-navigation">
          <button
            onClick={handlePreviousYear}
            disabled={currentYear === years[0]} // Disable if it's the first year in the array
          >
            ◀
          </button>
          <span>{currentYear}</span>
          <button
            onClick={handleNextYear}
            disabled={currentYear === years[years.length - 1]} // Disable if it's the last year in the array
          >
            ▶
          </button>
        </div>
        <div className="months-grid">
          {months.map((month, index) => {
            // Determine if the month should be disabled
            const isDisabled =
              (currentYear === startYear && index < startMonth) || // Disable months before rangeStartDate in the start year
              (currentYear === endYear && index > endMonth); // Disable months after rangeEndDate in the end year

            return (
              <div
                key={index}
                onClick={() =>
                  !isDisabled &&
                  handleSelectDate({
                    // Only select if not disabled
                    type: "Monthly",
                    value: index + 1,
                    year: currentYear,
                  })
                }
                className={`date-item ${
                  isSelected({
                    type: "Monthly",
                    value: index + 1,
                    year: currentYear,
                  })
                    ? "selected"
                    : ""
                }`}
                style={{
                  color: isDisabled ? "black" : "",
                  cursor: isDisabled ? "default" : "pointer",
                }}
              >
                {month}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderQuarters = () => {
    const years = getYearsInRange(rangeStartDate, rangeEndDate); // Assuming this returns an array of valid years

    // Parse rangeStartDate and rangeEndDate to get the start and end years and months
    const startYear = new Date(rangeStartDate).getFullYear();
    const startMonth = new Date(rangeStartDate).getMonth(); // 0-indexed (0 for Jan, 1 for Feb, etc.)

    const endYear = new Date(rangeEndDate).getFullYear();
    const endMonth = new Date(rangeEndDate).getMonth(); // 0-indexed

    const handlePreviousYear = () => {
      const currentIndex = years.indexOf(currentYear);
      if (currentIndex > 0) {
        setCurrentYear(years[currentIndex - 1]);
      }
    };

    const handleNextYear = () => {
      const currentIndex = years.indexOf(currentYear);
      if (currentIndex < years.length - 1) {
        setCurrentYear(years[currentIndex + 1]);
      }
    };

    return (
      <div>
        <div className="year-navigation">
          <button
            onClick={handlePreviousYear}
            disabled={currentYear === years[0]} // Disable if it's the first year in the array
          >
            ◀
          </button>
          <span>{currentYear}</span>
          <button
            onClick={handleNextYear}
            disabled={currentYear === years[years.length - 1]} // Disable if it's the last year in the array
          >
            ▶
          </button>
        </div>
        <div className="quarters-grid">
          {["Q1", "Q2", "Q3", "Q4"].map((quarter, index) => {
            // Determine if the quarter should be disabled
            const isDisabled =
              (currentYear === startYear &&
                index < Math.floor(startMonth / 3)) || // Disable quarters before the rangeStartDate in the start year
              (currentYear === endYear && index > Math.floor(endMonth / 3)); // Disable quarters after the rangeEndDate in the end year

            return (
              <div
                key={index}
                onClick={() =>
                  !isDisabled &&
                  handleSelectDate({
                    // Only select if not disabled
                    type: "Quarterly",
                    value: index + 1,
                    year: currentYear,
                  })
                }
                className={`quarter-item ${isDisabled ? "disabled" : ""} ${
                  isSelected({
                    type: "Quarterly",
                    value: index + 1,
                    year: currentYear,
                  })
                    ? "selected"
                    : ""
                }`}
                style={{
                  color: isDisabled ? "black" : "", // Set color to black if disabled
                  cursor: isDisabled ? "default" : "pointer", // Default cursor for disabled, pointer for others
                }}
              >
                {quarter}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  function getYearsInRange(rangeStartDate, rangeEndDate) {
    const startYear = new Date(rangeStartDate).getFullYear();
    const endYear = new Date(rangeEndDate).getFullYear();
    return Array.from(
      { length: endYear - startYear + 1 },
      (_, i) => startYear + i
    );
  }

  const renderYears = () => {
    const years = getYearsInRange(rangeStartDate, rangeEndDate);
    return years.map((year, index) => (
      <div
        key={index}
        onClick={() => handleSelectDate({ type: "Annual", value: year, year })}
        className={`year-item ${
          isSelected({ type: "Annual", value: year, year }) ? "selected" : ""
        }`}
      >
        {year}
      </div>
    ));
  };

  const handleSelectDate = (date) => {
    if (!startDate && !endDate) {
      setStartDate(date);
    } else if (startDate && !endDate) {
      if (
        date.year < startDate.year ||
        (date.year === startDate.year && date.value < startDate.value)
      ) {
        setEndDate(startDate);
        setStartDate(date);
      } else {
        setEndDate(date);
      }
      setIsDatePickerOpen(false);
    } else {
      setStartDate(date);
      setEndDate(null);
    }
  };

  function convertToStartDate(object1) {
    let type = object1?.type;
    const value = object1?.value;
    const year = object1?.year;

    let month = "01"; // Default month for 'year' type

    if (type === "Monthly") {
      month = String(value).padStart(2, "0"); // Ensure month is 2 digits
    } else if (type === "Quarterly") {
      // Quarter 1: 01, Quarter 2: 04, Quarter 3: 07, Quarter 4: 10
      month = String((value - 1) * 3 + 1).padStart(2, "0");
    }

    return `${year}-${month}-01`;
  }
  function getLastDayOfMonth(year, month) {
    return new Date(year, month, 0).getDate();
  }

  function convertToEndDate(object1) {
    const type = object1?.type;
    const value = object1?.value;
    const year = object1?.year;

    let month = "12"; // Default month for 'year' type
    let day = "31"; // Default day for 'year' type

    if (type === "Monthly") {
      month = String(value).padStart(2, "0"); // Ensure month is 2 digits
      day = getLastDayOfMonth(year, value); // Get last day of the month
    } else if (type === "Quarterly") {
      month = String(value * 3).padStart(2, "0"); // Last month of the quarter
      day = getLastDayOfMonth(year, value * 3); // Get last day of that month
    }

    return `${year}-${month}-${String(day).padStart(2, "0")}`;
  }

  const [selectedValue, setSelectedValue] = useState(TimeFramesAll[0].value); // Set default to the first item
  console.log("startDate", startDate);
  console.log("endDate", endDate);

  return (
    <div
      ref={datePickerRef}
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <div style={{ display: "flex" }}>
        <div className="selected-dates">
          <input
            style={{ color: "white", fontSize: "10px" }}
            className="tab-class-clicked"
            type="text"
            value={`${
              startDate ? `${formatDate(startDate)} - ` : ""
            }${formatDate(endDate)}`}
            // value={`${formatDate(startDate)} - ${formatDate(endDate || startDate)}`}
            readOnly
            onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}
          />

          <CalendarTodayIcon
            sx={{
              fontSize: "10px",
              marginLeft: "-20px",
              marginBottom: "2px",
              cursor: "pointer",
            }}
            onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}
          />
        </div>
        <i
          className={`fas fa-sync-alt ${isRefreshEnabled ? "disabled" : ""}`}
          style={{
            marginLeft: "10px", // Space between button and icon
            cursor: !isRefreshEnabled ? "default" : "pointer",
            fontSize: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: isRefreshEnabled ? "#ccc" : "#000", // Adjust the color when disabled
          }}
          onClick={isRefreshEnabled ? handleRefreshClick : null}
        ></i>
      </div>

      {isDatePickerOpen && (
        <div
          style={{
            position: "absolute",
            top: "100%",
            left: "0",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            zIndex: "99",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#1c2427",
              border: "1px solid grey",
              color: "white",
              zIndex: "99999",
              height: "120px",
              // borderRadius: "5px",
              marginTop: "6px",
              paddingLeft: "2px",
              marginLeft: "-20px",
              width: "90px",
            }}
          >
            {TimeFramesAll.map((item) => {
              const id = `timeframe-${item.value}`; // Unique ID for each input

              return (
                <div
                  key={id}
                  style={{ padding: "4px", fontSize: "10px", display: "flex" }}
                >
                  <input
                    id={id}
                    style={{
                      accentColor:
                        selectedValue === item.value ? "#d6ff41" : "initial",
                      transform: "scale(0.80)",
                    }}
                    name="timeframe"
                    checked={selectedValue === item.value}
                    type="radio"
                    value={item.value}
                    onChange={(e) => {
                      // onChangeHandler(e);
                      // setTimeframe(item.value);
                      handleViewChange(item.value);
                      setView(item.value);
                      setSelectedValue(item.value);
                    }}
                  />
                  <div style={{ marginLeft: "5px", marginTop: "7px" }}>
                    <label htmlFor={id}>{item.value}</label>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="date-picker">
            {view === "Monthly" && renderMonths()}
            {view === "Quarterly" && renderQuarters()}
            {view === "Annual" && renderYears()}
          </div>
        </div>
      )}
    </div>
  );
};

export default MultiCalender;