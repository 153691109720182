import React, { useState, useEffect } from "react";
import Legends from "./Legends";
import configDriven from "../ConfirgDriven";


const RowItem = ({ item, showOpenLevel }) => {
  
  const [expand, setExpand] = useState(true);
  
  useEffect(() => {
    const shouldExpand = item?.hirarchy_level <= showOpenLevel;
    setExpand(shouldExpand);
  }, [showOpenLevel, item?.hirarchy_level]);
  
  const isLeaf = item?.children.length === 0;

  console.log("re rendered", expand,"  ",showOpenLevel,"  ",item?.hirarchy_level);


  const handleExpand = () => {
    setExpand(!expand);
  };
  console.log("item.hirarchy_level outside",item.hirarchy_level,"   ",showOpenLevel);
  useEffect(() => {
    setExpand(item?.hirarchy_level <= showOpenLevel);
    console.log("item.hirarchy_level",item.hirarchy_level,"   ",showOpenLevel);
  }, [showOpenLevel, item]);

  const displayNamesChart = JSON.parse(localStorage.getItem("displayNames"));

  const returnDisplayName = (id) => {
    if (configDriven.show_display_name && displayNamesChart?.hasOwnProperty(id)) {
      return displayNamesChart[id];
    } else {
      return id;
    }
  }

  return (
    <>
      <tr>
        <td className="channel-name" style={{}}>
          {/* <div
            style={{
              cursor: isLeaf ? "default" : "pointer",
              marginLeft:
                parseInt(item?.hirarchy_level) !== 0
                  ? parseInt(item?.hirarchy_level) * 7 + "px"
                  : "0px",
                  fontSize:'12px',
            }}
            onClick={isLeaf ? null : handleExpand}
          >
            {returnDisplayName(item?.name)}
            {!isLeaf &&
              (expand ? (
                <i className="fas fa-minus ml-1 custom-table-toggle-icon"></i>
              ) : (
                <i className="fas fa-plus ml-1 custom-table-toggle-icon"></i>
              ))}
          </div> */}
        <div
            style={{
              cursor: isLeaf ? "default" : "pointer",
              marginLeft:
                parseInt(item?.hirarchy_level) !== 0
                  ? parseInt(item?.hirarchy_level) * 7 + "px"
                  : "0px",
                  fontSize:'12px',
              display: "flex",
              alignItems: "center",
            }}
            onClick={isLeaf ? null : handleExpand}
          >
            {!isLeaf ? (
              <i
                className={`fas fa-${
                  expand ? "minus" : "plus"
                } mr-1 custom-table-toggle-icon icon-class2`}
                style={{
                  marginRight: "4px",
                  // width: '15px',
                  color: "white",
                  textAlign: "center",
                  // border: "1px solid #d6ff41",  // Add a border for the box
                  borderRadius: "3px", // Rounded corners for the box
                  padding: "5px", // Add padding for spacing inside the box
                  width: "15px", // Fixed width to ensure consistency
                  height: "15px", // Fixed height to ensure consistency
                  display: "flex", // Center the icon within the box
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#1c2427", // Optional: Add background color to the box
                }}
              ></i>
            ) : (
              <span style={{ width: "15px", marginRight: "5px" }}>
                &nbsp;&nbsp;
              </span>
            )}
            {returnDisplayName(item?.name)}
          </div>
 

        </td>
        {item?.value.map((data, dataIndex) => (
          <td key={dataIndex} style={{color: data.color,fontSize:'12px',}}>{data?.value}</td>
        ))}
      </tr>
      {expand &&
        item.children &&
        item.children.map((childItem) => (
          <RowItem
            key={childItem.id}
            item={childItem}
            showOpenLevel={showOpenLevel}
          />
        ))}
    </>
  );
};



const ItemWise = (props) => {

  
const convertLabelToId = (label) => {
    // Split the label by hyphens
    console.log("label itemwise",label);
    const labelParts = label.split('-');
    const displayNamesChart = JSON.parse(localStorage.getItem("displayNames"));

    // Convert each part into its corresponding id
    const idParts = labelParts.map(part => {
      // Find the key where the value matches the part
    
        return displayNamesChart[part]|| part;
        
    });

    // Join the id parts with hyphens
    const resultId = idParts.join('-');

    return resultId;
  };
  
console.log("PROPS ItemWise ",props);
  return (
    <>
      <div className="row my-1 flex-nowrap" style={{ paddingRight: "40px"}}>
        <div className="custom-col-sm-1" style={{ width: "40px" }}>
          <p className="m-0 vertical-text"><i className="ri-arrow-up-line my-2 "></i> {props.yAxisTitle}</p>
        </div>
        <div className="col-sm-12 px-0" style={{marginTop:'15px'}}>
          {/* icons.....start */}
          <div className="row align-items-center sorting-btn-ui">
              <div className="col-sm-3" style={{display: "flex"}}>
                 {/* <ul style={{border: "1px solid blue"}}> */}
                 <span style={{display:"flex" ,position:"fixed", zIndex:"10", top:"140px", left:"20px", marginBottom:"10px"}}>
                   <div onClick={() => props.setShowOpenLevel(Math.floor(Math.random() * (10000000 - 1000000 + 1)) + 1000000)} title="Expand All">
                     <i className="fa fa-sitemap icon-class" aria-hidden="true"></i>
                   </div>
                   <div  onClick={() => props.setShowOpenLevel(1)} title="Reset">
                     <i className="fa icon-class" aria-hidden="true">
                       &#8635;
                     </i>
                   </div>
                   </span>
                 {/* </ul> */}
              </div>
          </div>
          {/* icons.......end */}
          <div className="gr-table-x-title-div ">
            <p className="m-0 gr-table-x-title">{props.xAxisTitle} <i className="ri-arrow-right-line mx-2"></i></p>
          </div>
          {/* <div className="table-responsive gains-risk-table" style={{ maxHeight: props.height - 140 + "px", overflowY: "scroll" }}>
            <table className="table table-bordered mb-0">
              <thead>
                <tr>
                  <th className="caption"></th>
                  {props.IndChannels.map((item, i) => {
                    return <th key={i}>{item}</th>;
                  })}
                </tr>
              </thead>

              <tbody>
                {ChannelData.map((item, i) => {
                  return (
                    <>
                      {item.show_hide !== "hide" ? <tr key={item.ChannelName + i}>
                        <td className="channel-name" key={i}>
                          <div style={{ marginLeft: "" + parseInt(item.level) !== 0 ? parseInt(item.level) * 7 : 0 + "px" }}>
                            {item.yaxis}
                            {parseInt(item.child) > 0 ?

                              item.show_hide === "showing_id" ? <i
                                className="fas fa-minus ml-1 custom-table-toggle-icon"
                                onClick={() => {
                                  HandleHideData(item.id)
                                }}
                              ></i> :
                                <i
                                  className="fas fa-plus ml-1 custom-table-toggle-icon"
                                  onClick={() => {
                                    HandleShowData(item.id)
                                  }}
                                ></i>
                              : null}
                          </div>
                        </td>

                        {item.ChannelData.map((data, index) => {
                          return (
                            <td
                              key={index}
                              style={{ color: data.Color }}
                            >
                              {data.Value}
                            </td>
                          );
                        })}
                      </tr> : null}
                    </>
                  );
                })}
              </tbody>
            </table>
          </div> */}

          {/* New table */}
          <div className="table-responsive gains-risk-table" style={{maxHeight: `${window.innerHeight < 950 ? window.innerHeight * 0.65 : window.innerHeight * 0.75}px`,  overflowY: "scroll", }}>
            <table className="table table-bordered-right mb-0">
              <thead>
                <tr>
                  <th className="caption"></th>
                  {props.xAxis?.map((item, index) => {
                    return <th key={index}
                    style={{
                      whiteSpace: 'nowrap',  fontSize:'12px',   overflow: 'hidden',     textOverflow: 'ellipsis',     maxWidth: '150px'
                    }}
                    
                    >{convertLabelToId(item)}</th>;
                  })}
                </tr>
              </thead>

              <tbody>
                
               {props.yAxis?.map(item => {
                  return <RowItem key={item.id} item ={item} showOpenLevel={props.showOpenLevel} />
               })}

              </tbody>
            </table>
          </div>
          {/* New table */}
        </div>
      </div>
      <div className="row my-3 d-flex justify-content-sm-end">
        <div className="col-sm-10 d-flex align-items-center justify-content-sm-end">
          {props.legends?.map((item, index) => {
            return <Legends key={index} item={item} />;
          })}
        </div>
      </div>
    </>
  );
};

export default ItemWise;
