import React, { useState, useEffect, useContext } from "react";
import Topbar from "../Components/Topbar";
import Tree from "react-d3-tree";
import { useCenteredTree } from "../Components/Helpers";
import "../assets/ManualCSS/custom-tree.css";
import Axios from "../Axios";
import Loader from "../Components/Loader";
import { ToastContainer, toast } from "react-toastify";
import { CssBaseline } from "@mui/material";
import DetailsPopUp from "../Components/DetailsPopUp";
import appContext from "../context/appContext";



const RenderRectSvgNode = ({ nodeDatum, toggleNode, handleNodeClick, setAllExpanded,HandleOnToggle,handleButtonClick,allExpanded }) => {
  const [isHovered, setIsHovered] = useState(false);
  console.log("nodeDatum",nodeDatum);
  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const rectStyle = {
    width: "200px",
    height: "76px",
    fill: "#1c2427",
    x: "-90px",
    y: "-38px",
    rx: "11px",
    ry: "11px",
    filter: isHovered ? "url(#hoveredShadow)" : "url(#outerShadow)",
    cursor: nodeDatum.access === 0 ? "not-allowed" : "pointer",
    transition: "transform 0.2s, filter 0.2s",
    transform: isHovered ? "scale(1.05)" : "scale(1)",
    stroke: "none",
  };

  return (
    <g
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}

    >
      <defs>
        <filter id="outerShadow" x="-50%" y="-50%" width="200%" height="200%">
          <feDropShadow
            dx="-4"
            dy="-4"
            stdDeviation="2"
            floodColor="rgba(255, 255, 255, 0.1)"
          />
          <feDropShadow
            dx="4"
            dy="4"
            stdDeviation="4"
            floodColor="rgba(0, 0, 0, 0.6)"
          />
        </filter>
        <filter id="hoveredShadow" x="-50%" y="-50%" width="200%" height="200%">
          <feDropShadow
            dx="-4"
            dy="-4"
            stdDeviation="2"
            floodColor="rgba(215, 255, 65, 0.5)"
          />
          <feDropShadow
            dx="4"
            dy="4"
            stdDeviation="2"
            floodColor="rgba(0, 0, 0, 0.6)"
          />
        </filter>
      </defs>

      <rect
        style={rectStyle}
        onClick={() => handleNodeClick(nodeDatum)}
      />

      <foreignObject
        x="-90px"
        y="-32px"
        width="60px"
        height="60px"
        style={{ cursor: nodeDatum.access === 0 ? "not-allowed" : "pointer" }}
        onClick={() => handleNodeClick(nodeDatum)}
      >
        <div xmlns="http://www.w3.org/1999/xhtml"></div>

      </foreignObject>

      {nodeDatum.img && (
        <image
          href={`${nodeDatum.img}?v=${new Date().getTime()}`}
          width={isHovered ? "70px" : "60px"}
          height='50px'
          x="-85px"
          y="-25px"
          alt="logo"
          loading="eager"
          style={{ cursor: nodeDatum.access === 0 ? "not-allowed" : "pointer", margin: '5px !important' }}
          onClick={() => handleNodeClick(nodeDatum)}
        />
      )}

      <foreignObject
        x="-30px"
        y="-32px"
        width="120px"
        height="60px"
        style={{ cursor: nodeDatum.access === 0 ? "not-allowed" : "pointer" }}
        onClick={() => handleNodeClick(nodeDatum)}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            wordWrap: "break-word",
            fontWeight: "bold",
            color: "white",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: nodeDatum.access === 0 ? "not-allowed" : "pointer",
            fontSize: isHovered ? "16px" : "14px",
            transition: "font-size 0.2s",
          }}
        >
          {nodeDatum.name}
        </div>
      </foreignObject>
      {nodeDatum.hirarchy_level===0?
      <foreignObject
        
        x="88px"
        y="-38px"
        width="120px"
        height="60px"
        onClick={handleButtonClick}
        >
        
        <i className="fa fa-sitemap expand-tree-icon" title="Expand All" aria-hidden="true" style={{ color: allExpanded ? '#d6ff41' : null }} ></i>
        {/* style={{ color: allExpanded ? '#d6ff41' : null }} */}
        {/*  */}
      </foreignObject>
      :null}

      {nodeDatum.children?.length > 0 && (
        <>
          <circle
            cx={isHovered ? "103px" : "98px"}
            cy="-1px"
            r="10px"
            stroke="#1c2427"
            strokeWidth="1"
            fill="black"
            onClick={() => { toggleNode(nodeDatum); HandleOnToggle(nodeDatum) }}

          />


          {nodeDatum.__rd3t.collapsed ? (
            <svg
              width="14px"
              height="14px"
              x={isHovered ? "96px" : "91px"}
              y="-8px"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => { toggleNode(nodeDatum); HandleOnToggle(nodeDatum);setAllExpanded(false); }}
            >
              <line
                x1="12"
                y1="5"
                x2="12"
                y2="19"
                stroke="white"
                strokeWidth="2"
              />
              <line
                x1="5"
                y1="12"
                x2="19"
                y2="12"
                stroke="white"
                strokeWidth="2"
              />
            </svg>
          ) : (
            <svg
              width="16px"
              height="16px"
              x={isHovered ? "95px" : "90px"}
              y="-9px"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => { toggleNode(nodeDatum); HandleOnToggle(nodeDatum) ;setAllExpanded(false);}}
            >
              <line
                x1="5"
                y1="12"
                x2="19"
                y2="12"
                stroke="white"
                strokeWidth="2"
              />
            </svg>
          )}
        </>
      )}
    </g>
  );
};

const PortfolioLandscape = () => {
  
  const [dimensions, translate, containerRef] = useCenteredTree();
  const [chartJson, setChartJson] = useState({});
  const [chartJsonCopy, setChartJsonCopy] = useState({});

  const [loader, setLoader] = useState(false);
  const [maxDepth, setMaxDepth] = useState(0);
  const context = useContext(appContext);
  const {
    isOpen,
    setIsOpen,
    DetailsId,
    setDetailsId,
    Details,
    setDetails,
    node,
  } = context;
  const nodeSize = { x: 270, y: 100 };
  const containerStyles = { width: "100vw", height: "100vh" };
  const [levelOp, setLevelOp] = useState(1);
  const [buttonval, setButtonVal] = useState(0);
  const [allExpanded, setAllExpanded] = useState(false);

  console.log("ALLExpanded ",allExpanded);
  const handleNodeClick = (nodeDatum) => {
    

    console.log("handleNodeClick ", nodeDatum);
    if (nodeDatum.access === 0) return null;
    setIsOpen(true);


    // console.log(checkAllNodesExpanded(nodeDatum));

    if (parseInt(nodeDatum.access) === 1) {
      setDetailsId(nodeDatum.id);
      setDetails({
        id: nodeDatum.id,
        name: nodeDatum.name,
        hirarchy_level: nodeDatum.hirarchy_level,
        img: nodeDatum.img,
      });

      localStorage.setItem(
        "BrandDetails",
        JSON.stringify({
          id: nodeDatum.id,
          name: nodeDatum.name,
          hirarchy_level: nodeDatum.hirarchy_level,
          img: nodeDatum.img,
        })
      );
    }
  };

  // const AllNodeActivation=(node)=>{

  //   node.__rd3t.collapsed=!node.__rd3t.collapsed;
  //   // if (node.children) {

  //   //   for (const child of node.children) {

  //   //     // child.__rd3t.collapsed=!child.__rd3t.collapsed;

  //   //     console.log(child);
  //   //   }
  //   // }

  // }

  const updateNodeInTree = (node, targetNode) => {
    // If this is the node to update, toggle the collapsed state
    if (node.id === targetNode.id) {
      return {
        ...node,
        __rd3t: {
          ...node.__rd3t,
          collapsed: !node.__rd3t.collapsed,
        },
      };
    }
    // If the node has children, recursively update them
    if (node.children) {
      return {
        ...node,
        children: node.children.map((child) =>
          updateNodeInTree(child, targetNode)
        ),
      };
    }
    // Return the node as is if it doesn't match the target and has no children
    return node;
  };

  // Create a new chartJson with the updated node


  const HandleOnToggle = (nodeDatum) => {

    nodeDatum.__rd3t.collapsed = !nodeDatum.__rd3t.collapsed;
    const updatedChartJson = updateNodeInTree(chartJsonCopy, nodeDatum);
    setChartJsonCopy(updatedChartJson);
    const isFullyExpanded = checkAllNodesExpanded(updatedChartJson);
    setAllExpanded(isFullyExpanded);
    // setAllExpanded(false);
    console.log("isFullyExpanded ", isFullyExpanded, "  ", updatedChartJson, "  ", nodeDatum);

  };

  const checkAllNodesExpanded = (node) => {
    console.log("checkAllNodesExpanded isFullyExpanded", node);
    if (node.__rd3t?.collapsed && node.children.length > 0) return false;
    if (node.children) {
      for (const child of node.children) {
        console.log('child ', child);
        if (!checkAllNodesExpanded(child)) return false;
      }
    }
    console.log("node isFullyExpanded", node);
    return true;
  };


  const collapseNodesBeyondLevel = (node, level, maxLevel) => {
    console.log("cliick");
    if (level > maxLevel) {
      node.__rd3t = { collapsed: true };
    }
    else if (level == 1) {
      node.__rd3t = { collapsed: true }; // Initialize as expanded
    }
    else {
      node.__rd3t = { collapsed: false };
    }
    if (node.children) {
      node.children.forEach((child) =>
        collapseNodesBeyondLevel(child, level + 1, maxLevel)
      );
    }
  };

  const openAllNodes = (node, level, maxLevel) => {

    if (level >= maxLevel) {
      node.__rd3t = { collapsed: true };
    }
    else {
      node.__rd3t = { collapsed: false };
    }

    if (node.children) {
      node.children.forEach((child) =>
        openAllNodes(child, level + 1, maxLevel)
      );
    }
  }

  const calculateMaxDepth = (node, depth = 0) => {
    if (!node.children || node.children.length === 0) {
      return depth;
    }
    return Math.max(
      ...node.children.map((child) => calculateMaxDepth(child, depth + 1))
    );
  };

  useEffect(() => {
    setLoader(true);
    Axios.post("get_hirarchy_data_nested/", {})
      .then((response) => {
        if (response.data.error === 1) {
          toast.error(response.data.erroMsg);
          setLoader(false);
        } else {
          const data = response.data;
          console.log("data", data);
          const nodeObj = {
            access: data.access,
            children: data.children,
            id: data.id,
            img: data.img,
            name: data.name,
            hirarchy_level: data.hirarchy_level
          }
          console.log(nodeObj);
          // AllNodeActivation(nodeObj);
          const depth = calculateMaxDepth(data);
          setMaxDepth(depth);
          collapseNodesBeyondLevel(data, 0, depth); // Collapse nodes beyond max depth
          setChartJson(data);
          setChartJsonCopy(data);
          localStorage.setItem("allBrands", JSON.stringify(data));
          setLoader(false);
        }
      })
      .catch((data) => {
        setLoader(false);
      });
    fetchDisplayNames();


    return () => { };
  }, []);


  const fetchDisplayNames = async () => {
    try {
      const response = await Axios.post("display_name_ui/", {});

      const data = response.data;
      // console.log(data);
      localStorage.setItem("displayNames", JSON.stringify(data.data));
    } catch (error) {
      console.error("Failed to fetch:", error);
    }
  };


  const handleButtonClick = () => {

    console.log("handleButtonClick",allExpanded," hello");
    
    // if(!allExpanded){
      setButtonVal(buttonval + 1);
      setLevelOp(maxDepth);
      openAllNodes(chartJsonCopy, 0, maxDepth);
      setAllExpanded(true);
    // }

  };

  return (
    <div style={{ backgroundColor: "#1c2427" }}>
      <CssBaseline />
      {loader ? <Loader /> : null}
      <ToastContainer />
      <Topbar BrandName="" />

      {/* <button
        className={`${allExpanded !== true ? 'btn-class' : 'btn-class-clicked'}`}
        onClick={handleButtonClick}
        style={{
          position: "fixed",
          left: "10px",
          top: "55px", // Adjust this value to set the fixed Y coordinate
          // zIndex: 1000, // Ensure it appears on top of other elements
          backgroundColor: "#1c2427", // Optional: add background color for better visibility
          padding: "5px", // Optional: add some padding
          borderRadius: "4px", // Optional: add rounded corners
          color: "white",
          fontSize: "10px",
          display: 'flex',
          alignItems: 'center',
          width: 'auto',
          justifyContent: 'center'
          // boxShadow:"-4px -4px 5px rgba(255, 255, 255, 0.1),4px 4px 8px rgba(0, 0, 0, 0.6)" ,
        }}
        title="Expand All"
      >
        <i className="fa fa-sitemap" aria-hidden="true"></i>
      </button> */}
      <div style={containerStyles} ref={containerRef}>
        <Tree
          key={`tree-${buttonval}`}
          data={chartJson}
          dimensions={dimensions}
          translate={translate}
          renderCustomNodeElement={(rd3tProps) => (
            <RenderRectSvgNode
              {...rd3tProps}
              handleNodeClick={handleNodeClick}
              setDetailsId={setDetailsId}
              HandleOnToggle={HandleOnToggle}
              handleButtonClick={handleButtonClick}
              allExpanded={allExpanded}
              setAllExpanded={setAllExpanded}
            />
          )}
          orientation="horizontal"
          initialDepth={levelOp}
          separation={{ siblings: 1, nonSiblings: 1 }}
          enableLegacyTransitions={800}
          nodeSize={nodeSize}
          pathFunc={"step"}
          zoomable={true}
          pathClassFunc={() => "custom-path-link"}
        />
      </div>
    </div>
  );
};

export default PortfolioLandscape;