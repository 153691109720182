import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import debounce from "lodash.debounce";
import Topbar from "../Components/Topbar";
import Menubar from "../Components/Menubar";
import axios from "../Axios";
import axiosDownload from "../Axios"; // download url will be change
import MultiCal2 from "../Components/MultiCal2";
import LTSTROI from "../Components/LTSTROI";
import ComparativeROI from "../Components/ComparativeROI";
import Loader from "../Components/Loader";
import { useLocation, Link } from "react-router-dom";
import Download from "../images/download-icon.png";
import { Tooltip } from "react-tooltip";
import InfoIcon from "../images/icons8-info.svg";
import { ToastContainer, toast } from "react-toastify";
import TrendChart from "../Components/TrendChart";
import AllHeirarchyFilter from "../Components/HeirarchyFilter";
// import ActiveHeirarchyAndCampaignFilter from "../ActiveHeirarchyAndCampaignFilter";
import ChannelFilter from "../Components/ChannelFilter";
import axiosChartDownload from "../Axios"; // download url will be change
import domtoimage from "dom-to-image";
import LightThemeHexCode from "../Components/LightThemeColorCode";
import useDownloadChart from "../Components/DownloadCharts";
import downloadFlag from "../DownloadFlag";
import appContext from "../context/appContext";
import configDriven from "../ConfirgDriven";
import DetailsPopUp from "../Components/DetailsPopUp";
import MultiSelect from "../Components/MultiSelect";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";

import CustomSelect from "../Components/CustomSelect";

const Main = () => {
  const location = useLocation();
  const refGraphDiv = useRef(null);
  // console.log("refGraphDiv: ", refGraphDiv);
  const displayNames = JSON.parse(localStorage.getItem("displayNames"));

  const returnDisplayName = (id) => {
    if (configDriven.show_display_name && displayNames?.hasOwnProperty(id)) {
      return displayNames[id];
    } else {
      return id;
    }
  };

  const [loader, setLoader] = useState(false);
  const Analyse = JSON.parse(localStorage.getItem("Analyse"));
  // console.log("Analyse: ", Analyse);
  const AnalyticFilter = JSON.parse(localStorage.getItem("AnalyticFilter"));
  // console.log("AnalyticFilter: ", AnalyticFilter);
  const CampaignAnalyticFilter = JSON.parse(
    localStorage.getItem("CampaignAnalyticFilter")
  );
  // console.log("CampaignAnalyticFilter: ", CampaignAnalyticFilter);
  const [AnalyticFilterFlag, setAnalyticFilterFlag] = useState(true);
  const [TimeFramesAll, setTimeFramesAll] = useState([]);
  console.log("TimeFramesAll: ", TimeFramesAll);
  // const [TimeFrame, setTimeFrame] = useState("");
  const [Tabs, setTabs] = useState([]);
  const [ActiveTab, setActiveTab] = useState("");
  console.log("ActiveTab: ", ActiveTab);
  const [ActiveTimeFrame, setActiveTimeFrame] = useState("");
  const [Categories, setCategories] = useState([]);
  // console.log("Categories: ", Categories);
  const [Series, setSeries] = useState([]);
  const [Summary, setSummary] = useState([]);
  const [Years, setYears] = useState([]);
  const [ChannelDataAll, setChannelDataAll] = useState([]);
  const [Legends, setLegends] = useState([]);
  const [FooterNotes, setFooterNotes] = useState("");

  const [Notes, setNotes] = useState("");
  const [ChartTitle, setChartTitle] = useState("");
  const [Xtitle, setxTitle] = useState("");
  const [Ytitle, setyTitle] = useState("");

  const [InitialLevel,setInitialLevel]=useState(0);
  const [AllChannelCategory, setAllChannelCategory] = useState([]);
  const [ChannelCategory, setChannelCategory] = useState("");
  const [FetchCall, setFetchCall] = useState(false);

  const [CategoryFlag, setCategoryFlag] = useState(false);
  const [ShowSummary, setShowSummary] = useState(0);
  const [IsAllEnabled, setIsAllEnabled] = useState(0);
  const [Reset, setReset] = useState(0);

  //Heirarchy filters state
  const [AllFilters, setAllFilters] = useState([]);
  // console.log("AllFilters: ", AllFilters);
  const [ActiveFilters, setActiveFilters] = useState([]);
  // console.log("ActiveFilters: ", ActiveFilters);

  const Fixed = localStorage.getItem("AnalyticFilter")
    ? AnalyticFilter.Fixed
    : [];

  const [Chartdata, setChartdata] = useState({});

  const [NotAllow, setNotAllow] = useState([]);
  const [showChat,setShowChat]=useState(false);

  //Channel filters state
  const [CompAllFilters, setCompAllFilters] = useState([]);
  // console.log("CompAllFilters: ", CompAllFilters);
  const [CompActiveFilters, setCompActiveFilters] = useState([]);
  // console.log("CompActiveFilters: ", CompActiveFilters);

  const [Downloadchart, setDownloadchart] = useState(false);
  const [BreadCrumbs, setBreadCrumbs] = useState([]);
  // console.log("BreadCrumbs: ", BreadCrumbs);
  // const [ShowLevels, setShowLevels] = useState(0);
  const [FilterTabShow, setFilterTabShow] = useState(false);
  const [ChartHeight, setChartHeight] = useState("");
  const [ResetFilterFlag, setResetFilterFlag] = useState(false);

  // on change of hierarchy or channel filter
  const [changeFilter, setChangeFilter] = useState(false);

  const [isDownloadingChart, setIsDownloadingChart] = useState(false);
  const [chartContainerID, setChartContainerID] = useState("");
  const [downloadedImageName, setDownloadedImageName] = useState("");
  const [downloadingChartTitle, setDownloadingChartTitle] = useState("");

  const [IsAllEnabledDownload, setIsAllEnabledDownload] = useState(0);

  // console.log("IsAllEnabledDownload: ", IsAllEnabledDownload);

  const [showOpenLevel, setShowOpenLevel] = useState(0);

  const [timeframe, setTimeframe] = useState("");
  const [DefaultStartDate, setDefaultStartDate] = useState("");
  const [DefaultEndDate, setDefaultEndDate] = useState("");
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(true);
  const [submittedStartDate, setSubmittedStartDate] = useState("");
  const [submittedEndDate, setSubmittedEndDate] = useState("");
  const [submittedTimeFrame, setSubmittedTimeFrame] = useState(timeframe);
  const [isRefreshEnabled, setIsRefreshEnabled] = useState(false);
  const [startdate, setStartdate] = useState("");
  const [enddate, setEnddate] = useState("");

  const [hierarchySelectionBreadCrumb, setHierarchySelectionBreadCrumb] =
    useState("");
  const [channelSelectionBreadCrumb, setChannelSelectionBreadCrumb] =
    useState("");

  useEffect(() => {
    if (
      DefaultStartDate !== submittedStartDate ||
      DefaultEndDate !== submittedEndDate
    ) {
      setIsRefreshEnabled(true);
    }

    if (
      DefaultStartDate === submittedStartDate &&
      DefaultEndDate === submittedEndDate
    ) {
      setIsRefreshEnabled(false);
    }
  }, [DefaultStartDate, DefaultEndDate]);

  const userName = localStorage.getItem("NameSkewb");

  useEffect(() => {
    setIsAllEnabledDownload(0);
  }, [ActiveTab]);

  const downloadButtonFlag = () => {
    if (ActiveTab === "comp") {
      return downloadFlag.data || downloadFlag.pdf;
    } else {
      return downloadFlag.chart || downloadFlag.data || downloadFlag.pdf;
    }
  };

  useEffect(() => {
    const extractFilters = (obj, skipData) => {
      return Object.keys(obj)
        .map((key) => {
          if (!skipData.some((skipItem) => skipItem.id === key)) {
            const filtersArray = obj[key].Filter.filter(
              (filter) => !skipData.includes(filter)
            );
            if (filtersArray.length > 0) {
              return filtersArray.join(","); // Join multiple filters with a comma
            }
          }
          return null; // Return null if the filters array is empty after filtering out SkipData
        })
        .filter(Boolean) // Remove null entries
        .join("/"); // Join different keys' filters with a slash
    };

    const SkipData = JSON.parse(
      localStorage.getItem("StoreAnalyticFilter")
    ).Fixed;

    // Assign Selection to SkipData
    SkipData.pop();

    const filtersString = extractFilters(Chartdata, SkipData);
    console.log("BreadCrumbs filters", filtersString);
  }, [Chartdata]);

  // useEffect(() => {
  //   setChannelDataAll([]);
  // }, [timeframe]);

  useDownloadChart(
    downloadedImageName,
    downloadingChartTitle,
    isDownloadingChart,
    chartContainerID,
    BreadCrumbs,
    setLoader,
    Analyse.Brand,
    timeframe,
    "",
    "",
    setIsDownloadingChart,
    Analyse.KPI
  );

  useEffect(() => {
    const url = window.location.href;
    const text = url.split("/");
    const tab = text[text.length - 1];
    for (var i = 0; i < Analyse.Menubar.length; i++) {
      if (Analyse.Menubar[i].key === tab) {
        setNotes(Analyse.Menubar[i].notes);
      }
    }
  }, [Analyse]);

  useEffect(() => {
    const localAnalyticFilter = JSON.parse(
      localStorage.getItem("StoreAnalyticFilter")
    );
    const localCampaignAnlayticFilter = JSON.parse(
      localStorage.getItem("StoreCampaignAnalyticFilter")
    );
    setAllFilters(localAnalyticFilter.AllFilters);
    setActiveFilters(localAnalyticFilter.ActiveFilters);
    setAnalyticFilterFlag(false);
    setCompActiveFilters(localCampaignAnlayticFilter.CompActiveFilters);
    setCompAllFilters(localCampaignAnlayticFilter.CompAllFilters);
  }, [Analyse.Brand, Analyse.KPI]);

  useEffect(() => {
    axios
      .post("get_roi_tabs/", {
        brand: Analyse.Brand,
        kpi: Analyse.KPI,
      })
      .then((response) => {
        if (response.data.error === 1) {
          toast.error(response.data.erroMsg);
          setLoader(false);
        } else {
          setTabs(response.data.data.tabs);
          if (response.data.data.tabs.length > 0) {
            setActiveTab(response.data.data.tabs[0].tab);
            localStorage.setItem("current_level", 0);
          }
        }
      });
  }, [Analyse.Brand, Analyse.KPI]);

  useEffect(() => {
    if (ActiveTab) {
      setLoader(true);
      axios
        .post("get_timeframes/", {
          page: ActiveTab + "_roi",
          brand: Analyse.Brand,
          kpi: Analyse.KPI,
        })
        .then((response) => {
          if (response.data.error === 1) {
            toast.error(response.data.erroMsg);
            setLoader(false);
          } else {
            setTimeFramesAll(response.data.data);
            setTimeframe(response.data.data[0].key);
            setActiveTimeFrame(ActiveTab);
            setDefaultStartDate(response.data.data[0].default_startdate);
            setDefaultEndDate(response.data.data[0].default_enddate);
            setSubmittedStartDate(response.data.data[0].default_startdate);
            setSubmittedEndDate(response.data.data[0].default_enddate);
            setSubmittedTimeFrame(response.data.data[0].key);
            setStartdate(response.data.data[0].start_date);
            setEnddate(response.data.data[0].end_date);
            setCategoryFlag(true);
            setLoader(false);
          }
        })
        .catch((data) => {
          setLoader(false);
        });
    }
  }, [ActiveTab, Analyse.Brand, Analyse.KPI]);

  useEffect(() => {
    if (CategoryFlag && timeframe) {
      axios
        .post("get_category/", {
          brand: Analyse.Brand,
          kpi: Analyse.KPI,
          timeframe: timeframe,
          page: "roi",
        })
        .then((response) => {
          if (response.data.error === 1) {
            toast.error(response.data.erroMsg);
            setLoader(false);
          } else {
            setAllChannelCategory(response.data.data);
            if (response.data.data.length > 0) {
              setChannelCategory(response.data.data[0].value);
            } else {
              setChannelCategory("");
            }
            setCategoryFlag(false);
            setFetchCall(true);
            setLoader(false);
          }
        })
        .catch((data) => {
          setLoader(false);
        });
    }
  }, [ActiveTab, Analyse.Brand, Analyse.KPI, timeframe, CategoryFlag]);

  useEffect(() => {
    // console.log("useeffect called!")
    if (
      FetchCall &&
      timeframe &&
      ActiveTimeFrame === ActiveTab &&
      !CategoryFlag &&
      JSON.stringify(Chartdata) !== "{}"
    ) {
      setLoader(true);
      setCategories([]);
      setSeries([]);
      axios
        .post("get_gain_risk_roi/", {
          brand: Analyse.Brand,
          kpi: Analyse.KPI,
          tab: ActiveTab,
          starttime: DefaultStartDate,
          endtime: DefaultEndDate,
          timeframe: timeframe,
          category: ChannelCategory,
          chartdata: Chartdata,
          is_all_enabled: IsAllEnabled,
          reset: Reset,
          level: localStorage.getItem("DefaultLevel"),
          theme: localStorage.getItem("CurrentTheme"),
          LightThemeColorCode: [
            localStorage.getItem("ColorCode"),
            LightThemeHexCode[localStorage.getItem("ColorCode")],
          ],
        })
        .then((response) => {
          if (response.data.error !== 1) {
            if (ActiveTab === "comp") {
              setYears(response.data.data.display_date);
              setChannelDataAll(response.data.data.ChannelData);
              // setShowLevels(response.data.data.show_levels)
              setLegends(response.data.data.legends);
              setShowOpenLevel(response.data.data.show_levels);
            } else {
              setCategories(response.data.data.categories);
              setSeries(response.data.data.series);
            }
            setNotAllow(response.data.data.nonclicklist);
            setShowSummary(response.data.data.show_summary);
            setSummary(response.data.data.summary);
            setFooterNotes(response.data.data.footernotes);
            setChartTitle(response.data.data.charttitle);
            setxTitle(response.data.data.xtitle);
            setyTitle(response.data.data.ytitle);
            setIsAllEnabled(0);
            setReset(0);
            localStorage.setItem("DefaultLevel", -1);
            setInitialLevel(response.data.data.readlevel);
          } else {
            setYears([]);
            setChannelDataAll([]);
            setLegends([]);
            setCategories([]);
            setSeries([]);
            setSummary([]);
            setFooterNotes("");
            setChartTitle("");
            setxTitle("");
            setyTitle("");
            // toast.error(response.data.erroMsg);
            toast.error(
              "No data found for the following filter, please reset the filter"
            );
          }
          setFetchCall(false);
          setLoader(false);
        })
        .catch((data) => {
          setLoader(false);
        });
    }
  }, [
    ActiveTab,
    Analyse.Brand,
    Analyse.KPI,
    timeframe,
    ActiveTimeFrame,
    ChannelCategory,
    FetchCall,
    Chartdata,
    CategoryFlag,
    IsAllEnabled,
    Reset,
  ]);

  const DivRef = useRef(null);
  const [DivHeight, setDivHeight] = useState("");

  useEffect(() => {
    setTimeout(() => {
      if (DivRef.current) {
      setDivHeight(DivRef.current.clientHeight);
      }
    }, 500);
  }, [DivRef, FetchCall]);

  const DownloadDataltstroi = (e) => {
    e.preventDefault();
    setLoader(true);
    axiosDownload
      .post("ltstroi/", {
        // data: {
        //   categories: Categories,
        //   series: Series,
        //   summary: Summary,
        //   Legends: Legends
        // }
        brand: Analyse.Brand,
        kpi: Analyse.KPI,
        timeframe: timeframe,
        chartdata: Chartdata,
        category: ChannelCategory,
        is_all_enabled: IsAllEnabledDownload,
        reset: Reset,
        level: -1,
        theme: localStorage.getItem("CurrentTheme"),
      })
      .then((response) => {
        var link = document.createElement("a");
        link.target = "_blank";
        link.href = response.data.data;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoader(false);
      })
      .catch((data) => {
        setLoader(false);
      });
  };

  const DownloadDatacomproi = (e) => {
    e.preventDefault();
    setLoader(true);
    axiosDownload
      .post("comproi/", {
        // data: {
        //   ChannelYears: Years,
        //   ChannelData: ChannelDataAll,
        //   summary: Summary,
        //   Legends: Legends
        // }
        brand: Analyse.Brand,
        kpi: Analyse.KPI,
        timeframe: timeframe,
        chartdata: Chartdata,
        category: ChannelCategory,
        is_all_enabled: IsAllEnabledDownload,
        reset: Reset,
        level: -1,
        theme: localStorage.getItem("CurrentTheme"),
      })
      .then((response) => {
        var link = document.createElement("a");
        link.target = "_blank";
        link.href = response.data.data;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoader(false);
      })
      .catch((data) => {
        setLoader(false);
      });
  };

  const DownloadPDFltstroi = async (e) => {
    e.preventDefault();
    setLoader(true);
    const chartContainers = [document.getElementById("roi_chart")];
    if (chartContainers.every((container) => container !== null)) {
      try {
        // setLoader(true);
        const dataUrls = await Promise.all(
          chartContainers.map((container) => domtoimage.toPng(container))
        );
        // setLoader(true);
        const response = await axiosDownload.post("ltstroi/", {
          brand: Analyse.Brand,
          kpi: Analyse.KPI,
          timeframe: timeframe,
          chartdata: Chartdata,
          category: ChannelCategory,
          is_all_enabled: IsAllEnabledDownload,
          reset: Reset,
          level: -1,
          theme: localStorage.getItem("CurrentTheme"),
          download_type: "PDF",
        });
        // setLoader(false);
        // console.log("excel: ", response.data);
        const pdfResponse = await axiosChartDownload.post(
          "download_pdf/create/",
          {
            images: dataUrls.map((dataUrl) => dataUrl.split(",")[1]),
            document: {
              table1_data: response.data.table1_data,
              titlename: "ROI",
              // textcontent:
              // "We take digital marketing analytics one step further with unparalleled prescriptive deep-dive. Our innovative end-to-end analytics solution gives you relevant data & helps in strategy formulation & planning at the lowest cuts.",
              // header_content: "Loreal Haircolor Garnier- Sales",
              footer_content: "Private & Confidential",
              table_heading1: "Total ROI",
              image_heading1: "Total ROI",
              username: userName,
              timeframe: timeframe,
              start_date: "",
              end_date: "",
              category: ChannelCategory,
              brand: Analyse.Brand,
              kpi: Analyse.KPI,
              chartdata: Chartdata,
              tabname: "roi",
            },
          }
        );
        if (pdfResponse.data.error === 1) {
          toast.error(pdfResponse.data.erroMsg);
          setLoader(false);
        } else {
          // console.log("response pdf: ", pdfResponse.data);
          setLoader(false);
          var link = document.createElement("a");
          link.target = "_blank";
          link.href = pdfResponse.data.pdf_url;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (error) {
        setLoader(false);
        console.error("Error while generating pdf:", error);
      }
    }
  };

  const DownloadPDFcomproi = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      const response = await axiosDownload.post("comproi/", {
        brand: Analyse.Brand,
        kpi: Analyse.KPI,
        timeframe: timeframe,
        chartdata: Chartdata,
        category: ChannelCategory,
        is_all_enabled: IsAllEnabledDownload,
        reset: Reset,
        level: -1,
        theme: localStorage.getItem("CurrentTheme"),
        download_type: "PDF",
      });

      // console.log("excel: ", response.data);
      const pdfResponse = await axiosChartDownload.post(
        "download_pdf/create/",
        {
          images: [],
          document: {
            table1_data: response.data.table1_data,
            titlename: "ROI",
            // textcontent:
            // "We take digital marketing analytics one step further with unparalleled prescriptive deep-dive. Our innovative end-to-end analytics solution gives you relevant data & helps in strategy formulation & planning at the lowest cuts.",
            footer_content: "Private & Confidential",
            table_heading1: "Comparitive ROI",
            username: userName,
            timeframe: timeframe,
            start_date: "",
            end_date: "",
            category: ChannelCategory,
            chartdata: Chartdata,
            brand: Analyse.Brand,
            kpi: Analyse.KPI,
            tabname: "roi",
          },
        }
      );
      if (pdfResponse.data.error === 1) {
        toast.error(pdfResponse.data.erroMsg);
        setLoader(false);
      } else {
        // console.log("response pdf: ", pdfResponse.data);
        setLoader(false);
        var link = document.createElement("a");
        link.target = "_blank";
        link.href = pdfResponse.data.pdf_url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      setLoader(false);
      console.error("Error while generating pdf:", error);
    }
  };

  useEffect(() => {
    if (refGraphDiv.current !== null) {
      setChartHeight(refGraphDiv.current.clientHeight);
    }
  }, [Series]);

  // When Activefilter change : prepare chartdata for input of get api call
  // useEffect(() => {
  //   const chartdata = {};
  //   const ChartdataTemp = structuredClone(Chartdata);
  //   // console.log("ChartdataTemp: ", ChartdataTemp);

  //   if (Fixed.length !== 0) {
  //     for (var r = 0; r < Fixed.length; r++) {
  //       const Filter = [];
  //       Filter.push(Fixed[r].value)
  //       chartdata[Fixed[r].id] = {
  //         selection: [],
  //         Filter: Filter,
  //       };
  //     }
  //   }

  //   if (ActiveFilters.length !== 0) {
  //     for (var i = 0; i < ActiveFilters.length; i++) {
  //       const selection = [];
  //       if ("Selected" in ActiveFilters[i]) {
  //         if (ActiveFilters[i]["Selected"].length > 0) {
  //           setResetFilterFlag(true)
  //         }

  //         for (var j = 0; j < ActiveFilters[i].Selected.length; j++) {
  //           var temp_arr = ActiveFilters[i].Selected[j].value.split("-");
  //           selection.push(temp_arr[temp_arr.length - 1]);
  //         }
  //       }

  //       chartdata[ActiveFilters[i].name] = {
  //         // selection: typeof Chartdata[ActiveFilters[i].name] !== 'undefined' ? Chartdata[ActiveFilters[i].name].selection : [],
  //         // // Filter: selection,
  //         // Filter: typeof Chartdata[ActiveFilters[i].name] !== 'undefined' ? Chartdata[ActiveFilters[i].name].Filter : [],
  //         selection: [],
  //         Filter: [],
  //       };
  //     }
  //   }

  //   for (var k = 0; k < CompActiveFilters.length; k++) {
  //     const selection = [];
  //     if ("Selected" in CompActiveFilters[k]) {
  //       if (CompActiveFilters[k]["Selected"].length > 0) {
  //         setResetFilterFlag(true)
  //       }

  //       for (var l = 0; l < CompActiveFilters[k].Selected.length; l++) {
  //         selection.push(CompActiveFilters[k].Selected[l].value);
  //       }
  //     }
  //     chartdata[CompActiveFilters[k].name] = {
  //       selection: [],
  //       Filter: selection,
  //     };
  //   }

  //   const keys = Object.keys(chartdata);
  //   const lastKeyIndex = keys.length - 1;

  //   for (let i = 0; i < lastKeyIndex; i++) {
  //     const key = keys[i];
  //     if (ChartdataTemp.hasOwnProperty(key)) {
  //       chartdata[key].selection = ChartdataTemp[key].selection;
  //     }
  //   }

  //   // for (const key in ChartdataTemp) {
  //   //   if(chartdata.hasOwnProperty(key)) {
  //   //     chartdata[key].selection = ChartdataTemp[key].selection;
  //   //   }
  //   // }

  //   setChartdata(chartdata);
  //   setFetchCall(true);
  //   setSelectedCheckboxes([]);
  //   setSelectedCheckboxesC([]);
  // }, [ActiveFilters, CompActiveFilters]);

  // console.log("Chartdata: ", Chartdata);

  useEffect(() => {
    console.log("resultt first time before ");
    if (changeFilter === false) {
      const chartdata = JSON.parse(localStorage.getItem("Chartdata"));
      console.log("resultt first time ", chartdata);
      if (JSON.stringify(chartdata) !== JSON.stringify(Chartdata)) {
        setChartdata(chartdata);
      }
      // setIsChartdataReady(true);
      setFetchCall(true);
      setSelectedCheckboxes([]);
      setSelectedCheckboxesC([]);
    }
  }, [Analyse.Brand, Analyse.KPI, ActiveTab]);

  const AddHeirarchyFilter = (index) => {
    const ActiveFiltersTemp = JSON.parse(JSON.stringify(ActiveFilters));
    let filter = AllFilters[index];

    console.log("Add H");
    // Assuming you want to select the first filter by default as in AddCompFilter
    // filter.Selected = [filter?.filters[0]];

    ActiveFiltersTemp.push(filter);
    setActiveFilters(ActiveFiltersTemp);
    setFilterTabShow(true);

    // Update the localStorage with the new filter data
    const AnalyticFilterTemp = {
      AllFilters: AllFilters,
      Fixed: Fixed,
      ActiveFilters: ActiveFiltersTemp,
    };

    localStorage.setItem("AnalyticFilter", JSON.stringify(AnalyticFilterTemp));
    updateChartData();
  };

  const RemoveHeirarchyFilter = (item) => {
    const ActiveFiltersCompTemp = JSON.parse(JSON.stringify(ActiveFilters));
    for (let i = 0; i < ActiveFiltersCompTemp.length; i++) {
      if (ActiveFiltersCompTemp[i].name === item.name) {
        ActiveFiltersCompTemp.splice(i, 1);
        setActiveFilters(ActiveFiltersCompTemp);
      }
    }
    setFilterTabShow(true);

    //Set analytical filters data in localstorage
    const AnalyticFilterTemp = {
      AllFilters: AllFilters,
      Fixed: Fixed,
      ActiveFilters: ActiveFiltersCompTemp,
    };

    localStorage.setItem("AnalyticFilter", JSON.stringify(AnalyticFilterTemp));
    updateChartData();
  };

  const IsInAllHeirarchyFilters = (item) => {
    for (let i = 0; i < ActiveFilters.length; i++) {
      if (ActiveFilters[i].name === item.name) {
        return true;
      }
    }
    return false;
  };

  //CHANNEL FILTERS
  const AddCompFilter = (index) => {
    const ActiveFiltersCompTemp = JSON.parse(JSON.stringify(CompActiveFilters));
    let filter = CompAllFilters[index];
    filter.Selected = [filter.filters[0]];

    ActiveFiltersCompTemp.push(filter);
    setCompActiveFilters(ActiveFiltersCompTemp);
    setFilterTabShow(true);

    //Set campaign filter data in localstorage
    const CampaignAnalyticFilter = {
      CompAllFilters: CompAllFilters,
      CompActiveFilters: ActiveFiltersCompTemp,
    };

    localStorage.setItem(
      "CampaignAnalyticFilter",
      JSON.stringify(CampaignAnalyticFilter)
    );
    updateChartData();
  };

  const RemoveCompFilter = (item) => {
    const ActiveFiltersCompTemp = JSON.parse(JSON.stringify(CompActiveFilters));
    for (let i = 0; i < ActiveFiltersCompTemp.length; i++) {
      if (ActiveFiltersCompTemp[i].name === item.name) {
        ActiveFiltersCompTemp.splice(i, 1);
        setCompActiveFilters(ActiveFiltersCompTemp);
      }
    }
    setFilterTabShow(true);

    //Set campaign filter data in localstorage
    const CampaignAnalyticFilter = {
      CompAllFilters: CompAllFilters,
      CompActiveFilters: ActiveFiltersCompTemp,
    };

    localStorage.setItem(
      "CampaignAnalyticFilter",
      JSON.stringify(CampaignAnalyticFilter)
    );
    updateChartData();
  };

  const IsInAllCompFilters = (item) => {
    for (let i = 0; i < CompActiveFilters.length; i++) {
      if (CompActiveFilters[i].name === item.name) {
        return true;
      }
    }
    return false;
  };

  // Handle Initial Level
  useEffect(()=>{

    const handleInitailLevel = (level) => {
      console.log("nonclicklist NotAllow", NotAllow);
      const keysArrayOfChartData = Object.keys(Chartdata);
      const ChartdataTemp = structuredClone(Chartdata);
  
      // Create an array of empty arrays for each key in Chartdata
      const result = keysArrayOfChartData.map(() => []);
  
      // Split each item in NotAllow by "-" and push the resulting arrays into result
      NotAllow.forEach((item) => {
        const splitArray = item.split("-");
        result.forEach((res, index) => {
          // For each result array, push the corresponding slice of splitArray
          res.push(splitArray.slice(0, index + 1).join("-"));
        });
      });
  
      // Create the payload array, filtering out duplicates
      let payloadArray = result.map((item) => {
        return [...new Set(item)]; // Remove duplicates
      });
  
      console.log(
        "Payload Array after nonclicklist:",
        payloadArray.slice(0, payloadArray.length - 1)
      );
  
      let SkipData = JSON.parse(
        localStorage.getItem("StoreAnalyticFilter")
      ).Fixed;
  
      console.log("SkipData.length initialLevel",SkipData.length);
      // Assign Selection to Skipdata
      for(let i=0;i<=SkipData.length-InitialLevel-1;i++){
        SkipData.pop();
      }
      console.log("SkipData initialLevel",SkipData);
      
      // Step 1: Update `ChartdataTemp` for ids in `SkipData`
      // Loop through the sliced SkipData
      // SkipData?.forEach((skipItem) => {
      //   const skipId = skipItem.id;
  
      //   // Check if ChartdataTemp includes the skipId as a key
      //   if (ChartdataTemp.hasOwnProperty(skipId)) {
      //     // Make the selection array same as the filter array
      //     // ChartdataTemp[skipId].selection = ChartdataTemp[skipId].Filter;
      //     ChartdataTemp[skipId].Filter=[];
      //   }
      // });
  
      payloadArray = payloadArray.slice(0, payloadArray.length - 1);
      let SkipLength = SkipData?.length;
      let maxIterations = keysArrayOfChartData.length - SkipLength;
  
      // Step 2: Update `ChartdataTemp` with `payloadArray` after handling `SkipData`
      keysArrayOfChartData.slice(0, -1).forEach((key, index) => {
        if (!SkipData?.some((skipItem) => skipItem.id === key)) {
          if (ChartdataTemp.hasOwnProperty(key)) {
            // Set the selection to the corresponding payloadArray values
            console.log("nonclicklist ", key, "   ", payloadArray[index]);
            ChartdataTemp[key].Filter =  [];
          }
        }
      });
  
  
      console.log("ChartdataTemp nonclicklist initialLevel", ChartdataTemp,"    ",Chartdata);
      // setFetchCall(true);
      // setChartdata(ChartdataTemp);
      // setShowChat(true);
  
    }
    if(NotAllow!==undefined){
    handleInitailLevel(InitialLevel);
    }

  },[InitialLevel,Analyse.Brand,Analyse.KPI])
 

  useEffect(() => {
    if (JSON.stringify(Chartdata) !== "{}") {
      const cloneOfChartdata = structuredClone(Chartdata);
      const allKeysAlreadyInChartdata = Object.keys(cloneOfChartdata);

      // Retrieve SkipData from local storage
      let SkipData = JSON.parse(
        localStorage.getItem("StoreAnalyticFilter")
      ).Fixed;
      SkipData.pop();

      const skipIds = SkipData.map((item) => item.id);

      // Prepare a list to store lengths of valid breadcrumb selections
      const listToStoreLength = [];

      for (const key in cloneOfChartdata) {
        const list = cloneOfChartdata[key]["selection"];
        console.log(
          "allKeysAlreadyInChartdata list ",
          list,
          "    ",
          !skipIds.includes(key),
          "  ",
          cloneOfChartdata[key]
        );
        // Only process if the list length is greater than 0 and the key is not in skipIds
        if (list.length > 0 && !skipIds.includes(key)) {
          for (const name of list) {
            const arr = name.split("-");
            listToStoreLength.push(arr.length);
            console.log("allKeysAlreadyInChartdata arr", arr);
          }
        }
      }

      let maxNumber = Math.max(...listToStoreLength);

      // If listToStoreLength is empty, set maxNumber to 0
      if (listToStoreLength.length === 0) {
        maxNumber = 0;
      }

      // Set the breadcrumbs based on the maximum length
      console.log(
        "allKeysAlreadyInChartdata ",
        allKeysAlreadyInChartdata,
        "  ",
        maxNumber,
        " "
      );
      setBreadCrumbs(
        allKeysAlreadyInChartdata.slice(
          SkipData?.length,
          maxNumber + 1 + SkipData?.length
        )
      );
    }
  }, [Chartdata]);

  // for point click
  const DrillDown = (category) => {
    console.log("clicked category point click: ", category);

    const splitNameList = category.split("-");
    const Length = splitNameList.length;

    console.log("Length point click: ", Length);

    let SkipData = JSON.parse(
      localStorage.getItem("StoreAnalyticFilter")
    ).Fixed;

    SkipData.pop();

    let SkipLength = SkipData?.length;
    console.log("SKIPDATA ", SkipData, SkipLength);

    const ChartdataTemp = structuredClone(Chartdata);
    const allKeysAlreadyInChartdata = Object.keys(Chartdata);
    // console.log("allKeysAlreadyInChartdatra: ", allKeysAlreadyInChartdata);

    const keyToAddSelectionFilterInChartdata =
      allKeysAlreadyInChartdata[Length - 1 + SkipLength];

    console.log(
      "keyToAddSelectionFilterInChartdata: ",
      keyToAddSelectionFilterInChartdata
    );

    let cloneOfChartdata = structuredClone(Chartdata);

    SkipData?.forEach((skipItem) => {
      const skipId = skipItem.id;

      // Check if ChartdataTemp includes the skipId as a key
      if (cloneOfChartdata.hasOwnProperty(skipId)) {
        // Make the selection array same as the filter array
        cloneOfChartdata[skipId].selection = cloneOfChartdata[skipId].Filter;
      }
    });

    if (cloneOfChartdata.hasOwnProperty(keyToAddSelectionFilterInChartdata)) {
      cloneOfChartdata[keyToAddSelectionFilterInChartdata].selection.push(
        category
      );
    }

    setChartdata(cloneOfChartdata);
    setFetchCall(true);
    // setClickedCategory(Category);
    setIsAllEnabledDownload(0);
  };

  const convertLabelToId = (label, displayNames) => {
    // Split the label by hyphens
    const labelParts = label.split("-");

    // Convert each part into its corresponding id
    const idParts = labelParts.map((part) => {
      // Find the key where the value matches the part

      const foundId = Object.keys(displayNames).find(
        (id) => displayNames[id] === part
      );
      return foundId || part; // Use the part itself if no matching id is found
    });

    // Join the id parts with hyphens
    const resultId = idParts.join("-");

    return resultId;
  };

  // for label click
  const DrillUp = (category) => {
    // console.log("category: ", category.value);
    var span = document.createElement("span");
    span.innerHTML = category.value;
    const label = span.firstChild.getAttribute("id");
    // const labelIDdiv=category.value.match(/id="([^"]+)"/)[1]

    let label_id = label;
    console.log("label: before", label_id);

    const convertedId = label;

    console.log("label: ", convertedId);
    const ChartdataTemp = structuredClone(Chartdata);
    let parentClear = false;
    for (const key in ChartdataTemp) {
      // Remove the label from the selection array if it is present
      ChartdataTemp[key].selection = ChartdataTemp[key].selection.filter(
        (value) => value !== convertedId
      );
      if (parentClear) {
        ChartdataTemp[key].selection = [];
      }
      if (ChartdataTemp[key].selection.length === 0) {
        parentClear = true;
      }
    }

    console.log("label: chartDatatemp ", ChartdataTemp);

    setChartdata(ChartdataTemp);
    setFetchCall(true);
    setIsAllEnabledDownload(0);
  };

  // for down arrow
  const DrillDownAll = () => {
    // Get the keys in Chartdata
    const keysAlreadyInChartData = Object.keys(Chartdata);

    // Clone Chartdata to avoid mutating the original object
    const ChartdataTemp = structuredClone(Chartdata);

    // Retrieve SkipData from local storage
    let SkipData = JSON.parse(
      localStorage.getItem("StoreAnalyticFilter")
    ).Fixed;

    SkipData.pop();

    let SkipLength = SkipData?.length;
    // Extract the IDs from SkipData
    const skipIds = SkipData?.map((item) => item.id);

    // Create empty arrays based on the number of keys in Chartdata
    const result = keysAlreadyInChartData.map(() => []);

    // Process the NotAllow array
    NotAllow.forEach((item) => {
      const splitArray = item.split("-");
      result.forEach((res, index) => {
        // For each result array, push the corresponding slice of splitArray
        res.push(splitArray.slice(0, index + 1).join("-"));
      });
    });

    // Create the payload array, filtering out duplicates
    let payloadArray = result.map((item) => {
      return [...new Set(item)]; // Remove duplicates
    });

    payloadArray = payloadArray.slice(0, payloadArray.length - 1);

    // Find the next element that is not in SkipData and has no selection or shorter selection
    let nextKey = null;
    let nextIndex = -1;
    SkipData?.forEach((skipItem) => {
      const skipId = skipItem?.id;

      // Check if ChartdataTemp includes the skipId as a key
      if (ChartdataTemp.hasOwnProperty(skipId)) {
        // Make the selection array same as the filter array
        ChartdataTemp[skipId].selection = ChartdataTemp[skipId].Filter;
      }
    });

    for (let i = 0; i < keysAlreadyInChartData.length - 1; i++) {
      const key = keysAlreadyInChartData[i];
      if (
        ChartdataTemp.hasOwnProperty(key) &&
        !skipIds?.includes(key) &&
        ChartdataTemp[key].selection.length === 0
      ) {
        nextKey = key;
        nextIndex = i;
        break;
      }
    }

    // If a nextKey is found and it's not in SkipData, set its selection with processedNotAllow data
    if (nextKey && ChartdataTemp.hasOwnProperty(nextKey)) {
      ChartdataTemp[nextKey].selection = payloadArray[nextIndex - SkipLength];
    }

    console.log("DrillDownAll - Updated ChartdataTemp:", ChartdataTemp);

    // Update the state with the modified ChartdataTemp
    setChartdata(ChartdataTemp);
    setFetchCall(true);
    setIsAllEnabledDownload(0);
  };

  // for up arrow
  const DrillUpAll = () => {
    // Get the keys in Chartdata
    const keysAlreadyInChartData = Object.keys(Chartdata);

    // Clone Chartdata to avoid mutating the original object
    const ChartdataTemp = structuredClone(Chartdata);

    // Retrieve SkipData from local storage
    let SkipData = JSON.parse(
      localStorage.getItem("StoreAnalyticFilter")
    ).Fixed;
    SkipData.pop();
    // Extract the IDs from SkipData
    const skipIds = SkipData?.map((item) => item.id);

    // Identify the last key in ChartdataTemp that has a non-empty selection and is not in SkipData
    const lastKey = keysAlreadyInChartData.reverse().find((key) => {
      return (
        ChartdataTemp[key].selection &&
        ChartdataTemp[key].selection.length > 0 &&
        !skipIds?.includes(key)
      );
    });

    // If a lastKey is found and it's not in SkipData, clear its selection
    if (lastKey && ChartdataTemp.hasOwnProperty(lastKey)) {
      ChartdataTemp[lastKey].selection = [];
    }

    console.log("DrillUpAll - Updated ChartdataTemp:", ChartdataTemp);

    // Update the state with the modified ChartdataTemp
    setChartdata(ChartdataTemp);
    setFetchCall(true);
    setIsAllEnabledDownload(0);
  };

  // tree button
  const DrillAll = () => {
    console.log("nonclicklist ", NotAllow);
    const keysArrayOfChartData = Object.keys(Chartdata);
    const ChartdataTemp = structuredClone(Chartdata);

    // Create an array of empty arrays for each key in Chartdata
    const result = keysArrayOfChartData.map(() => []);

    // Split each item in NotAllow by "-" and push the resulting arrays into result
    NotAllow.forEach((item) => {
      const splitArray = item.split("-");
      result.forEach((res, index) => {
        // For each result array, push the corresponding slice of splitArray
        res.push(splitArray.slice(0, index + 1).join("-"));
      });
    });

    // Create the payload array, filtering out duplicates
    let payloadArray = result.map((item) => {
      return [...new Set(item)]; // Remove duplicates
    });

    console.log(
      "Payload Array after nonclicklist:",
      payloadArray.slice(0, payloadArray.length - 1)
    );

    let SkipData = JSON.parse(
      localStorage.getItem("StoreAnalyticFilter")
    ).Fixed;

    // Assign Selection to Skipdata
    SkipData.pop();
    // Step 1: Update `ChartdataTemp` for ids in `SkipData`
    // Loop through the sliced SkipData
    SkipData?.forEach((skipItem) => {
      const skipId = skipItem.id;

      // Check if ChartdataTemp includes the skipId as a key
      if (ChartdataTemp.hasOwnProperty(skipId)) {
        // Make the selection array same as the filter array
        ChartdataTemp[skipId].selection = ChartdataTemp[skipId].Filter;
      }
    });

    payloadArray = payloadArray.slice(0, payloadArray.length - 1);
    let SkipLength = SkipData?.length;

    // Step 2: Update `ChartdataTemp` with `payloadArray` after handling `SkipData`
    keysArrayOfChartData.slice(0, -1).forEach((key, index) => {
      if (!SkipData?.some((skipItem) => skipItem.id === key)) {
        if (ChartdataTemp.hasOwnProperty(key)) {
          // Set the selection to the corresponding payloadArray values
          console.log("nonclicklist ", key, "   ", payloadArray[index]);
          ChartdataTemp[key].selection = payloadArray[index - SkipLength] || [];
        }
      }
    });

    console.log("ChartdataTemp nonclicklist", ChartdataTemp);

    setIsAllEnabled(1);
    setFetchCall(true);
    setChartdata(ChartdataTemp);
  };

  // for reset button
  const ResetAll = () => {
    let ChartdataTemp = {};
    for (const filter in Chartdata) {
      ChartdataTemp[filter] = Chartdata[filter];
      ChartdataTemp[filter].selection = [];
    }

    setReset(1);
    setChartdata(ChartdataTemp);
    setFetchCall(true);
    setIsAllEnabledDownload(0);
  };

  // new filters functions end..........

  const ResetFilters = () => {
    var active_filter = [];
    for (let i = 0; i < ActiveFilters.length; i++) {
      var obj = ActiveFilters[i];
      obj["Selected"] = [];
      active_filter.push(obj);
    }

    var comp_active_filter = [];
    for (let k = 0; k < CompActiveFilters.length; k++) {
      var obj1 = CompActiveFilters[k];
      obj1["Selected"] = [];
      comp_active_filter.push(obj1);
    }

    setResetFilterFlag(false);
    //Set analytical filters data in localstorage
    const AnalyticFilterTemp = {
      AllFilters: AllFilters,
      Fixed: Fixed,
      ActiveFilters: active_filter,
    };

    localStorage.setItem("AnalyticFilter", JSON.stringify(AnalyticFilterTemp));

    const CampaignAnalyticFilter = {
      CompAllFilters: CompAllFilters,
      CompActiveFilters: comp_active_filter,
    };

    localStorage.setItem(
      "CampaignAnalyticFilter",
      JSON.stringify(CampaignAnalyticFilter)
    );

    setActiveFilters(active_filter);
    setCompActiveFilters(comp_active_filter);
  };

  const [full, setFull] = useState(true);
  const [divWidth, setDivWidth] = useState("");

  const handler = () => {
    setFull(!full);
  };

  // console.log("divWidth: ", divWidth);

  useEffect(() => {
    const updateDivHeight = () => {
      // console.log("ran")
      if (DivRef.current !== null) {
        const newWidth = DivRef.current.clientWidth;
        // console.log("newWidth: ", newWidth);
        setDivWidth(newWidth);
      }
    };

    updateDivHeight(); // Initial update
    window.addEventListener("resize", updateDivHeight);

    return () => {
      window.removeEventListener("resize", updateDivHeight);
    };
  }, []);

  const [HFilters, setHFilters] = useState([]);
  // console.log("HFilters: ", HFilters);
  // console.log("Chartdata: ", Object.keys(Chartdata));

  const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
  // console.log("selectedCheckboxes: ", selectedCheckboxes);

  const filteredKeys = Object.keys(Chartdata).filter(
    (key) => !key.startsWith("C")
  );

  useEffect(() => {
    const fetchHierarchyFilters = async () => {
      if (JSON.stringify(Chartdata) !== "{}") {
        try {
          const response = await axios.post("hieracrhy_data/", {
            hierarchy_list: filteredKeys,
            brand: Analyse.Brand,
          });

          const data = response.data;
          // console.log(data);
          setHFilters(data.data);
          // localStorage.setItem("hierarchyFilters", JSON.stringify(data.data));
        } catch (error) {
          console.error("Failed to fetch:", error);
        }
      }
    };
    fetchHierarchyFilters();
  }, [Chartdata, Analyse.Brand]);

  const [CFilters, setCFilters] = useState([]);
  // console.log("HFilters: ", HFilters);
  // console.log("Chartdata: ", Object.keys(Chartdata));

  const [selectedCheckboxesC, setSelectedCheckboxesC] = useState({});
  // console.log("selectedCheckboxes: ", selectedCheckboxes);

  const filteredKeysC = Object.keys(Chartdata).filter(
    (key) => !key.startsWith("H")
  );

  useEffect(() => {
    const fetchChannelFilters = async () => {
      if (JSON.stringify(Chartdata) !== "{}" && filteredKeysC.length > 0) {
        try {
          const response = await axios.post("channel_hierarcy/", {
            channel_list: filteredKeysC,
            brand: Analyse.Brand,
          });

          const data = response.data;
          // console.log(data);
          setCFilters(data.data);
          // localStorage.setItem("channelFilters", JSON.stringify(data.data));
        } catch (error) {
          console.error("Failed to fetch:", error);
        }
      }
    };
    fetchChannelFilters();
  }, [Chartdata, Analyse.Brand]);

  const updateChartData = (changeFilter = [], channelFilter = []) => {
    // Initialize the result object
    const result = {};
    console.log("resultt updatedChartData");
    const filterdata = JSON.parse(localStorage.getItem("AnalyticFilter"));
    const ChannelFilterData = JSON.parse(
      localStorage.getItem("CampaignAnalyticFilter")
    );

    // Populate the result object with Fixed filters
    filterdata.Fixed.forEach((item) => {
      result[item.id] = {
        selection: [],
        Filter: [item.value],
      };
    });

    if (changeFilter.length === 0) {
      // Populate the result object with AllFilters that are not in Fixed
      filterdata.ActiveFilters.forEach((item) => {
        if (!result[item.name]) {
          result[item.name] = {
            selection: [],
            Filter: [],
          };
        }
      });
    } else {
      changeFilter.forEach((item) => {
        if (!result[item.name]) {
          result[item.name] = {
            selection: [],
            Filter: [],
          };
        }
      });
    }

    if (channelFilter.length === 0) {
      // Populate the result object with AllFilters that are not in Fixed
      ChannelFilterData.CompActiveFilters.forEach((item) => {
        if (!result[item.name]) {
          result[item.name] = {
            selection: [],
            Filter: [],
          };
        }
      });
    } else {
      channelFilter.forEach((item) => {
        if (!result[item.name]) {
          result[item.name] = {
            selection: [],
            Filter: [],
          };
        }
      });
    }
    console.log("resultt ", result, " ", Chartdata);
    setFetchCall(true);
    setChartdata(result);
    setChangeFilter(true);
    setSelectedCheckboxes([]);
    setSelectedCheckboxesC([]);
  };

  return (
    <>
      {loader ? <Loader /> : null}
      <ToastContainer theme="colored" />
      <Topbar />
      <Menubar />
      <div className="main-content">
        <div className="page-content">
          {/* change 2 class add */}
          <div className="filter-class">
            <AllHeirarchyFilter
              setHierarchySelectionBreadCrumb={setHierarchySelectionBreadCrumb}
              key={Chartdata}
              updateChartData={updateChartData}
              OnlyHeirarchyFilter={false}
              Fixed={Fixed}
              ActiveFilters={ActiveFilters}
              AllFilters={AllFilters}
              AddHeirarchyFilter={AddHeirarchyFilter}
              RemoveHeirarchyFilter={RemoveHeirarchyFilter}
              IsInAllHeirarchyFilters={IsInAllHeirarchyFilters}
              Chartdata={Chartdata}
              setChartdata={setChartdata}
              setFetchCall={setFetchCall}
              HFilters={HFilters}
              setActiveFilters={setActiveFilters}
              selectedCheckboxes={selectedCheckboxes}
              setSelectedCheckboxes={setSelectedCheckboxes}
            />
            <ChannelFilter
              setChannelSelectionBreadCrumb={setChannelSelectionBreadCrumb}
              key={filteredKeysC}
              updateChartData={updateChartData}
              CompActiveFilters={CompActiveFilters}
              CompAllFilters={CompAllFilters}
              AddCompFilter={AddCompFilter}
              RemoveCompFilter={RemoveCompFilter}
              IsInAllCompFilters={IsInAllCompFilters}
              Chartdata={Chartdata}
              setChartdata={setChartdata}
              setFetchCall={setFetchCall}
              setCompActiveFilters={setCompActiveFilters}
              CFilters={CFilters}
              selectedCheckboxesC={selectedCheckboxesC}
              setSelectedCheckboxesC={setSelectedCheckboxesC}
            />
          </div>

          <div
            className="container-fluid main-content-w pt-1"
            style={{ paddingTop: "10px" }}
          >
            <div className="row">
              {/* {
                false ?
                  <ActiveHeirarchyAndCampaignFilter OnlyHeirarchyFilter={false} onCompChangeFilter={onCompChangeFilter} onChangeFilter={onChangeFilter} onDragEnd={onDragEnd} ActiveFilters={ActiveFilters} onCompDragEnd={onCompDragEnd} setFilterTabShow={setFilterTabShow} CompActiveFilters={CompActiveFilters} CompAllFilters={CompAllFilters} Fixed={Fixed} />
                  : null
              } */}

              <div
                className={
                  parseInt(ShowSummary) === 1 ? "col-xl-9" : "col-xl-12"
                }
              >
                <div
                  className=" card card-min-height synergy-table-height"
                  ref={DivRef}
                  style={{
                    height: `${window.innerHeight < 850
                        ? window.innerHeight * 0.82
                        : window.innerHeight * 0.87
                      }px`,
                  }}
                >
                  <div className="ef-drivers" style={{ padding: "10px" }}>
                    <div className="row align-items-center justify-content-sm-between">
                      <div
                        className="col-sm-4  gap-3"
                        style={{
                          display: "flex",
                          justifyContent: "left",
                          alignItems: "center",
                        }}
                      >
                        {ActiveTab === "lt_st" && (
                          <CustomSelect
                            value={timeframe}
                            onChange={(e) => {
                              setTimeframe(e.target.value);
                              setChannelCategory("");
                              setCategoryFlag(true);
                            }}
                            options={TimeFramesAll.map((item) => ({
                              key: item.key,
                              value: item.value,
                            }))}
                            isDetailPopUp={false}
                          />
                        )}

                        {DefaultStartDate &&
                          DefaultEndDate &&
                          startdate &&
                          enddate &&
                          ActiveTab === "comp" && (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <MultiCal2
                                // key={refreshKey}
                                setDefaultStartDate={setDefaultStartDate}
                                setDefaultEndDate={setDefaultEndDate}
                                DefaultStartDate={DefaultStartDate}
                                DefaultEndDate={DefaultEndDate}
                                rangeStartDate={startdate}
                                rangeEndDate={enddate}
                                setTimeframe={setTimeframe}
                                // onChangeHandler={onChangeHandler}
                                TimeFramesAll={TimeFramesAll}
                                timeframe={timeframe}
                                // SelectedChart={SelectedChart}
                                setIsSubmitEnabled={setIsSubmitEnabled}
                                submittedStartDate={submittedStartDate}
                                submittedEndDate={submittedEndDate}
                                submittedTimeFrame={submittedTimeFrame}
                                isRefreshEnabled={isRefreshEnabled}
                              />
                            </div>
                          )}

                        {ActiveTab === "comp" && (
                          <div
                            className=""
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <button
                              className="submit-btn"
                              disabled={isSubmitEnabled}
                              // className="btn-class"
                              type="button"
                              style={{
                                // marginTop:'-40px !important',
                                fontSize: "10px",
                                width: "80px",
                                height: "20px",
                              }}
                              onClick={() => {
                                setSubmittedStartDate(DefaultStartDate);
                                setSubmittedEndDate(DefaultEndDate);
                                setSubmittedTimeFrame(timeframe);
                                setIsRefreshEnabled(false);
                                setFetchCall(true);
                              }}
                            >
                              Submit
                            </button>
                          </div>
                        )}

                        {AllChannelCategory.length !== 0 &&
                          AllChannelCategory.length > 1 ? (
                          <CustomSelect
                            value={ChannelCategory}
                            onChange={(e) => {
                              setChannelCategory(e.target.value);
                              setFetchCall(true);
                            }}
                            options={AllChannelCategory.map((item) => ({
                              key: item.value,
                              value: item.label,
                            }))}
                            isDetailPopUp={false}
                          />
                        ) : null}
                      </div>

                      <div className="col-sm-5">
                        <p
                          className="card-title-lable"
                          style={{
                            paddingLeft: ActiveTab === "lt_st" ? "0px" : "0px",
                          }}
                        >
                          {ChartTitle}
                        </p>
                      </div>
                      <div className="col-sm-3 d-flex align-items-center justify-content-sm-end">
                        {Tabs.map((item) => {
                          return (
                            <p
                              key={item.tab}
                              style={{ cursor: "pointer" }}
                              className={
                                ActiveTab === item.tab
                                  ? "m-0 px-2 mx-2 lt-st custom-input-box tab-class-clicked"
                                  : "m-0 px-2 mx-2 text-lable custom-input-box tab-class-unclicked"
                              }
                              onClick={() => setActiveTab(item.tab)}
                            >
                              {item.tab_name}
                            </p>
                          );
                        })}
                        {/* <p
                          style={{ cursor: "pointer" }}
                          className={
                            ActiveTab === "halo"
                              ? "m-0 px-2 mx-2 lt-st custom-input-box"
                              : "m-0 px-2 mx-2 text-lable custom-input-box"
                          }
                          onClick={() => setActiveTab("halo")}
                          >
                            {"Halo"}
                        </p> */}
                        {Notes !== "" ? (
                          <>
                            <p
                              className="m-0 px-2"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                fontSize: "10px",
                              }}
                            >
                              <svg
                                src={InfoIcon}
                                id="notes"
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="25"
                                class="bi bi-info-circle icon-class"
                                viewBox="0 0 16 16"
                              >
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                              </svg>
                            </p>

                            <Tooltip anchorSelect="#notes" clickable>
                              {/* <div className="api-notes">
                                  {Notes}
                                </div> */}
                              <div
                                className="api-notes"
                                style={{
                                  height: "max-content",
                                  color: "white",
                                }}
                                dangerouslySetInnerHTML={{ __html: Notes }}
                              ></div>
                            </Tooltip>
                          </>
                        ) : null}

                        {downloadButtonFlag() && (
                          <div className="flex-shrink-0 dropdown">
                            <Link
                              to="#"
                              className="d-flex align-items-center text-decoration-none dropdown-toggle text-white"
                              id="dropdownUser2"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <p
                                className="m-0 icon-class"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  fontSize: "10px",
                                }}
                              >
                                <svg
                                  src={Download}
                                  title="Download"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="25"
                                  height="25"
                                  class="bi bi-download"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                </svg>
                              </p>
                            </Link>
                            <ul
                              className="dropdown-menu text-small shadow"
                              aria-labelledby="dropdownUser2"
                            >
                              {ActiveTab !== "comp" && downloadFlag.chart && (
                                <li onClick={(e) => { }}>
                                  <Link
                                    className="dropdown-item"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      if (Categories.length > 0) {
                                        // setDownloadchart(true)
                                        // setLoader(true)
                                        setIsDownloadingChart(true);
                                        setDownloadingChartTitle("ROI");
                                        setChartContainerID("roi_chart");
                                        setDownloadedImageName("ROI");
                                      } else {
                                        toast.error(
                                          "Chartdata is not available."
                                        );
                                      }
                                    }}
                                  >
                                    Download Chart
                                  </Link>
                                </li>
                              )}

                              {downloadFlag.data && (
                                <li onClick={(e) => { }}>
                                  {ActiveTab === "lt_st" ? (
                                    <Link
                                      className="dropdown-item"
                                      onClick={(e) => DownloadDataltstroi(e)}
                                    >
                                      Download Data
                                    </Link>
                                  ) : null}
                                  {ActiveTab === "comp" ? (
                                    <Link
                                      className="dropdown-item"
                                      onClick={(e) => DownloadDatacomproi(e)}
                                    >
                                      Download Data
                                    </Link>
                                  ) : null}
                                </li>
                              )}

                              {/* download pdf */}
                              {downloadFlag.pdf && (
                                <li onClick={(e) => { }}>
                                  {ActiveTab === "lt_st" ? (
                                    <Link
                                      className="dropdown-item"
                                      onClick={(e) => DownloadPDFltstroi(e)}
                                    >
                                      Download PDF
                                    </Link>
                                  ) : null}
                                  {ActiveTab === "comp" ? (
                                    <Link
                                      className="dropdown-item"
                                      onClick={(e) => DownloadPDFcomproi(e)}
                                    >
                                      Download PDF
                                    </Link>
                                  ) : null}
                                </li>
                              )}
                              {/* download pdf */}
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>

                    {ActiveTab === "lt_st" && Categories.length !== 0 ? (
                      <div className="h-600 chart-div" style={{}}>
                        <div className="col-sm-12 ">
                          <div className="row align-items-center sorting-btn-ui ">
                            <div className="col" style={{ padding: "0px" }}>
                              <ul>
                                <li
                                  onClick={() => {
                                    DrillUpAll();
                                  }}
                                  title="Drill Up"
                                >
                                  <i
                                    className="fa fa-arrow-up icon-class"
                                    aria-hidden="true"
                                  ></i>
                                </li>
                                <li
                                  onClick={() => {
                                    DrillDownAll();
                                  }}
                                  title="Drill Down"
                                >
                                  <i
                                    className="fa fa-arrow-down icon-class"
                                    aria-hidden="true"
                                  ></i>
                                </li>
                                <li
                                  onClick={() => {
                                    DrillAll();
                                  }}
                                  title="Expand All"
                                >
                                  <i
                                    className="fa fa-sitemap icon-class"
                                    aria-hidden="true"
                                  ></i>
                                </li>
                                <li
                                  onClick={() => {
                                    ResetAll();
                                    setFull(false);
                                  }}
                                  title="Reset"
                                >
                                  <i
                                    className="fa icon-class"
                                    aria-hidden="true"
                                  >
                                    &#8635;
                                  </i>
                                </li>
                                <li>
                                  {full ? (
                                    <i
                                      className="fas fa-magnifying-glass-minus icon-class"
                                      onClick={handler}
                                      style={{
                                        color: "white",
                                        cursor: "pointer",
                                      }}
                                    ></i>
                                  ) : (
                                    <i
                                      className="fas fa-magnifying-glass-plus icon-class"
                                      onClick={handler}
                                      style={{
                                        color: "white",
                                        cursor: "pointer",
                                      }}
                                    ></i>
                                  )}
                                </li>
                              </ul>
                            </div>

                            {/* <div className="col-sm-9 custom-brdcrum">
                              {BreadCrumbs.map((item) => {
                                return returnDisplayName(item);
                              }).join(" / ")}
                            </div> */}
                          </div>
                        </div>
                        <div
                          className="col-sm-12"
                          style={{ display: "flex", alignContent: "center" }}
                        >
                          <div
                            className="col-sm-6 custom-brdcrum2"
                            style={{
                              display: "flex",
                              alignContent: "center",
                              justifyContent: "start",
                              wordBreak: "break-word",
                              flexWrap: "wrap",
                              minHeight: "min-content",
                            }}
                          >
                            {hierarchySelectionBreadCrumb ||
                            channelSelectionBreadCrumb ? (
                              <>
                                <i
                                  className="fas fa-filter"
                                  style={{
                                    marginRight: "5px",
                                    fontSize: "12px",
                                  }} // Spacing and icon size adjustments
                                ></i>
                                {hierarchySelectionBreadCrumb &&
                                channelSelectionBreadCrumb
                                  ? `${hierarchySelectionBreadCrumb} / ${channelSelectionBreadCrumb}`
                                  : hierarchySelectionBreadCrumb ||
                                    channelSelectionBreadCrumb}
                              </>
                            ) : null}
                          </div>
 
                          <div className="col-sm-6 custom-brdcrum">
                            {BreadCrumbs.map((item) => {
                              return returnDisplayName(item);
                            }).join(" / ")}
                          </div>
                        </div>
                        <div
                          className=""
                          ref={refGraphDiv}
                          style={{ height: "100%" }}
                        >
                          
                          <LTSTROI
                            BreadCrumbs={BreadCrumbs}
                            ActiveFilters={ActiveFilters}
                            Brand={Analyse.Brand}
                            Fixed={Fixed}
                            Timeframe={timeframe}
                            setLoader={setLoader}
                            Xtitle={Xtitle}
                            Ytitle={Ytitle}
                            Categories={Categories}
                            Series={Series}
                            height={450}
                            DivHeight={400}
                            DrillDown={DrillDown}
                            DrillUp={DrillUp}
                            NotAllow={NotAllow}
                            Chartdata={Chartdata}
                            Downloadchart={Downloadchart}
                            setDownloadchart={setDownloadchart}
                            full={full}
                            divWidth={divWidth - 80}
                          />
                        </div>
                      </div>
                    ) : ActiveTab === "lt_st" ? (
                      <div className="mt-2">
                        <h6 style={{ textAlign: "center" }}>No Data Found</h6>
                      </div>
                    ) : null}

                    {ActiveTab === "comp" ? (
                      <ComparativeROI
                        Xtitle={Xtitle}
                        Ytitle={Ytitle}
                        ChannelYears={Years}
                        ChannelData={ChannelDataAll}
                        Legends={Legends}
                        height={ChartHeight}
                        showOpenLevel={showOpenLevel}
                        setShowOpenLevel={setShowOpenLevel}
                      />
                    ) : ActiveTab === "comp" ? (
                      <div className="mt-2">
                        <h6 style={{ textAlign: "center" }}>No Data Found</h6>
                      </div>
                    ) : null}
                    {/* Halo tab start */}
                    {/* {ActiveTab === "halo" ? <div>Halo</div> : <div>Halo</div>} */}
                    {/* Halo tab end */}
                  </div>
                </div>
              </div>

              {parseInt(ShowSummary) === 1 ? (
                <div className="col-xl-3">
                  <div className="">
                    <div className="">
                      <div className="row align-items-center justify-content-sm-between">
                        <h3 className="data-spends my-2">Summary</h3>
                        <div className="spends-list my-2">
                          <ol>
                            {Summary.map((item, index) => {
                              return (
                                <li
                                  key={item + index}
                                  className="spends-list-li"
                                >
                                  {item}
                                </li>
                              );
                            })}
                          </ol>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>

            <div className="row custom-note">
              <div className="col-sm-1">
                <div className="col-sm-1">
                  {FooterNotes !== "" ? (
                    <>
                      <i
                        className="fas fa-mail-bulk chart_info_icon footer-notes-icon icon-class"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "15px",
                          marginLeft: "6px",
                          marginBottom: "10px",
                        }}
                        id="clickable"
                      ></i>
                      <Tooltip anchorSelect="#clickable" clickable>
                        {/* <div className="footer-notes">{FooterNotes}</div> */}
                        <div
                          className="footer-notes"
                          style={{ height: 'max-content' }}
                          dangerouslySetInnerHTML={{ __html: FooterNotes }}
                        ></div>
                      </Tooltip>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
