import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import '../assets/ManualCSS/Menubar.css';  // Make sure to import your CSS file

const Menubar = () => {
  const location = useLocation();
  const Analyse = JSON.parse(localStorage.getItem("Analyse"));

  // useEffect(() => {
  //   // This will run every time the route changes
  //   console.log("Current path:", location.pathname.split("/")[1]);
  // }, [location]); // Add 'location' as a dependency to run useEffect on route change


  return (
    <div>
      <div className="topnav p-0 navbar-heading">
        <div className="max-w-100 p-0">
          <div className=" w-100 m-0">
            <div className="col-sm-12 sub-menu-bar">
              <nav className="navbar navbar-light navbar-expand-lg topnav-menu">
                <div className="collapse navbar-collapse" id="topnav-menu-content">
                  <ul className="navbar-nav w-100 justify-content-sm-between ">
                    {Analyse.Menubar.map((item) => (
                      item.children && item.children.length > 0 ? (
                        item.children.map((child) => (
                          <li key={child.id} className="nav-item">
                            <Link
                              className={
                                location.pathname.split("/")[1] === child.new_id
                                  ? "nav-link active nav-div fontSize"
                                  : "nav-link nav-div fontSize"
                              }
                              to={"/" + child.new_id}
                            >
                              {child.name}
                            </Link>
                          </li>
                        ))
                      ) : (
                        <li key={item.id} className="nav-item">
                          <Link
                            className={
                              location.pathname.split("/")[1] === item.new_id
                                ? "nav-link active nav-div"
                                : "nav-link nav-div"
                            }
                            to={"/" + item.new_id}
                          >
                            {item.name}
                          </Link>
                        </li>
                      )
                    ))}



                    <li className="nav-item">
                      <Link
                        className={
                          location.pathname === "/savedresults"
                            ? "nav-link active nav-div"
                            : "nav-link nav-div"
                        }
                        to={"/savedresults"}
                      >
                        Saved Results
                      </Link>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menubar;
