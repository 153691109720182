import React, { useState, useRef, useEffect } from 'react';
import appContext from './appContext';

function AppState(props) {
  // Initialize states with either the value from localStorage or the default value
  const [isOpen, setIsOpen] = useState(() => JSON.parse(localStorage.getItem('isOpen')) || false);
  const [DetailsId, setDetailsId] = useState(() => localStorage.getItem('DetailsId') || "");
  const [Details, setDetails] = useState(() => JSON.parse(localStorage.getItem('Details')) || {});
  const [DisplayName, setDisplayName] = useState(() => JSON.parse(localStorage.getItem('DisplayName')) || "");
  const [AnalyticFilter, setAnalyticFilter] = useState(() => JSON.parse(localStorage.getItem('AnalyticFilter')) || {});
  const [DefaultLevel, setDefaultLevel] = useState(() => localStorage.getItem('DefaultLevel') || "");
  const [Chartdata, setChartdata] = useState(() => JSON.parse(localStorage.getItem('Chartdata')) || "");
  const [Analyse, setAnalyse] = useState(() => JSON.parse(localStorage.getItem('Analyse')) || "");
  const [CampaignAnalyticFilter, setCampaignAnalyticFilter] = useState(() => JSON.parse(localStorage.getItem('CampaignAnalyticFilter')) || "");
  const [BrandDetails, setBrandDetails] = useState(() => JSON.parse(localStorage.getItem('BrandDetails')) || "");
  const node = useRef(null);

  // useEffect hooks to persist data to localStorage whenever the state changes
  useEffect(() => {
    localStorage.setItem('isOpen', JSON.stringify(isOpen));
  }, [isOpen]);

  useEffect(() => {
    localStorage.setItem('DetailsId', DetailsId);
  }, [DetailsId]);

  useEffect(() => {
    localStorage.setItem('Details', JSON.stringify(Details));
  }, [Details]);

  useEffect(() => {
    localStorage.setItem('DisplayName', JSON.stringify(DisplayName));
  }, [DisplayName]);

  useEffect(() => {
    localStorage.setItem('AnalyticFilter', JSON.stringify(AnalyticFilter));
  }, [AnalyticFilter]);

  useEffect(() => {
    localStorage.setItem('DefaultLevel', DefaultLevel);
  }, [DefaultLevel]);

  useEffect(() => {
    localStorage.setItem('Chartdata',JSON.stringify(Chartdata));
  }, [Chartdata]);

  useEffect(() => {
    localStorage.setItem('Analyse', JSON.stringify(Analyse));
  }, [Analyse]);

  useEffect(() => {
    localStorage.setItem('CampaignAnalyticFilter', JSON.stringify(CampaignAnalyticFilter));
  }, [CampaignAnalyticFilter]);

  useEffect(() => {
    localStorage.setItem('BrandDetails', JSON.stringify(BrandDetails));
  }, [BrandDetails]);

  return (
    <appContext.Provider value={{
      isOpen, setIsOpen, DetailsId, setDetailsId, Details, setDetails, node, DisplayName, setDisplayName,
      AnalyticFilter, setAnalyticFilter, DefaultLevel, setDefaultLevel, Chartdata, setChartdata, Analyse, setAnalyse,
      CampaignAnalyticFilter, setCampaignAnalyticFilter, BrandDetails, setBrandDetails,
    }}>
      {props.children}
    </appContext.Provider>
  )
}

export default AppState;
