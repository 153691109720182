import React, { useState } from "react";
import CustomSelect from "./CustomSelect";

const SummaryAnalytics = (props) => {
  const [FilterType, setFilterType] = useState(true);

  return (
    <div
      className="col-xs-12 col-md-12 col-lg-12 col-xl-4"
      style={{ height: props.height + "px" }}
    >
      <div
        className="card custom-summary"
        style={{ height: props.height + "px" }}
      >
        <div className="card-body p-0">
          <div className="card-3">
            <div className="custom-header mb-1">
              <span
                className="header-title cursor-pointer"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                onClick={() =>
                  parseInt(props.ShowSummary) === 1
                    ? setFilterType(!FilterType)
                    : ""
                }
              >
                <h3 className="data-spends my-2 px-2">Analytics Table</h3>
                {/* {FilterType ? (
                  <h3 className="m-0">
                    <i className="fa fa-minus" aria-hidden="true"></i>&nbsp;
                  </h3>
                ) : (
                  <h3 className="m-0">
                    <i className="fa fa-plus" aria-hidden="true"></i>&nbsp;
                  </h3>
                )} */}
              </span>
            </div>
            {parseInt(props.ShowSummary) === 1 ? (
              <div
                className={
                  !FilterType
                    ? "custom-footer active mb-1"
                    : "custom-footer mb-1"
                }
              >
                <span
                  className="footer-title cursor-pointer"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  onClick={() => setFilterType(!FilterType)}
                >
                  <h3 className="data-spends my-2 px-2">Summary</h3>
                  {/* {!FilterType ? (
                    <h3 className="m-0">
                      <i className="fa fa-minus" aria-hidden="true"></i>&nbsp;
                    </h3>
                  ) : (
                    <h3 className="m-0">
                      <i className="fa fa-plus" aria-hidden="true"></i>&nbsp;
                    </h3>
                  )} */}
                </span>
              </div>
            ) : (
              ""
            )}

            {FilterType ? (
              <div className="custom-body px-3 py-1">
                <div className="row">
                  <div className="col-sm-12 my-1 pb-2 d-flex justify-content-center">
                    <CustomSelect
                      className="form-select analytics-table-select"
                      value={props.SelectedTableData}
                      onChange={(e) =>
                        props.setSelectedTableData(e.target.value)
                      }
                      options={props.TableData.map((item) => ({
                        key: item.name, // Unique key for each option
                        value: item.name, // Value that matches what is selected
                        label: item.name, // Display text for each option
                      }))}
                      isDetailPopUp={false} // Assuming this prop is part of CustomSelect's API
                      barWidth={150}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="table-responsive p-0 analytics-table">
                      {JSON.stringify(props.SelectedTable) !== "{}" ? (
                        <table className="table table-bordered text-center">
                          <thead>
                            <tr>
                              {props.SelectedTable.headers.map(
                                (item, index) => {
                                  return (
                                    <th key={index} scope="col">
                                      {item}
                                    </th>
                                  );
                                }
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {props.SelectedTable.rawdata.map((item, index) => {
                              return (
                                <tr key={index}>
                                  {item.rodata.map((item1, index1) => {
                                    return (
                                      <td
                                        key={index + index1}
                                        style={{ color: item1.color }}
                                      >
                                        {item1.value}
                                      </td>
                                    );
                                  })}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="custom-body px-3 py-1">
                <div className="row">
                  <div className="col-sm-12">
                    <div
                      className="table-responsive p-0"
                      style={{ height: "500px" }}
                    >
                      <ul>
                        {props.Summary.map((item, index) => {
                          return (
                            <li key={index} className="spends-list-li">
                              {item}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SummaryAnalytics;
