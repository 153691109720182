import React from 'react';
import { Link } from 'react-router-dom';
// import './ErrorPage.css'; // Optional: You can create a CSS file for styling

const ErrorPage = () => {
    return (
        <div className="error-container" style={{ color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center',height:'30%',flexDirection:'column' }}>
            <h3 className="error-message" >Please close the DevTools to use this app, for more information contact admin.</h3>
            <Link to={`${window.location.origin}/login`}>Back To Home</Link>
        </div>
    );
};

export default ErrorPage;
