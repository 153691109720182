// Base URL for the API
// const BASE_URL = "http://stagingnew.skewb.ai:8001"; // Replace with your actual API URL
import axios from '../Axios'
// Function to fetch data from the API
// Function to fetch data from the API
export const fetchData = async (query, tabId) => {
  // Check if tabId exists before proceeding
  if (tabId) {
    const payload = {
      tab_id: tabId,
      question: query,
    };

    try {
      // Make the API call using axios
      const response = await axios.post('/gpt_cascader/', payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      // Handle the response and return the data
      console.log('fetchData: ', response.data.data);
      return response.data;
    } catch (error) {
      // Handle any errors during the API request
      console.error('Error fetching data:', error);
      return error.response ? error.response.data : error;
    }
  }
};