import { useEffect, useRef } from "react";
import Plotly from "plotly.js-dist";

const Plot = ({ data }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const chartNode = chartRef.current;

    const layout = {
      title: "",
      height: 450,
      width: 700,
      paper_bgcolor: "black", // Background color outside the plot
      plot_bgcolor: "black",
      font: { color: "white" },
      showlegend: false, // Turn off the legend
      xaxis: {
        title: {
          text: "",
        },
        type: "category",
      },
      yaxis: {
        title: {
          text: "",
        },
      },
      bargap: 0.1, // Controls the gap between bars (0 = no gap, 1 = max gap)
      bargroupgap: 0.05, // Controls the gap between groups of bars (multiple series)
    };

    const config = {
      displaylogo: false, // Hide the "Produced by Plotly" watermark
    };

    // Apply #d6ff41 color to columns (bars) via the marker property
    const updatedData = data.map((trace) => ({
      ...trace,
      marker: { color: "#d6ff41" }, // Set bar color
    }));

    Plotly.newPlot(chartNode, updatedData, layout, config);

    // Cleanup on unmount
    return () => {
      Plotly.purge(chartNode);
    };
  }, [data]);

  return <div ref={chartRef} />;
};

export default Plot;
