import React, { useRef, useEffect, useState } from "react";
import MultiLineColumnChart from "../Components/MultiLineColumnChart";
const TrendChart = (props) => {

  console.log("Trendcharts Props",props);
  const trendsRef = useRef(null);
  const [TrendsHeight, setTrendsHeight] = useState("");
  // console.log("TrendsHeight: ", TrendsHeight);
  
  useEffect(() => {
    if (trendsRef.current !== null) {
      setTrendsHeight(trendsRef.current.clientHeight-100);
    }
  }, [props.fetchMixChart2]);

  // console.log("props.fetchMixChart2.data: ", props.fetchMixChart2);

  return (
    <div
      className="row"
      style={
        {
          
        }
      }
    >
      <div className="col-sm-12" style={{height:'max-content',}}>
        {/* <p className="card-title-lable">&nbsp;</p> */}
        <div className="trends-chart-height" id="trends_chart" ref={trendsRef}>
          {props.fetchMixChart2?.data.length>0 && TrendsHeight &&  (
            // <MixChart
            //   height={TrendsHeight}
            //   TrendChartRef={props.TrendChartRef}
            //   ChartData={props.TrendChartdata}
            //   Downloadchart={props.Downloadchart}
            //   setDownloadchart={props.setDownloadchart}
            //   MixChart={props.mixChartData}
            // />
            <MultiLineColumnChart data={props.fetchMixChart2} TrendsHeight={TrendsHeight} />
          )}
        </div>
      </div>
    </div>
  );
};

export default TrendChart;