import React from "react";
 
const DeleteResultPopup = (props) => {
  return (
    <>
      <div
        className="modal fade bs-example-modal-center show"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="mySmallModalLabel"
        aria-hidden="true"
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            {/* <div className="modal-header">
                <h5 className="modal-title">Center modal</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>  */}
            <div className="modal-body modal-body-custom">
              <div className="text-center">
                <h2 className="modal-title-custom">
                  Are you sure you want to delete this saved result?
                </h2>
                <p className="m-0 modal-subtitle-custom">
                  This action cannot be undone
                </p>
              </div>
              <div className="row">
                <div className="col-6 text-center">
                  <button className=" btn btn-modal-primary" onClick={props.DeleteRecord}>Delete</button>
                </div>
                <div className="col-6 text-center">
                  <button
                    className=" btn btn-modal-secondary"
                    data-bs-dismiss="modal "
                    onClick={props.CanceledId}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
 
export default DeleteResultPopup;
 
 