


const configDriven = Object.freeze({
    show_charts_summary_optimizer: false,
    show_multi_chart: true,
    show_display_name: true,
  });
  
  export default configDriven;
  