import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import MeasurementSummary from "../Components/MeasurementSummary";
import { toast } from "react-toastify";
import StackChart from "../Components/StackChart";
import { Link } from "react-router-dom";
import Download from "../images/download-icon.png";
import { Tooltip } from "react-tooltip";
import InfoIcon from "../images/icons8-info.svg";
import downloadFlag from "../DownloadFlag";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import MultiCal from "../Components/MultiCal";
import configDriven from "../ConfirgDriven";

const Measurement = (props) => {
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(true);
  // const [refreshKey, setRefreshKey] = useState(0);
  // const [submittedStartDate, setSubmittedStartDate] = useState(
  //   props.DefaultStartDate
  // );
  // const [submittedEndDate, setSubmittedEndDate] = useState(
  //   props.DefaultEndDate
  // );
  // const [submittedTimeFrame, setSubmittedTimeFrame] = useState(props.timeframe);
  const [isRefreshEnabled, setIsRefreshEnabled] = useState(false);

  const refGraphDiv = useRef(null);
  const DivRef = useRef(null);
  const Analyse = JSON.parse(localStorage.getItem("Analyse"));
  // console.log("Analyse: ", Analyse);
  const [ChartHeight, setChartHeight] = useState("");
  const [DivHeight, setDivHeight] = useState("");
  const [Notes, setNotes] = useState("");

  useEffect(() => {
    if (
      (props.submittedStartDate && props.DefaultStartDate !== props.submittedStartDate) ||
      (props.submittedEndDate && props.DefaultEndDate !== props.submittedEndDate)
    ) {
      setIsRefreshEnabled(true);
    }

    if (
      props.DefaultStartDate === props.submittedStartDate &&
      props.DefaultEndDate === props.submittedEndDate
    ) {
      setIsRefreshEnabled(false);
    }
  }, [props.DefaultStartDate, props.DefaultEndDate]);

  useEffect(() => {
    if (refGraphDiv.current !== null) {
      setChartHeight(refGraphDiv.current.clientHeight);
    }
  }, [props]);

  useEffect(() => {
    if (DivRef.current !== null) {
      setDivHeight(DivRef.current.clientHeight);
    }
  }, [props]);

  useEffect(() => {
    const url = window.location.href;
    const text = url.split("/");
    const tab = text[text.length - 1];
    // console.log("tab: ", tab);
    for (var i = 0; i < Analyse.Menubar.length; i++) {
      if (Analyse.Menubar[i].key === tab) {
        setNotes(Analyse.Menubar[i].notes);
      }
    }
  }, [Analyse]);

  const fnDisabledSubmitBtn = () => {
    if (
      props.DefaultStartDate !== "NaN-NaN-NaN" &&
      props.DefaultEndDate !== "NaN-NaN-NaN"
    ) {
      return false;
    } else if (
      props.DefaultStartDate === "NaN-NaN-NaN" ||
      props.DefaultEndDate === "NaN-NaN-NaN"
    ) {
      return true;
    }
  };

  const displayNames = JSON.parse(localStorage.getItem("displayNames"));

  const returnDisplayName = (id) => {
    if (configDriven.show_display_name && displayNames?.hasOwnProperty(id)) {
      return displayNames[id];
    } else {
      return id;
    }
  };

  const [full, setFull] = useState(true);
  const [divWidth, setDivWidth] = useState("");

  const handler = () => {
    setFull(!full);
  };

  // console.log("divWidth: ", divWidth);

  useLayoutEffect(() => {
    const updateDivHeight = () => {
      if (DivRef.current !== null) {
        const newWidth = DivRef.current.clientWidth;
        setDivWidth(newWidth);
      }
    };

    updateDivHeight(); // Initial update
    window.addEventListener("resize", updateDivHeight);

    return () => {
      window.removeEventListener("resize", updateDivHeight);
    };
  }, []);

  return (
    <div className="container-fluid main-content-w pt-1">
      <div className="">
        <div
          className={
            parseInt(props.ShowSummary) !== 1
              ? "col-xl-12  cm-chart"
              : props.ZoomIn
              ? "col-xl-10  cm-chart"
              : "col-xl-8  cm-chart"
          }
        >
          <div
            className="card card-min-height"
            ref={DivRef}
            style={{
              height: `${
                window.innerHeight < 850
                  ? window.innerHeight * 0.785
                  : window.innerHeight * 0.84
              }px`,
            }}
          >
            <div
              className=""
              style={{
                paddingTop: "2px",
                paddingLeft: "10px",
                marginRight: "4px",
              }}
            >
              {/* <div className="row align-items-center justify-content-sm-between pb-1 ef-drivers">
                <div className="col-sm-4">
                </div>
                <div className="col-sm-4">
                  <p className="card-title-lable">
                    EFFECTIVENESS MEASUREMENT OVER TIME
                  </p>
                </div>

              </div> */}
              <div
                className="row align-items-center ce-filter justify-content-between"
                style={{ border: "none" }}
              >
                <div className="col-sm-12 col-md-12 col-lg-4 d-flex align-items-center justify-content-space-between mt-1">
                  {true && (
                    <div className="col-sm-12 col-md-10 col-lg-6 d-flex align-items-center justify-content-space-between gap-2">
                      {props.DefaultStartDate &&
                        props.DefaultEndDate &&
                        props.startdate &&
                        props.enddate && (
                          <MultiCal
                            // key={refreshKey}
                            setDefaultStartDate={props.setDefaultStartDate}
                            setDefaultEndDate={props.setDefaultEndDate}
                            DefaultStartDate={props.DefaultStartDate}
                            DefaultEndDate={props.DefaultEndDate}
                            rangeStartDate={props.startdate}
                            rangeEndDate={props.enddate}
                            setTimeframe={props.setTimeframe}
                            onChangeHandler={props.onChangeHandler}
                            TimeFramesAll={props.TimeFramesAll}
                            timeframe={props.timeframe}
                            SelectedChart={props.SelectedChart}
                            setIsSubmitEnabled={setIsSubmitEnabled}
                            // handleRefreshClick={handleRefreshClick}
                            submittedStartDate={props.submittedStartDate}
                            submittedEndDate={props.submittedEndDate}
                            submittedTimeFrame={props.submittedTimeFrame}
                            isRefreshEnabled={isRefreshEnabled}
                          />
                        )}
                      <div className="">
                        <button
                          className="submit-btn"
                          disabled={isSubmitEnabled}
                          // className="btn-class"
                          type="button"
                          style={{
                            // marginTop:'-40px !important',
                            fontSize: "10px",
                            width: "80px",
                            height: "20px",
                          }}
                          onClick={() => {
                            props.setSubmittedStartDate(props.DefaultStartDate);
                            props.setSubmittedEndDate(props.DefaultEndDate);
                            props.setSubmittedTimeFrame(props.timeframe);
                            setIsRefreshEnabled(false);
                            props.setFetchCall(true);
                          }}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-sm-12 col-md-12 col-lg-3 d-flex align-items-center justify-content-end ">
                  <div
                    className="d-flex align-items-center justify-content-sm-end"
                    style={{ float: "right" }}
                  >
                    {parseInt(props.ShowSummary) === 1 ? (
                      <i
                        style={{
                          float: "right",
                          color:
                            localStorage.getItem("CurrentTheme") === "Dark"
                              ? "#ffffff"
                              : "#1C2427",
                          cursor: "pointer",
                        }}
                        className={
                          !props.ZoomIn
                            ? "fa fa-window-maximize"
                            : "fa fa-window-minimize"
                        }
                        aria-hidden="true"
                        onClick={() => {
                          props.HandleZoom();
                        }}
                        title={props.ZoomIn ? "Minimize" : "Maximize"}
                      ></i>
                    ) : null}
                    {Notes !== "" ? (
                      <>
                        <p
                          className="m-0 px-2"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: "10px",
                          }}
                        >
                          <svg
                            src={InfoIcon}
                            id="notes"
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            class="bi bi-info-circle icon-class"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                          </svg>
                        </p>

                        <Tooltip anchorSelect="#notes" clickable>
                          {/* <div className="api-notes">
                                  {Notes}
                                </div> */}
                          <div
                            className="api-notes"
                            style={{
                              height: "max-content",
                              color: "white",
                            }}
                            dangerouslySetInnerHTML={{ __html: Notes }}
                          ></div>
                        </Tooltip>
                      </>
                    ) : null}
                    {(downloadFlag.chart ||
                      downloadFlag.data ||
                      downloadFlag.pdf) && (
                      <span className="flex-shrink-0 dropdown">
                        <Link
                          to="#"
                          className="d-flex align-items-center text-decoration-none dropdown-toggle text-white"
                          id="dropdownUser2"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <p
                            className="m-0 icon-class"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              fontSize: "10px",
                            }}
                          >
                            <svg
                              src={Download}
                              title="Download"
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="25"
                              class="bi bi-download"
                              viewBox="0 0 16 16"
                            >
                              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                              <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                            </svg>
                          </p>
                        </Link>
                        <ul
                          className="dropdown-menu text-small shadow"
                          aria-labelledby="dropdownUser2"
                        >
                          {downloadFlag.chart && (
                            <li onClick={(e) => {}}>
                              <Link
                                className="dropdown-item"
                                onClick={(e) => {
                                  e.preventDefault();

                                  if (
                                    props.categories.length > 0 &&
                                    props.series.length > 0
                                  ) {
                                    // props.setDownloadchart(true)
                                    // props.setLoader(true)
                                    props.setIsDownloadingChart(true);
                                    props.setChartContainerID("campaign_chart");
                                    props.setDownloadedImageName(
                                      "Campaign Effectiveness"
                                    );
                                    props.setDownloadingChartTitle(
                                      "Campaign Effectiveness"
                                    );
                                    props.setHierarchy(props.BreadCrumbs);
                                    props.setStartDate(props.DefaultStartDate);
                                    props.setEndDate(props.DefaultEndDate);
                                    props.setTimeFrameChart(props.timeframe);
                                  } else {
                                    toast.error("Chartdata is not available");
                                  }
                                }}
                              >
                                Download Chart
                              </Link>
                            </li>
                          )}

                          {downloadFlag.data && (
                            <li onClick={(e) => {}}>
                              <Link
                                className="dropdown-item"
                                onClick={(e) => props.downloadDataM(e)}
                              >
                                Download Data
                              </Link>
                            </li>
                          )}
                          {downloadFlag.pdf && (
                            <li onClick={(e) => {}}>
                              <Link
                                className="dropdown-item"
                                onClick={(e) => props.downloadPDFM(e)}
                              >
                                Download PDF
                              </Link>
                            </li>
                          )}

                          {/* <li onClick={(e) => { }}>
                          <Link className="dropdown-item">Download data</Link>
                        </li> */}
                        </ul>
                      </span>
                    )}
                  </div>
                </div>
                {/* <div className="col-sm-12 col-md-12 col-lg-4 d-flex align-items-center justify-content-center my-3">
                                    <button
                                        className="btn brand-btn-info"
                                        type="button"
                                        onClick={() => {
                                            props.ExpandAll()
                                        }}>
                                        Expand All
                                    </button>
                                </div> */}
              </div>
              <div>
                <div className="col-sm-12">
                  <div className="row align-items-center sorting-btn-ui">
                    <div className="col-sm-3">
                      <ul>
                        <li onClick={props.DrillUpAll} title="Drill Up">
                          <i
                            className="fa fa-arrow-up icon-class"
                            aria-hidden="true"
                          ></i>
                        </li>
                        <li onClick={props.DrillDownAll} title="Drill Down">
                          <i
                            className="fa fa-arrow-down icon-class"
                            aria-hidden="true"
                          ></i>
                        </li>
                        <li onClick={props.DrillAll} title="Expand All">
                          <i
                            className="fa fa-sitemap icon-class"
                            aria-hidden="true"
                          ></i>
                        </li>
                        <li
                          onClick={() => {
                            props.ResetAll();
                            setFull(false);
                          }}
                          title="Reset"
                        >
                          <i className="fa icon-class" aria-hidden="true">
                            &#8635;
                          </i>
                        </li>
                        <li>
                          {full ? (
                            <i
                              className="fas fa-magnifying-glass-minus icon-class"
                              onClick={handler}
                              style={{
                                color: "white",
                                cursor: "pointer",
                              }}
                            ></i>
                          ) : (
                            <i
                              className="fas fa-magnifying-glass-plus icon-class"
                              onClick={handler}
                              style={{
                                color: "white",
                                cursor: "pointer",
                              }}
                            ></i>
                          )}
                        </li>
                      </ul>
                    </div>

                    {/* <div className="col-sm-9 custom-brdcrum">
                      {props.BreadCrumbs.map((item) => {
                        return displayNames[item];
                      }).join(" / ")}
                    </div> */}
                  </div>
                </div>
                <div
                  className="col-sm-12"
                  style={{ display: "flex", alignContent: "center" }}
                >
                 <div
                    className="col-sm-6 custom-brdcrum2"
                    style={{
                      display: "flex",
                      paddingLeft: "2px",
                      alignContent: "center",
                      justifyContent: "start",
                      wordBreak: "break-word",
                      flexWrap: "wrap",
                      minHeight: "min-content",
                    }}
                  >
                    {props.hierarchySelectionBreadCrumb ||
                    props.channelSelectionBreadCrumb ? (
                      <>
                        <i
                          className="fas fa-filter"
                          style={{ marginRight: "5px", fontSize: "12px" }} // Adds spacing and adjusts icon size
                        ></i>
                        {props.hierarchySelectionBreadCrumb &&
                        props.channelSelectionBreadCrumb
                          ? `${props.hierarchySelectionBreadCrumb} / ${props.channelSelectionBreadCrumb}`
                          : props.hierarchySelectionBreadCrumb ||
                            props.channelSelectionBreadCrumb}
                      </>
                    ) : null}
                  </div>
 
                  <div className="col-sm-6 custom-brdcrum">
                    {props.BreadCrumbs.map((item) => {
                      return returnDisplayName(item);
                    }).join(" / ")}
                  </div>
                </div>
                <div className="col-sm-12">
                  <div
                    className=""
                    style={{ height: "100%" }}
                    ref={refGraphDiv}
                  >
                    {props.categories.length > 0 && props.series.length > 0 ? (
                      <StackChart
                        Timeframe={props.timeframe}
                        StartDate={props.DefaultStartDate}
                        EndDate={props.DefaultEndDate}
                        ActiveFilters={props.ActiveFilters}
                        Fixed={props.Fixed}
                        categories={props.categories}
                        series={props.series}
                        // series={DATA.data.series}
                        xtitle={props.xtitle}
                        ytitle={props.ytitle}
                        charttitle={props.charttitle}
                        DrillDown={props.DrillDown}
                        DrillUp={props.DrillUp}
                        height={ChartHeight}
                        ZoomIn={props.ZoomIn}
                        NotAllow={props.NotAllow}
                        setDownloadchart={props.setDownloadchart}
                        Downloadchart={props.Downloadchart}
                        setLoader={props.setLoader}
                        BreadCrumbs={props.BreadCrumbs}
                        timeframe={props.timeframe}
                        full={full}
                        divWidth={divWidth - 40}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <MeasurementSummary
          Summary={props.Summary}
          ZoomIn={props.ZoomIn}
          ShowSummary={props.ShowSummary}
          DivHeight={DivHeight}
        />
      </div>
      <div className="row custom-note">
        <div className="col-sm-1">
          {props.FooterNotes !== "" ? (
            <>
              <i
                className="fas fa-mail-bulk chart_info_icon footer-notes-icon icon-class"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "15px",
                  marginLeft: "6px",
                  marginBottom: "10px",
                }}
                id="clickable"
              ></i>
              <Tooltip anchorSelect="#clickable" clickable>
                {/* <div className="footer-notes">{FooterNotes}</div> */}
                <div
                  className="footer-notes"
                  style={{ height: 'max-content' }}
                  dangerouslySetInnerHTML={{ __html: props.FooterNotes }}
                ></div>
              </Tooltip>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Measurement;
