import React, { useEffect, useState, useRef } from "react";
import Topbar from "../Components/Topbar";
import Menubar from "../Components/Menubar";
import axios from "../Axios";
import Loader from "../Components/Loader";
import SummaryAnalytics from "../Components/SummaryAnalytics";
import { useLocation } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MixChart from "../Components/MixChart";
import AllHeirarchyFilter from "../Components/HeirarchyFilter";
import ActiveHeirarchyAndCampaignFilter from "../Components/ActiveHieAndCamFil";
import { Tooltip } from "react-tooltip";
import InfoIcon from "../images/icons8-info.svg";
import Download from "../images/download-icon.png";
import { Link } from "react-router-dom";

import LightThemeHexCode from "../Components/LightThemeColorCode";
import useDownloadChart from "../Components/DownloadCharts";

import axiosDownload from "../Axios"; // download url will be change
import axiosChartDownload from "../Axios"; // download url will be change
import domtoimage from "dom-to-image";
import downloadFlag from "../DownloadFlag";
import CustomSelect from "../Components/CustomSelect";

const Main = () => {
  const location = useLocation();
  const refGraphDiv = useRef(null);
  const divHeight = useRef(null);
  const ChartRef = useRef(null);

  const Analyse = JSON.parse(localStorage.getItem("Analyse"));
  const AnalyticFilter = JSON.parse(localStorage.getItem("AnalyticFilter"));
  const [AnalyticFilterFlag, setAnalyticFilterFlag] = useState(true);
  const [loader, setLoader] = useState(false);
  const [Notes, setNotes] = useState("");
  const [FooterNotes, setFooterNotes] = useState("");
  const [TimeFramesAll, setTimeFramesAll] = useState([]);
  const [timeframe, setTimeframe] = useState("");
  const [TableData, setTableData] = useState([]);
  const [SelectedTableData, setSelectedTableData] = useState("");
  const [SelectedTable, setSelectedTable] = useState({});
  const [Summary, setSummary] = useState([]);
  const [ChartData, setChartData] = useState({});
  const [DivHeight, setDivHeight] = useState({});
  // const [Downloadchart, setDownloadchart] = useState(false);

  //Analytical filters state
  const [AllFilters, setAllFilters] = useState([]);

  const [ActiveFilters, setActiveFilters] = useState([]);

  const Fixed = localStorage.getItem("AnalyticFilter")
    ? AnalyticFilter.Fixed
    : [];

  const [HeirarchyChartdata, setHeirarchyChartdata] = useState({});
  // console.log("HeirarchyChartdata: ", HeirarchyChartdata);

  const [FetchCall, setFetchCall] = useState(false);
  const [FilterTabShow, setFilterTabShow] = useState(false);
  const [ShowSummary, setShowSummary] = useState(0);
  const [ResetFilterFlag, setResetFilterFlag] = useState(false);

  const [isDownloadingChart, setIsDownloadingChart] = useState(false);
  const [chartContainerID, setChartContainerID] = useState("");
  const [downloadedImageName, setDownloadedImageName] = useState("");
  const [downloadingChartTitle, setDownloadingChartTitle] = useState("");

  const userName = localStorage.getItem("NameSkewb");

  useDownloadChart(
    downloadedImageName,
    downloadingChartTitle,
    isDownloadingChart,
    chartContainerID,
    Fixed,
    ActiveFilters,
    setLoader,
    Analyse.Brand,
    timeframe,
    "",
    "",
    setIsDownloadingChart,
    Analyse.KPI
  );

  useEffect(() => {
    const url = window.location.href;
    const text = url.split("/");
    const tab = text[text.length - 1];
    for (var i = 0; i < Analyse.Menubar.length; i++) {
      if (Analyse.Menubar[i].key === tab) {
        setNotes(Analyse.Menubar[i].notes);
      }
    }
  }, [Analyse]);


  useEffect(() => {
    if (AnalyticFilterFlag && AnalyticFilter) {
      setAllFilters(AnalyticFilter.AllFilters);
      setActiveFilters(AnalyticFilter.ActiveFilters);
      setAnalyticFilterFlag(false);
    }
  }, [AnalyticFilter, AnalyticFilterFlag]);

  useEffect(() => {
    if (true) {
      setLoader(true);
      axios
        .post("get_timeframes/", {
          page: "validations",
          brand: Analyse.Brand,
          kpi: Analyse.KPI,
        })
        .then((response) => {
          if (response.data.error === 1) {
            toast.error(response.data.erroMsg);
            setLoader(false);
          } else {
            setTimeFramesAll(response.data.data);
            setTimeframe(response.data.data[0].key);
            if ("notes" in response.data) {
              setFooterNotes(response.data.notes);
            }
            setLoader(false);
            setFetchCall(true);
          }
        })
        .catch((data) => {
          setLoader(false);
        });
    }
  }, [Analyse.Brand, Analyse.KPI]);

  useEffect(() => {
    if (
      timeframe !== "" &&
      JSON.stringify(HeirarchyChartdata) !== "{}" &&
      FetchCall
    ) {
      setLoader(true);
      axios
        .post("get_validations/", {
          brand: Analyse.Brand,
          kpi: Analyse.KPI,
          timeframe: timeframe,
          chartdata: HeirarchyChartdata,
          theme: localStorage.getItem("CurrentTheme"),
          LightThemeColorCode: [
            localStorage.getItem("ColorCode"),
            LightThemeHexCode[localStorage.getItem("ColorCode")],
          ],
        })
        .then((response) => {
          if (response.data.error === 1) {
            toast.error(response.data.erroMsg);
            setLoader(false);
          } else {
            setTableData(
              response.data.data.tabledata ? response.data.data.tabledata : []
            );
            setSelectedTableData(response.data.data.tabledata[0].name);
            setSummary(response.data.data.summary);
            setChartData(response.data.data.chartdata);
            if ("notes" in response.data) {
              setFooterNotes(response.data.notes);
            }
            if (response.data.data.show_summary !== undefined) {
              setShowSummary(response.data.data.show_summary);
            }
            setLoader(false);
            setFetchCall(false);
          }
        })
        .catch((data) => {
          setLoader(false);
        });
    }
  }, [Analyse.Brand, Analyse.KPI, timeframe, HeirarchyChartdata, FetchCall]);

  useEffect(() => {
    if (SelectedTableData !== "") {
      for (var i = 0; i < TableData.length; i++) {
        if (SelectedTableData === TableData[i].name) {
          setSelectedTable(TableData[i]);
          break;
        }
      }
    }
  }, [SelectedTableData, TableData]);

  const [ChartHeight, setChartHeight] = useState("");

  useEffect(() => {
    if (refGraphDiv.current !== null) {
      setChartHeight(refGraphDiv.current.clientHeight);
    }
  }, [FetchCall]);

  useEffect(() => {
    if (divHeight.current !== null) {
      setDivHeight(divHeight.current.clientHeight);
    }
  }, [FetchCall]);

  // When Activefilter change : prepare chartdata for input of get api call
  useEffect(() => {
    const chartdata = {};
    if (Fixed.length !== 0) {
      for (var r = 0; r < Fixed.length; r++) {
        const Filter = [];
        Filter.push(Fixed[r].value);
        chartdata[Fixed[r].id] = {
          selection: [],
          Filter: Filter,
        };
      }
    }

    if (ActiveFilters.length !== 0) {
      for (var i = 0; i < ActiveFilters.length; i++) {
        const selection = [];
        if ("Selected" in ActiveFilters[i]) {
          if (ActiveFilters[i]["Selected"].length > 0) {
            setResetFilterFlag(true);
          }

          for (var j = 0; j < ActiveFilters[i].Selected.length; j++) {
            var temp_arr = ActiveFilters[i].Selected[j].value.split("-");
            selection.push(temp_arr[temp_arr.length - 1]);
          }
        }

        chartdata[ActiveFilters[i].name] = {
          selection:
            typeof HeirarchyChartdata[ActiveFilters[i].name] !== "undefined"
              ? HeirarchyChartdata[ActiveFilters[i].name].selection
              : [],
          // Filter: selection,
          Filter:
            typeof HeirarchyChartdata[ActiveFilters[i].name] !== "undefined"
              ? HeirarchyChartdata[ActiveFilters[i].name].Filter
              : [],
        };
      }
    }

    setHeirarchyChartdata(chartdata);
    setFetchCall(true);
  }, [ActiveFilters]);

  //HEIRARCHY FILTERS
  const GetOption = (current_level, selection, destination_index) => {
    setLoader(true);
    axios
      .post("get_hirarchy_dropdown_options/", {
        hirarchy: Analyse.Brand,
        current_level: current_level,
        old_selection: selection !== "all" ? selection : "",
      })
      .then((response) => {
        const ActiveFiltersTemp = JSON.parse(JSON.stringify(ActiveFilters));
        var sort_arr = [...AllFilters];
        for (var i = 0; i < sort_arr.length; i++) {
          if (parseInt(sort_arr[i].level) === current_level) {
            var obj = sort_arr[i];
            obj["filters"] = response.data.data.options;
            obj["Selected"] = [];

            const temp_index = sort_arr.indexOf(sort_arr[i]);
            if (temp_index > -1) {
              // only splice array when item is found
              sort_arr.splice(temp_index, 1); // 2nd parameter means remove one item only
            }

            ActiveFiltersTemp.splice(destination_index, 0, obj);
          }
        }
        let data = [...ActiveFiltersTemp];
        for (let k = 0; k < ActiveFiltersTemp.length; k++) {
          if (destination_index < k) {
            data[k]["filters"] = [];
            data[k]["Selected"] = [];
          }
        }
        setActiveFilters(data);

        //Set analytical filters data in localstorage
        const AnalyticFilterTemp = {
          AllFilters: AllFilters,
          Fixed: Fixed,
          ActiveFilters: data,
        };

        localStorage.setItem(
          "AnalyticFilter",
          JSON.stringify(AnalyticFilterTemp)
        );

        setLoader(false);
      })
      .catch((data) => {
        setLoader(false);
      });
  };

  const AddHeirarchyFilter = (index) => {
    const ActiveFiltersCompTemp = JSON.parse(JSON.stringify(ActiveFilters));
    let selection_value = [];
    var i = ActiveFiltersCompTemp.length - 1;
    while (i >= 0) {
      if (ActiveFilters[i]["Selected"].length !== 0) {
        for (var j = 0; j < ActiveFilters[i]["Selected"].length; j++) {
          selection_value.push(
            ActiveFilters[i]["Selected"][j].value === "all"
              ? ""
              : ActiveFilters[i]["Selected"][j].value
          );
        }
      }
      i--;
    }

    const selection = selection_value.toString().replaceAll(",", ";");
    GetOption(
      parseInt(AllFilters[index].level),
      selection,
      ActiveFiltersCompTemp.length
    );

    let filter = AllFilters[index];
    filter.Selected = [];

    // setActiveFilters(ActiveFiltersCompTemp);
    setFilterTabShow(true);
  };

  const onChangeFilter = (selected, index, data) => {
    const ActiveFiltersTemp = data
      ? data
      : JSON.parse(JSON.stringify(ActiveFilters));

    ActiveFiltersTemp[index].Selected = selected;

    let next_level_arr = [];
    for (var j = 0; j < ActiveFiltersTemp.length; j++) {
      if (j > index) {
        next_level_arr.push(ActiveFiltersTemp[j].level);
      }
    }

    const next_level = next_level_arr.toString().replaceAll(",", ";");

    setLoader(true);

    var selection_value = [];
    for (var i = 0; i < selected.length; i++) {
      selection_value.push(selected[i].value);
    }

    const selection = selection_value.toString().replaceAll(",", ";");
    axios
      .post("hirarchy_dropdown_child_options/", {
        hirarchy: Analyse.Brand,
        selection: selection !== "all" ? selection : "",
        next_level: next_level,
      })

      .then((response) => {
        var data = [...ActiveFiltersTemp];
        for (var i = 0; i < ActiveFiltersTemp.length; i++) {
          if (response.data.data[data[i].level]) {
            data[i]["Selected"] = [];
            data[i]["filters"] = response.data.data[data[i].level];
          }
        }
        setActiveFilters(data);

        //Set analytical filters data in localstorage
        const AnalyticFilterTemp = {
          AllFilters: AllFilters,
          Fixed: Fixed,
          ActiveFilters: data,
        };

        localStorage.setItem(
          "AnalyticFilter",
          JSON.stringify(AnalyticFilterTemp)
        );

        setLoader(false);
      })
      .catch((data) => {
        setLoader(false);
      });
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    if (
      result.destination.droppableId === "active" &&
      result.source.droppableId === "inactive"
    ) {
      let selection_value = [];

      var i = result.destination.index - 1;
      while (i >= 0) {
        if (ActiveFilters[i]["Selected"].length !== 0) {
          for (var j = 0; j < ActiveFilters[i]["Selected"].length; j++) {
            selection_value.push(
              ActiveFilters[i]["Selected"][j].value === "all"
                ? ""
                : ActiveFilters[i]["Selected"][j].value
            );
          }
        }
        i--;
      }
      const selection = selection_value.toString().replaceAll(",", ";");
      GetOption(
        parseInt(result.draggableId),
        selection,
        result.destination.index
      );
    }

    if (
      result.destination.droppableId === "inactive" &&
      result.source.droppableId === "active"
    ) {
      const AllFiltersTemp = JSON.parse(JSON.stringify(AllFilters));
      AllFiltersTemp.splice(
        result.destination.index,
        0,
        ActiveFilters[result.source.index]
      );
      setAllFilters(AllFiltersTemp);

      const ActiveFiltersTemp = JSON.parse(JSON.stringify(ActiveFilters));
      ActiveFiltersTemp.splice(result.source.index, 1);
      setActiveFilters(ActiveFiltersTemp);

    }

    if (
      result.destination.droppableId === "active" &&
      result.source.droppableId === "active"
    ) {
      const ActiveFiltersTemp = JSON.parse(JSON.stringify(ActiveFilters));

      ActiveFiltersTemp.splice(result.source.index, 1);
      ActiveFiltersTemp.splice(
        result.destination.index,
        0,
        ActiveFilters[result.source.index]
      );

      onChangeFilter(
        ActiveFiltersTemp[result.destination.index].Selected,
        result.destination.index,
        ActiveFiltersTemp
      );
    }

    if (
      result.destination.droppableId === "inactive" &&
      result.source.droppableId === "inactive"
    ) {
      const AllFiltersTemp = JSON.parse(JSON.stringify(AllFilters));
      AllFiltersTemp.splice(result.source.index, 1);
      AllFiltersTemp.splice(
        result.destination.index,
        0,
        AllFilters[result.source.index]
      );
      setAllFilters(AllFiltersTemp);
    }
  };

  const RemoveHeirarchyFilter = (item) => {
    const ActiveFiltersCompTemp = JSON.parse(JSON.stringify(ActiveFilters));
    for (let i = 0; i < ActiveFiltersCompTemp.length; i++) {
      if (ActiveFiltersCompTemp[i].name === item.name) {
        ActiveFiltersCompTemp.splice(i, 1);
        setActiveFilters(ActiveFiltersCompTemp);
      }
    }
    setFilterTabShow(true);

    //Set analytical filters data in localstorage
    const AnalyticFilterTemp = {
      AllFilters: AllFilters,
      Fixed: Fixed,
      ActiveFilters: ActiveFiltersCompTemp,
    };

    localStorage.setItem("AnalyticFilter", JSON.stringify(AnalyticFilterTemp));
  };

  const IsInAllHeirarchyFilters = (item) => {
    for (let i = 0; i < ActiveFilters.length; i++) {
      if (ActiveFilters[i].name === item.name) {
        return true;
      }
    }
    return false;
  };

  const ResetFilters = () => {
    var active_filter = [];
    for (let i = 0; i < ActiveFilters.length; i++) {
      var obj = ActiveFilters[i];
      obj["Selected"] = [];
      active_filter.push(obj);
    }
    setResetFilterFlag(false);

    //Set analytical filters data in localstorage
    const AnalyticFilterTemp = {
      AllFilters: AllFilters,
      Fixed: Fixed,
      ActiveFilters: active_filter,
    };

    localStorage.setItem("AnalyticFilter", JSON.stringify(AnalyticFilterTemp));
    setActiveFilters(active_filter);
  };

  const DownloadData = (e) => {
    e.preventDefault();
    setLoader(true);
    axiosDownload
      .post("validation/", {
        brand: Analyse.Brand,
        kpi: Analyse.KPI,
        timeframe: timeframe,
        chartdata: HeirarchyChartdata,
        theme: localStorage.getItem("CurrentTheme"),
      })
      .then((response) => {
        var link = document.createElement("a");
        link.target = "_blank";
        link.href = response.data.data;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoader(false);
      })
      .catch((data) => {
        setLoader(false);
      });
  };

  const downloadPDF = async (e) => {
    e.preventDefault();
    setLoader(true);
    const chartContainers = [document.getElementById("validation_chart")];
    if (chartContainers.every((container) => container !== null)) {
      try {
        // setLoader(true);
        const dataUrls = await Promise.all(
          chartContainers.map((container) => domtoimage.toPng(container))
        );
        // setLoader(true);
        const response = await axiosDownload.post("validation/", {
          brand: Analyse.Brand,
          kpi: Analyse.KPI,
          timeframe: timeframe,
          chartdata: HeirarchyChartdata,
          theme: localStorage.getItem("CurrentTheme"),
          download_type: "PDF",
        });
        // setLoader(false);
        // console.log("excel: ", response.data);
        const pdfResponse = await axiosChartDownload.post(
          "download_pdf/create/",
          {
            images: dataUrls.map((dataUrl) => dataUrl.split(",")[1]),
            document: {
              table1_data: response.data.table1_data,
              table2_data: response.data.table2_data,
              titlename: "Validations",
              footer_content: "Private & Confidential",
              table_heading1: "Validations",
              image_heading1: "Validations",
              table_heading2: "Model Parameter",
              username: userName,
              timeframe: timeframe,
              start_date: "",
              end_date: "",
              category: "",
              chartdata: HeirarchyChartdata,
              brand: Analyse.Brand,
              kpi: Analyse.KPI,
              tabname: "validations",
            },
          }
        );
        if (pdfResponse.data.error === 1) {
          toast.error(pdfResponse.data.erroMsg);
          setLoader(false);
        } else {
          // console.log("response pdf: ", pdfResponse.data);
          setLoader(false);
          var link = document.createElement("a");
          link.target = "_blank";
          link.href = pdfResponse.data.pdf_url;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (error) {
        setLoader(false);
        console.error("Error while generating pdf:", error);
      }
    }
  };

  const [HFilters, setHFilters] = useState([]);
  // console.log("HFilters: ", HFilters);
  // console.log("Chartdata: ", Object.keys(HeirarchyChartdata));

  const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
  // console.log("selectedCheckboxes: ", selectedCheckboxes);

  const filteredKeys = Object.keys(HeirarchyChartdata).filter(
    (key) => !key.startsWith("C")
  );

  useEffect(() => {
    const fetchHierarchyFilters = async () => {
      if (JSON.stringify(HeirarchyChartdata) !== "{}") {
        setHFilters([]);
        try {
          const response = await axios.post("hieracrhy_data/", {
            hierarchy_list: filteredKeys,
            brand: Analyse.Brand,
          });

          const data = response.data;
          // console.log(data);
          setHFilters(data.data);
          // localStorage.setItem("hierarchyFilters", JSON.stringify(data.data));
        } catch (error) {
          console.error("Failed to fetch:", error);
        }
      }
    };
    fetchHierarchyFilters();
  }, [HeirarchyChartdata, Analyse.Brand]);

  return (
    <>
      {loader ? <Loader /> : null}

      <ToastContainer theme="colored" />
      <Topbar />
      <Menubar />
      <div className="main-content">
        <div className="page-content" >
       
          
            
              {/* change 2 class add */}
              <div className="filter-class" style={{ alignItems: "center", width:"100vw" }}>
                {/* <AllHeirarchyFilter
                  key={HeirarchyChartdata}
                  OnlyHeirarchyFilter={true}
                  Fixed={Fixed}
                  ActiveFilters={ActiveFilters}
                  AllFilters={AllFilters}
                  AddHeirarchyFilter={AddHeirarchyFilter}
                  RemoveHeirarchyFilter={RemoveHeirarchyFilter}
                  IsInAllHeirarchyFilters={IsInAllHeirarchyFilters}
                  Chartdata={HeirarchyChartdata}
                  setChartdata={setHeirarchyChartdata}
                  setFetchCall={setFetchCall}
                  HFilters={HFilters}
                  setActiveFilters={setActiveFilters}
                  selectedCheckboxes={selectedCheckboxes}
                  setSelectedCheckboxes={setSelectedCheckboxes}
                /> */}
                {false && (
                  <div className="col-xl-2 pr-0">
                    <div className="card my-1">
                      {/* change 1 class + css */}
                      <div
                        className="card-body cursor-pointer d-flex align-items-center justify-content-center custom-selected-filter"
                        onClick={() => {
                          setFilterTabShow(true);
                        }}
                      >
                        <h6 className="m-0 text-center primary-text">
                          <i className="fa fa-filter" aria-hidden="true"></i>{" "}
                          Selected Filter
                        </h6>
                        {ResetFilterFlag ? (
                          <h6 className="m-0 text-center primary-text cursor-pointer custom-reset">
                            <p
                              className="mx-2 m-0"
                              title="Reset Filter"
                              onClick={() => {
                                ResetFilters();
                              }}
                            >
                              {" "}
                              &#8635;{" "}
                            </p>
                          </h6>
                        ) : null}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            
          

          <div className="container-fluid main-content-w pt-1"
          style={{marginTop:'35px'}}
          >
            <div className="row">
              {false ? (
                <ActiveHeirarchyAndCampaignFilter
                  setFetchCall={setFetchCall}
                  OnlyHeirarchyFilter={true}
                  onChangeFilter={onChangeFilter}
                  onDragEnd={onDragEnd}
                  ActiveFilters={ActiveFilters}
                  setFilterTabShow={setFilterTabShow}
                  Fixed={Fixed}
                />
              ) : null}

              <div
                className={
                  parseInt(ShowSummary) === 1 || TableData.length > 0
                    ? "col-xl-8 cm-chart"
                    : "col-xl-12 cm-chart"
                }
              >
                <div className="card card-min-height" ref={divHeight}
                 style={{ height: 'max-content',padding:'10px' }}>
                  <div className="card-body brand-card"
                  style={{ background: 'transparent', height: `${window.innerHeight < 850 ? window.innerHeight * 0.83 : window.innerHeight * 0.89}px` }}>
                    <div className="row align-items-center ce-filter mt-1">
                      <div className="col-sm-12 col-md-12 col-lg-2 d-flex ">
                        <CustomSelect
                          className="form-select custom-input-box"
                          value={timeframe}
                          onChange={(e) => {
                            setTimeframe(e.target.value);
                            setFetchCall(true);
                          }}
                          options={TimeFramesAll.map((item) => ({
                            key: item.key,
                            value: item.key, // Ensure the value matches the key for proper selection
                            label: item.value, // Use label for displaying text
                          }))}
                          style={{ width: "115px" }}
                          isDetailPopUp={false} // Assuming this is a relevant prop for your CustomSelect
                        />
                      </div>
                      <div
                        className="col-sm-12 col-md-12 col-lg-9 d-flex justify-content-center my-1"
                        style={{ float: "right" }}
                      ></div>
                      <div
                        className="col-sm-12 col-md-12 col-lg-1 d-flex justify-content-center my-1"
                        style={{ float: "right" }}
                      >
                        {Notes !== "" ? (
                          <>
                            <p
                              className="icon-class"
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              <svg
                                src={InfoIcon}
                                id="notes"
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="25"
                                class="bi bi-info-circle"
                                viewBox="0 0 16 16"
                              >
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                              </svg>
                            </p>
                            {(downloadFlag.chart ||
                              downloadFlag.data ||
                              downloadFlag.pdf) && (
                              <span className="flex-shrink-0 dropdown">
                                <Link
                                  to="#"
                                  className="d-flex align-items-center text-decoration-none dropdown-toggle text-white"
                                  id="dropdownUser2"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <p
                                    className="icon-class"
                                    style={{
                                      color: "#ffffff",
                                    }}
                                  >
                                    <svg
                                      src={Download}
                                      title="Download"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="25"
                                      height="25"
                                      className="bi bi-download"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                      <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                    </svg>
                                  </p>
                                </Link>
                                <ul
                                  className="dropdown-menu text-small shadow"
                                  aria-labelledby="dropdownUser2"
                                >
                                  {downloadFlag.chart && (
                                    <li onClick={(e) => {}}>
                                      <Link
                                        className="dropdown-item"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          // setDownloadchart(true)
                                          setChartContainerID(
                                            "validation_chart"
                                          );
                                          setIsDownloadingChart(true);
                                          setDownloadingChartTitle(
                                            "Validation"
                                          );
                                          setDownloadedImageName("Validation");
                                        }}
                                      >
                                        Download Chart
                                      </Link>
                                    </li>
                                  )}

                                  {downloadFlag.data && (
                                    <li onClick={(e) => {}}>
                                      <Link
                                        className="dropdown-item"
                                        onClick={(e) => DownloadData(e)}
                                      >
                                        Download Data
                                      </Link>
                                    </li>
                                  )}
                                  {downloadFlag.pdf && (
                                    <li onClick={(e) => {}}>
                                      <Link
                                        className="dropdown-item"
                                        onClick={(e) => downloadPDF(e)}
                                      >
                                        Download PDF
                                      </Link>
                                    </li>
                                  )}

                                  {/* <li onClick={(e) => { }}>
                          <Link className="dropdown-item">Download data</Link>
                        </li> */}
                                </ul>
                              </span>
                            )}

                            <Tooltip anchorSelect="#notes" clickable>
                              {/* <div className="api-notes">
                                  {Notes}
                                </div> */}
                              <div
                                className="api-notes"
                                dangerouslySetInnerHTML={{ __html: Notes }}
                              ></div>
                            </Tooltip>
                          </>
                        ) : null}
                      </div>
                    </div>
                    <div className="row my-1">
                      <div className="col-sm-12">
                        <div
                          className="my-1 validation-chart-height"
                          ref={refGraphDiv}
                          id="validation_chart"
                        >
                          {JSON.stringify(ChartData) !== "{}" ? (
                            <MixChart
                              ChartRef={ChartRef}
                              ChartData={ChartData}
                              height={ChartHeight}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {parseInt(ShowSummary) === 1 || TableData.length > 0 ? (
                <SummaryAnalytics
                  ShowSummary={ShowSummary}
                  height={DivHeight}
                  Summary={Summary}
                  TableData={TableData}
                  setSelectedTableData={setSelectedTableData}
                  SelectedTableData={SelectedTableData}
                  SelectedTable={SelectedTable}
                />
              ) : null}
            </div>
          </div>
          <div className="row custom-note">
            <div className="col-sm-1">
              {FooterNotes !== "" ? (
                <>
                <i
                  className="fas fa-mail-bulk chart_info_icon footer-notes-icon icon-class"
                  style={{ display: "flex", alignItems: "center", justifyContent: "center", fontSize: '15px', marginLeft: '6px', marginBottom: '10px' }}
                  id="clickable"
                ></i>
                <Tooltip anchorSelect="#clickable" clickable>
                  {/* <div className="footer-notes">{FooterNotes}</div> */}
                  <div
                    className="footer-notes"
                    style={{ height: 'max-content' }}
                    dangerouslySetInnerHTML={{ __html: FooterNotes }}
                  ></div>
                </Tooltip>
              </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      
      
      
    </>
  );
};

export default Main;
