// router
import { BrowserRouter, Routes, Route, useNavigate, useLocation } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import axios from "./Axios";
import DisableDevtool from 'disable-devtool';


// toast
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";


// mui components
import { CssBaseline, Typography } from "@mui/material";

// pagesgit 

import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgetPassword";
import PortfolioLandscape from "./pages/PortfolioLandscape";
import GPT from "./pages/GPT/App"
import DetailsPopUp from "./Components/DetailsPopUp"
import AppState from "./context/appState";
import HeirarchyFilter from './Components/HeirarchyFilter'
import Eda from './pages/Eda'
import Roi from './pages/Roi'
import Synergy from './pages/Synergy'
import GrowthDriver from './pages/GrowthDriver'
import CampaignEffectiveness from './pages/CampaignEffectiveness'
import SkewbSimulator from "./SkewbSimulator/Main";
import SavedResults from './pages/SavedResults'
import Validation from './pages/Validation'
import GrowthCurves from "./pages/GrowthCurves"
import DeleteResult from "./Components/DeleteResult"
import ErrorPage from './Components/ErrorPage'
import Topbar from "./Components/Topbar";
import Menubar from "./Components/Menubar";



import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";



let theme = createTheme({
  palette: {
    background: {
      default: "#1c2427",
    },
    themeColor: "#d6ff41", //yellow sa
    bgColor: "#1c2427",  // black sa
    borderColor: "#3E5056",// thoda alg sa 
  },

  typography: {
    fontFamily: ["Nunito", "sans-serif"].join(","),
    h1: {
      fontSize: 16,
    },

    h2: {
      fontSize: 15,
    },
    h3: {
      fontSize: 14,
    },
    h4: {
      fontSize: 13,
    },
    h5: {
      fontSize: 12,
    },
    h6: {
      fontSize: 11,
    },
    small: {
      fontSize: 10,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: "26px", // Set default size for all icons
        },
      },
    },
  },
});

theme = responsiveFontSizes(theme);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.data?.error) {
      // console.log(error.response.data.error);

      localStorage.clear();
      window.location.href = "/login";
      toast("Your session is expired. Please login Again")
    }
    return Promise.reject(error);
  }
);

const AppRoutes = () => {
  const location = useLocation();
  const [redirected, setRedirected] = useState(false); // Flag to track if the redirect has already occurred

  const config = {
    // url: `${window.location.origin}/not-found`, // Full URL with protocol, hostname, and port
    ondevtoolopen: (type, next) => {
      if (!redirected) {
        // Redirect only if it hasn't already occurred
        console.log(`DevTools detected in mode: ${type}`);
        // localStorage.clear(); // Clear localStorage when DevTools is opened
        const notFoundUrl = `${window.location.origin}/not-found`;
        if (window.location.href !== notFoundUrl) {
          window.location.href = notFoundUrl; // Redirect to the not-found page if it's not already there
        }
        setRedirected(true); // Set flag to true to prevent further redirects
      }
    },
    disableMenu: true,  // Disables the right-click context menu
  };

  useEffect(() => {
    DisableDevtool(config);
  }, []); // Empty dependency array ensures it runs only once

  return (
    <>
{/* <Topbar/>
<Menubar/> */}
      <Routes>
        <Route
          path="/"
          element={<Login />}
        />
        <Route path="/login" element={<Login />} />
        <Route path='/not-found' element={<ErrorPage />} />
        <Route path="/gpt" element={<GPT />} />
        <Route path="/forget-password" element={<ForgotPassword key={`${location.pathname}-${Date.now()}`} />} />
        <Route path="/portfolio" element={<PortfolioLandscape key={`${location.pathname}-${Date.now()}`} />} />
        <Route path='/detailPopUp' element={<DetailsPopUp key={`${location.pathname}-${Date.now()}`} />} />
        <Route path='/heirarchyFilter' element={<HeirarchyFilter key={`${location.pathname}-${Date.now()}`} />} />
        <Route path='/eda' element={<Eda key={`${location.pathname}-${Date.now()}`} />} />
        <Route path='/roi' element={<Roi key={`${location.pathname}-${Date.now()}`} />} />
        <Route path='/synergy' element={<Synergy key={`${location.pathname}-${Date.now()}`} />} />
        <Route path='/contri' element={<GrowthDriver key={`${location.pathname}-${Date.now()}`} />} />
        <Route path='/grain_contri' element={<CampaignEffectiveness key={`${location.pathname}-${Date.now()}`} />} />
        <Route path='/optimizer' element={<SkewbSimulator key={`${location.pathname}-${Date.now()}`} />} />
        <Route path='/savedresults' element={<SavedResults key={`${location.pathname}-${Date.now()}`} />} />
        <Route path='/roc' element={<GrowthCurves key={`${location.pathname}-${Date.now()}`} />} />
        <Route path='/validations' element={<Validation key={`${location.pathname}-${Date.now()}`} />} />
        <Route
          exact
          path="/optimizer/:ResultId"
          element={<SkewbSimulator key={`${location.pathname}-${Date.now()}`} />}
        ></Route>
        <Route
          exact
          path="/deleteresult"
          element={<DeleteResult key={`${location.pathname}-${Date.now()}`} />}
        ></Route>
      </Routes>
    </>
  )
}
const App = () => {



  // useEffect(() => {
  //   // Disable right-click
  //   const disableRightClick = (event) => {
  //     event.preventDefault();
  //   };

  //   // Disable keyboard shortcuts for DevTools and other actions
  //   const disableShortcuts = (event) => {
  //     // Windows/Linux shortcuts
  //     if (
  //       event.keyCode === 123 || // F12
  //       (event.ctrlKey && event.shiftKey && event.keyCode === 73) || // Ctrl+Shift+I
  //       (event.ctrlKey && event.shiftKey && event.keyCode === 74) || // Ctrl+Shift+J
  //       (event.ctrlKey && event.shiftKey && event.keyCode === 67) || // Ctrl+Shift+C
  //       (event.ctrlKey && event.keyCode === 85) || // Ctrl+U
  //       (event.ctrlKey && event.keyCode === 83) || // Ctrl+S
  //       (event.ctrlKey && event.keyCode === 80)    // Ctrl+P
  //     ) {
  //       event.preventDefault();
  //     }

  //     // macOS Cmd+Shift combinations
  //     if (
  //       (event.metaKey && event.shiftKey && event.keyCode === 73) || // Cmd+Shift+I
  //       (event.metaKey && event.shiftKey && event.keyCode === 74) || // Cmd+Shift+J
  //       (event.metaKey && event.shiftKey && event.keyCode === 67) || // Cmd+Shift+C
  //       (event.metaKey && event.keyCode === 85) || // Cmd+U
  //       (event.metaKey && event.keyCode === 83) || // Cmd+S
  //       (event.metaKey && event.keyCode === 80)    // Cmd+P
  //     ) {
  //       event.preventDefault();
  //     }

  //     // macOS Cmd+Option combinations
  //     if (
  //       (event.metaKey && event.altKey && event.keyCode === 73) || // Cmd+Option+I
  //       (event.metaKey && event.altKey && event.keyCode === 74) || // Cmd+Option+J
  //       (event.metaKey && event.altKey && event.keyCode === 67) || // Cmd+Option+C
  //       (event.metaKey && event.altKey && event.keyCode === 85) || // Cmd+Option+U
  //       (event.metaKey && event.altKey && event.keyCode === 83) || // Cmd+Option+S
  //       (event.metaKey && event.altKey && event.keyCode === 80)    // Cmd+Option+P
  //     ) {
  //       event.preventDefault();
  //     }
  //   };

  //   // Add event listeners for right-click and keyboard shortcuts
  //   document.addEventListener('contextmenu', disableRightClick);
  //   document.addEventListener('keydown', disableShortcuts);

  //   // Cleanup event listeners on component unmount
  //   return () => {
  //     document.removeEventListener('contextmenu', disableRightClick);
  //     document.removeEventListener('keydown', disableShortcuts);
  //   };
  // }, []);



  // const [windowDimensions, setWindowDimensions] = useState({
  //   width: window.innerWidth,
  //   height: window.innerHeight,
  // });

  // useEffect(() => {
  //   let timeoutId = null;

  //   function handleResize() {
  //     clearTimeout(timeoutId);
  //     timeoutId = setTimeout(() => {
  //       setWindowDimensions({
  //         width: window.innerWidth,
  //         height: window.innerHeight,
  //       });
  //     }, 200); // Adjust the debounce delay as needed
  //   }

  //   window.addEventListener('resize', handleResize);
  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //     clearTimeout(timeoutId);
  //   };
  // }, []);


  // window.addEventListener('beforeunload', () => {
  //   if (localStorage.getItem('isOpen') === true) {
  //     localStorage.setItem('isOpen', false);
  //   }
  // });


  const [analyticsTool, setAnalyticsTool] = useState('CSS');
  const analyticsToolOptions = [
    { key: 'html', value: 'HTML' },
    { key: 'css', value: 'CSS' },
    { key: 'js', value: 'JavaScript' },
    { key: 'bootstrap', value: 'Bootstrap' },
    { key: 'python', value: 'Python' },
    { key: 'java', value: 'Java' },
    { key: 'node', value: 'Node.js' },
    { key: 'angular', value: 'Angular' },
  ];
  const props = {
    Details: {
      img: 'path/to/image', // replace with the actual image path
      name: 'User Name', // replace with actual user name
      id: "tea-lipton"// replace with actual id
    },
    tag1: 'Tag 1',
    tag_val1: 'Value 1',
    tag_unit1: 'Unit 1',
    tag2: 'Tag 2',
    tag_val2: 'Value 2',
    tag_unit2: 'Unit 2',
    AllBrands: ['Brand 1', 'Brand 2'], // replace with actual brands data
    divWidth: 300,
    setGetBrand: () => { },
    setDetailsId: () => { },
    setDetails: () => { },
    getKPI: 'Some KPI',
    matchKPI: 'Matched KPI',
    allKPI: ['KPI 1', 'KPI 2'], // replace with actual KPIs data
    setGetKPI: () => { },
    AnalyticsToolAll: ['Tool 1', 'Tool 2'], // replace with actual tools data
    setShowPopup: () => { },
  };
  return (
    <>
      {/* <CustomSelect
        value={analyticsTool}
        onChange={(e) => setAnalyticsTool(e.target.value)}
        options={analyticsToolOptions}
      /> */}

      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppState>
          <BrowserRouter>
            <AppRoutes />
          </BrowserRouter>
        </AppState>
        <ToastContainer theme="colored" />

      </ThemeProvider >


    </>
  );
};


export default App;