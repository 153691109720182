import React, { useState, useEffect, useRef } from "react";

import configDriven from "../ConfirgDriven";
import ChannelMultiCascader from "../Components/ChannelMultiCascaderComp";
import ChannelMultiCascaderComp2 from "../Components/ChannelMultiCascaderComp2";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const AllCampaignFilter = (props) => {
    const [reset, setReset] = useState(false);
    const [fetchData, setFetchData] = useState(false);
    console.log("channel filter props", props);

    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false); // Close dropdown if click outside
                setFetchData(false);
            }
        };

        // Add event listener when component mounts
        document.addEventListener('click', handleClickOutside, true);

        // Remove event listener when component unmounts
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    const enableFetching = () => {
        setFetchData(true); // Enable fetching on any operation
    };
    const [channelCheckBox, setChannelCheckBox] = useState(
        props?.selectedCheckboxesC
    );
    const [channelCheckBox2, setChannelCheckBox2] = useState(
        props?.selectedCheckboxesC
    );

    console.log("channel filter props", props);
    // console.log("props.CompActiveFilters: ", props.CompActiveFilters);
    // console.log("props.CompAllFilters: ", props.CompAllFilters);

    const bgColor = (node) => {
        if (props.Chartdata?.length === 1) {
            if (props.Chartdata[0].hasOwnProperty(node)) {
                if (props.Chartdata[0][node].Filter?.length > 0) {
                    return true;
                } else {
                    return false;
                }
            }
        } else if (props.Chartdata?.length === 2) {
            if (props.Chartdata[0].hasOwnProperty(node)) {
                if (props.Chartdata[0][node].Filter?.length > 0) {
                    return true;
                } else {
                    if (props.Chartdata[1].hasOwnProperty(node)) {
                        if (props.Chartdata[1][node].Filter?.length > 0) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                }
            }
        }
    };

    const displayNames = JSON.parse(localStorage.getItem("displayNames"));

    const returnDisplayName = (id) => {
        if (configDriven.show_display_name && displayNames?.hasOwnProperty(id)) {
            return displayNames[id];
        } else {
            return id;
        }
    };

    // const data = JSON.parse(localStorage.getItem("channelFilters"));

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const newItems = Array.from(props.CompActiveFilters);
        const [removed] = newItems.splice(result.source.index, 1);
        newItems.splice(result.destination.index, 0, removed);
        props.setCompActiveFilters(newItems);
        props.updateChartData([], newItems);
        // console.log("newItems: ", newItems);
    };

    const styles = {
        bar: {
            display: "flex",
            marginBottom: '0.2rem',
            padding: '1px 8px',
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#1c2427",

            marginLeft: "4px",
            marginRight: "2px",
            height: '20px',
            color: "white",
            position: "relative", // This ensures the dropdown is positioned relative to the bar
        },
        barTitle: {
            fontSize: "10px",
        },
        dropdown: {
            fontSize: '10px',
            position: "static", // Change to static to allow the content to position relative to the bar
        },
        dropdownButton: {
            backgroundColor: "transparent",
            color: "white",

            border: "none",
            cursor: "pointer",
        },
        dropdownContent: {
            display: "flex",
            position: "absolute", // Keep this absolute
            top: "100%", // This will position it right below the bar
            left: 0, // Align with the left of the bar
            right: 0, // Extend to the full width of the bar
            backgroundColor: "#1c2427",
            color: "#d6ff41",
            minWidth: "200px",
            boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
            marginTop: "4px", // A slight margin to separate the dropdown from the bar
            zIndex: 1000, // Ensure it appears above all other content
            transition: "width 0.3s ease",
        },
        component: {
            backgroundColor: "#1c2427",
            flexGrow: 1,
        },
    };


    return (
        <div
            className={props.ActiveChart === "Comparison" ? "col-xl-6" : "col-xl-7"}
            style={{
                borderRadius: "10px",

                marginTop: 0,
                height: "max-content",
            }}
        >
            <div
                className="heirarchy-class"
                style={{ position: "relative", border: "none", width: "99.9%" }}
            >
                <div className="heirarchy-class-div" style={{ position: "relative" }}>
                    <h6 className="m-0 text-center cm-filter-title">Channel Filter</h6>
                    {props.SelectedChart !== "trends" ? (
                        <p
                            className="m-0 px-1"
                            style={{ color: "black", fontSize: "10px" }}
                        >
                            {/* <i
                className="fas fa-sync-alt"
                style={{
                  cursor:
                    JSON.stringify(channelCheckBox) === "{}"
                      ? "default"
                      : "pointer",
                  color:
                    JSON.stringify(channelCheckBox) === "{}"
                      ? "black"
                      : "white",
                }}
                onClick={() => {
                  if (JSON.stringify(channelCheckBox) !== "{}") {
                    // props.selectedCheckboxesC({});
                    setChannelCheckBox({});
                    props.setFetchCall(true);
                    setReset(true);
                  }
                }}
                aria-hidden="true"
              ></i> */}
                        </p>
                    ) : null}

                    {props.SelectedChart !== "trends" &&
                        props.CFilters &&
                        (props.ActiveChart === undefined ||
                            props.ActiveChart === "Measurement" ||
                            props.ActiveChart === "Comparison") && (
                            <div style={{ paddingLeft: "0.1rem" }}>
                                {/* <ChannelMultiCascader
                  data={props.CFilters}
                  Chartdata={props.Chartdata}
                  setChartdata={props.setChartdata}
                  setFetchCall={props.setFetchCall}
                  selectedCheckboxes={channelCheckBox}
                  setSelectedCheckboxes={setChannelCheckBox}
                  reset={reset}
                  setReset={setReset}
                /> */}
                                <div style={styles.bar} className="form-control">
                                    <span style={styles.barTitle}>Select Channel</span>
                                    <div style={styles.dropdown}>
                                        <button
                                            style={styles.dropdownButton}
                                            onClick={toggleDropdown}
                                        >
                                            <i className="fas fa-chevron-down"></i>
                                        </button>
                                        {isOpen && (
                                            <div style={styles.dropdownContent} ref={dropdownRef}>

                                                <div style={styles.component}>
                                                    <ChannelMultiCascader
                                                        setNewChannelBreadCrumb={props.setNewChannelBreadCrumb}
                                                        setNewChannelBreadCrumb2={props.setNewChannelBreadCrumb2}
                                                        data={props.CFilters}
                                                        Chartdata={props.Chartdata}
                                                        setChartdata={props.setChartdata}
                                                        setFetchCall={props.setFetchCall}
                                                        selectedCheckboxes={props.selectedCheckboxesC1}
                                                        setSelectedCheckboxes={props.setSelectedCheckboxesC1}
                                                        reset={reset}
                                                        setReset={setReset}
                                                        fetchData={fetchData}
                                                        enableFetching={enableFetching}
                                                        placeholder={"Left Chart"}
                                                    />
                                                </div>
                                                <div style={styles.component}>
                                                    <ChannelMultiCascaderComp2
                                                        setNewChannelBreadCrumb={props.setNewChannelBreadCrumb}
                                                        setNewChannelBreadCrumb2={props.setNewChannelBreadCrumb2}
                                                        data={props.CFilters}
                                                        Chartdata={props.Chartdata2}
                                                        setChartdata={props.setChartdata2}
                                                        setFetchCall={props.setFetchCall2}
                                                        selectedCheckboxes={props.selectedCheckboxesC2}
                                                        setSelectedCheckboxes={props.setSelectedCheckboxesC2}
                                                        reset={reset}
                                                        fetchData={fetchData}
                                                        enableFetching={enableFetching}
                                                        setReset={setReset}
                                                        placeholder={"Right Chart"}
                                                    />
                                                </div>
                                            </div>

                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    <div id="scroller" className="d-flex" style={{ overflowX: "scroll", marginLeft: '5px' }}>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="changeable" direction="horizontal">
                                {(provided) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        style={{ display: "flex", flexDirection: "row" }}
                                    >
                                        {props.CompActiveFilters.map((item, index) => (
                                            <Draggable
                                                key={item.name}
                                                draggableId={item.name.toString()}
                                                index={index}
                                            >
                                                {(provided) => (
                                                    <div
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        ref={provided.innerRef}
                                                    >
                                                        <li
                                                            key={index}
                                                            className={""}
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                height: "35px",
                                                                width: "auto",
                                                                whiteSpace: "nowrap",
                                                                overflow: "hidden",
                                                                textOverflow: "ellipsis",
                                                                maxWidth: "150px",
                                                            }}
                                                            onClick={() => props.RemoveCompFilter(item)}
                                                        >
                                                            <span
                                                                className="btn cm-drag-btn p-1 mx-2 active tab-class-clicked"
                                                                style={{ height: "24px", padding: "5px" }}
                                                            >
                                                                <p className="m-0 text-left px-1" style={{ fontSize: '10px' }} >
                                                                    {returnDisplayName(item.name)}
                                                                </p>
                                                                <p className="m-0 px-1">
                                                                    <i
                                                                        className="fa fa-minus"
                                                                        aria-hidden="true"
                                                                    ></i>
                                                                </p>
                                                            </span>
                                                        </li>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                        {props.CompAllFilters.map((item, index) =>
                            !props.IsInAllCompFilters(item) ? (
                                <li
                                    key={index}
                                    className={""}
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        height: "35px",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        maxWidth: "150px",
                                    }}
                                    onClick={() => {setFetchData(false); props.AddCompFilter(index)}}
                                >
                                    <span
                                        className="btn cm-drag-btn p-1 mx-2 tab-class-unclicked"
                                        style={{ width: "auto" }}
                                    >
                                        <p className="m-0 text-left px-1" style={{ fontSize: '10px' }} >
                                            {returnDisplayName(item.name)}
                                        </p>
                                        <p className="m-0 px-1">
                                            <i className="fa fa-plus" aria-hidden="true"></i>
                                        </p>
                                    </span>
                                </li>
                            ) : null
                        )}
                    </div>
                </div>
            </div>
            {/* </div> */}
        </div>
    );
};

export default AllCampaignFilter;
